import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ConditionsTab from './ConditionsTab';
import { InstrumentRuleConditions } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import { useTranslation } from 'react-i18next';
import { currenciesBaseArray, termsBaseArray } from 'constants/appConstants';

const ConditionsTabContainer = (props: Props) => {
    const { conditions, handleChange, isSoftDelete } = props;
    const { t } = useTranslation('settingsInstrumentRules');

    const instrumentTypesOptions = [
        { id: 1, name: 'CEDEARs', value: 'CERTIFICATE' },
        { id: 2, name: 'Bonos', value: 'BONDS' },
        { id: 3, name: 'Acciones', value: 'EQUITY' },
        { id: 4, name: 'Fondos', value: 'FUND' },
    ];

    const getInitialConditionsType = () => {
        if (isSoftDelete) {
            return 'custom';
        }
        return !conditions.length || !conditions[0]?.ticker ? 'instrument' : 'custom';
    };

    const [conditionsType, setConditionsType] = React.useState<'instrument' | 'custom'>(
        getInitialConditionsType(),
    );

    const [instrumentType, setInstrumentType] = React.useState<{
        id: number;
        name: string;
        value: string;
    } | null>(
        !conditions.length || !conditions[0]?.ticker
            ? instrumentTypesOptions.find(f => f.value === conditions[0]?.instrumentType) ?? null
            : null,
    );

    const [instrument, setInstrument] = React.useState<any>(undefined);

    const handleConditionsType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConditionsType((event.target as HTMLInputElement).value as 'instrument' | 'custom');
        setInstrument(undefined);
        setInstrumentType(null);
        handleChange({
            target: {
                name: 'conditions',
                value: [],
            },
        });
    };

    const handleSelectInstrument = (values: any) => {
        if (!values) {
            setInstrumentType(null);
            setInstrument(undefined);
            return;
        }
        setInstrument(values);
    };

    const handleAddInstrument = () => {
        const newCondition: InstrumentRuleConditions = {
            instrumentType: null,
            ticker: instrument.name,
            terms: [...termsBaseArray],
            currencies: [...currenciesBaseArray],
        };
        handleChange({
            target: {
                name: 'conditions',
                value: conditions?.concat(newCondition),
            },
        });
        setInstrument(undefined);
        setInstrumentType(null);
    };
    const handleAddConditionInstrumentType = (value: any) => {
        const newCondition: InstrumentRuleConditions = {
            instrumentType: value?.value ?? '',
            ticker: null,
            terms: [...termsBaseArray],
            currencies: [...currenciesBaseArray],
        };
        setInstrumentType(value);
        handleChange({
            target: {
                name: 'conditions',
                value: value ? [newCondition] : [],
            },
        });
    };

    const childProps = {
        ...props,
        instrumentType,
        setInstrumentType,
        conditionsType,
        handleConditionsType,
        t,
        instrumentTypesOptions,
        instrument,
        handleAddInstrument,
        handleSelectInstrument,
        handleAddConditionInstrumentType,
    };

    return <ConditionsTab {...childProps} />;
};

const propTypes = {
    handleChange: PropTypes.func.isRequired,
    isSoftDelete: PropTypes.bool.isRequired,
};

interface extraProps {
    conditions: InstrumentRuleConditions[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ConditionsTabContainer.propTypes = propTypes;

export default ConditionsTabContainer;
