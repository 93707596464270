import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketExteriorInstrumentPage from './MarketExteriorInstrumentPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ExternalInstrument } from 'types/marketExteriorInstruments/marketExteriorInstruments.types';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import MarketExteriorInstrumentForm from './MarketExteriorInstrumentForm';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_INSTRUMENTS } from 'constants/abilities.constants';

const MarketExteriorInstrumentPageContainer = (props: Props) => {
    const { t } = useTranslation('marketExteriorInstruments');
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const columns: CommonGridColumnsProps<ExternalInstrument>[] = [
        {
            id: 'creationDate',
            name: t('market_exterior_instruments_list_creation_date_column'),
            selector: row => formatDateWithHour(row.creationDate),
            exportOption: row => formatDateWithHour(row.creationDate),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'modifiedDate',
            name: t('market_exterior_instruments_list_modified_date_column'),
            selector: row => formatDateWithHour(row.modifiedDate),
            exportOption: row => formatDateWithHour(row.modifiedDate),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'ticker',
            name: t('market_exterior_instruments_list_ticker_column'),
            selector: row => row.name,
            exportOption: row => row.name,
            sortField: 'name',
            sortable: true,
            center: true,
        },
        {
            id: 'description',
            name: t('market_exterior_instruments_list_description_column'),
            selector: row => row.description || '-',
            exportOption: row => row.description || '-',
            sortField: 'description',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <MarketExteriorInstrumentForm isEdit {...props} />,
            isHidden: () => isHiddenByAbility(UPDATE_INSTRUMENTS),
        },
    ];
    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <div />}
                    modalTitle={'market-exterior-instruments'}
                    component={(props: any) => <MarketExteriorInstrumentForm {...props} />}
                />
            ),
            isHidden: isHiddenByAbility(UPDATE_INSTRUMENTS),
        },
    ];
    const headerFilters: CommonFilter[] = [
        {
            id: 'ticker',
            label: t('market_exterior_instrument_ticker_filter_label'),
            type: 'text',
            placeholder: t('market_exterior_instrument_ticker_filter_placeholder') as string,
            query: 'ticker',
        },
        {
            id: 'name',
            label: t('market_exterior_instrument_name_filter_label'),
            type: 'text',
            placeholder: t('market_exterior_instrument_name_filter_placeholder') as string,
            query: 'name',
        },
        {
            id: 'dateFrom',
            label: t('market_exterior_instrument_date_from_filter_label'),
            type: 'date',
            placeholder: t('market_exterior_instrument_date_from_filter_placeholder') as string,
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('market_exterior_instrument_date_to_filter_label'),
            type: 'date',
            placeholder: t('market_exterior_instrument_date_to_filter_placeholder') as string,
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        actionColumns,
        headerActions,
        headerFilters,
    };

    return <MarketExteriorInstrumentPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketExteriorInstrumentPageContainer.propTypes = propTypes;

export default MarketExteriorInstrumentPageContainer;
