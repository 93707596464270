import * as React from 'react';
import { AlertColor, IconButton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomTableAction.styled';
import {
    CustomIconProps,
    ModalActionProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { SXBreakpoint, Tprop } from 'types/common/general.types';
import TableHeaderButton from '../TableHeaderButton';
import { ReactElement } from 'react';
import { ICON_CELL_SIZE, tModal } from 'constants/appConstants';
import defaultTheme from 'utils/theme/defaultTheme';
import CustomIconSelector from 'components/common/CustomIconSelector';

const CustomTableAction = (props: Props) => {
    const {
        component,
        modalTitle,
        type,
        Icon,
        row,
        width,
        ButtonElement,
        customizedTitle,
        isOpen,
        open,
        close,
        setSnackBarMessage,
        t,
    } = props;

    return (
        <>
            {type === 'create' ? (
                <TableHeaderButton
                    text={t('add')}
                    handleClick={open}
                    icon={
                        <CustomIconSelector type="add" sx={{ color: '#FFF', ...ICON_CELL_SIZE }} />
                    }
                />
            ) : (
                (ButtonElement && ButtonElement({ open })) || (
                    <Tooltip title={t(type.replaceAll('-', '_'), tModal)}>
                        <IconButton onClick={open}>
                            <Icon row={row} sx={ICON_CELL_SIZE} />
                        </IconButton>
                    </Tooltip>
                )
            )}
            {isOpen && (
                <St.Modal
                    open={isOpen}
                    disableEnforceFocus
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <St.ModalContentWrapper ownerState={{ width }}>
                        {customizedTitle ? (
                            customizedTitle(row)
                        ) : (
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {t(
                                    `${modalTitle
                                        .replaceAll('-', '_')
                                        .toLocaleLowerCase()}_${type.replaceAll('-', '_')}`,
                                    tModal,
                                )}
                            </Typography>
                        )}
                        <St.CloseIconButton aria-label="close" onClick={close}>
                            <CustomIconSelector
                                type="close"
                                sx={{ color: defaultTheme.palette.grey[500] }}
                            />
                        </St.CloseIconButton>
                        {component &&
                            component({
                                close,
                                setSnackBarMessage,
                                row,
                            } as ModalActionProps)}
                    </St.ModalContentWrapper>
                </St.Modal>
            )}
        </>
    );
};

const propTypes = {
    modalTitle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

type extraProps = InferPropsExtended<
    {
        component?: React.FunctionComponent;
        Icon: (props: CustomIconProps) => JSX.Element;
        row?: any;
        width: SXBreakpoint<string | number>;
        ButtonElement?: (props: { open: () => void }) => JSX.Element;
        customizedTitle?: (row: any) => ReactElement;
        setSnackBarMessage: (msj: string, sever: AlertColor) => void;
        open: () => void;
        close: () => void;
    },
    Tprop
>;

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomTableAction.propTypes = propTypes;

export default CustomTableAction;
