import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MainLayout.styled';
import { Outlet } from 'react-router-dom';
import HelmetLayout from '../HelmetLayout';
import SidebarLayout from '../SidebarLayout';
import { AlertsApi } from 'types/alerts/alerts.api.types';
import AlertsNotificationModal from 'components/common/AlertNotificationsModal';

const MainLayout = (props: Props) => {
    const {
        isAuthenticated,
        alertNotification,
        openAlertModal,
        setOpenAlertModal,
        errorMessageAlert,
        isLoadingAlert,
    } = props;

    return (
        <>
            <HelmetLayout withOutlet={false} />
            <St.MainWrapper component="main">
                <SidebarLayout />
                <St.PageWrapper>
                    {/* REVISAR */}
                    {isAuthenticated === true ? <Outlet /> : <Outlet />}
                </St.PageWrapper>
                {!!alertNotification?.length && (
                    <AlertsNotificationModal
                        open={openAlertModal}
                        setOpenModal={setOpenAlertModal}
                        filteredAlert={alertNotification}
                        errorMessage={errorMessageAlert}
                        isLoading={isLoadingAlert}
                    />
                )}
            </St.MainWrapper>
        </>
    );
};

const propTypes = {
    isAuthenticated: PropTypes.bool,
};

interface extraProps {
    setOpenAlertModal: (x: boolean) => void;
    openAlertModal: boolean;
    alertNotification: AlertsApi[] | undefined;
    errorMessageAlert: string | null;
    isLoadingAlert: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayout.propTypes = propTypes;

export default MainLayout;
