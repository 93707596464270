import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientDepositsPage from './ClientDepositsPage';
import { useTranslation } from 'react-i18next';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { Deposits } from 'types/clientDeposits/clientDeposits.types';
import { useDownloadFile } from 'hooks/api/utils.hooks';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

const ClientDepositsPageContainer = (props: Props) => {
    const { t } = useTranslation('clientDeposits');
    const { downloadFile, SnackBar } = useDownloadFile();

    const columns: CommonGridColumnsProps<Deposits>[] = [
        {
            id: 'admission_date',
            name: t('deposits_list_transfer_date_column'),
            selector: row => formatDateWithHour(row.transferDate as string),
            exportOption: row => formatDateWithHour(row.transferDate as string),
            sortField: 'transferDate',
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            id: 'channel',
            name: t('deposits_list_chanel_column'),
            selector: row => getFullName(row.channel),
            exportOption: row => getFullName(row.channel),
            sortField: 'channel',
            sortable: false,
            center: true,
        },
        {
            id: 'clientName',
            name: t('deposits_list_client_column'),
            selector: row => getFullName(row.user),
            exportOption: row => getFullName(row.user),
            sortField: 'client',
            sortable: false,
            center: true,
            wrap: true,
        },
        {
            id: 'document',
            name: t('deposits_list_personal_data_column'),
            selector: row => row.user.docNumber || '-',
            exportOption: row => row.user.docNumber || '-',
            sortField: 'document',
            sortable: true,
            center: true,
        },
        {
            id: 'customerCode',
            name: t('deposits_list_client_number_column'),
            selector: row => row.customerCode,
            exportOption: row => row.customerCode,
            sortField: 'customerCode',
            sortable: true,
            center: true,
        },
        {
            id: 'accountNumber',
            name: t('deposits_list_cbu_column'),
            selector: row => row.bankAccount.accountNumber || '-',
            exportOption: row => row.bankAccount.accountNumber || '-',
            sortField: 'accountNumber',
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            id: 'currency',
            name: t('deposits_list_coin_column'),
            selector: row => row.bankAccount.currency || '-',
            exportOption: row => row.bankAccount.currency || '-',
            sortField: 'currency',
            sortable: true,
            center: true,
        },
        {
            id: 'amount',
            name: t('deposits_list_amount_column'),
            selector: row => convertCurrencyFormat(row.amount),
            exportOption: row => convertCurrencyFormat(row.amount),
            sortField: 'amount',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'download',
            icon: (props: any) => <CustomIconSelector type="download" sx={props.sx} />,
            onIconClick: (row: Deposits) => downloadFile(row.fileUrl),
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'client',
            label: t('client_deposits_client_filter_label'),
            type: 'text',
            placeholder: t('client_deposits_client_filter_placeholder') as string,
            query: 'client',
        },
        {
            id: 'from',
            label: t('client_deposits_creation_date_from_filter_label'),
            type: 'date',
            placeholder: t('client_deposits_creation_date_from_filter_placeholder') as string,
            query: 'dateFrom',
        },
        {
            id: 'to',
            label: t('client_deposits_creation_date_to_filter_label'),
            type: 'date',
            placeholder: t('client_deposits_creation_date_to_filter_placeholder') as string,
            query: 'dateTo',
        },
        {
            id: 'customer-code',
            label: t('client_deposits_customer_code_filter_label'),
            type: 'text',
            placeholder: t('client_deposits_customer_code_filter_placeholder') as string,
            query: 'customerCode',
            regex: /^\d{0,9}$/,
        },
        {
            id: 'account-number',
            label: t('client_deposits_account_number_filter_label'),
            type: 'text',
            placeholder: t('client_deposits_account_number_filter_placeholder') as string,
            query: 'accountNumber',
            regex: /^\d{0,22}$/,
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        t,
        headerFilters,
        SnackBar,
    };

    return <ClientDepositsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDepositsPageContainer.propTypes = propTypes;

export default ClientDepositsPageContainer;
