import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { tCommonGrid, tModal } from 'constants/appConstants';
import ActionIconButton from 'components/common/ActionIconButton';
import CustomIconSelector from 'components/common/CustomIconSelector';
import AlertNoData from 'components/common/AlertNoData';
import { UserDocuments } from 'types/users/users.api.types';
import { getDateFromFileUrl } from 'utils/helpers/dateHelper';

const ClientSectionDocuments = ({ documents, t, downloadFile, SnackBar, isLoading }: Props) => (
    <>
        {documents?.length > 0 ? (
            <TableContainer component={Paper}>
                <Table aria-label="user's exception table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('name')}</TableCell>
                            <TableCell>{t('date')}</TableCell>
                            <TableCell align="right" sx={{ pr: 4 }}>
                                {t('actions', tCommonGrid)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map(({ documentName, documentPath }, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{documentName}</TableCell>
                                <TableCell>{getDateFromFileUrl(documentPath)}</TableCell>
                                <TableCell align="right" sx={{ pr: 5 }}>
                                    {isLoading ? (
                                        <CircularProgress
                                            sx={{
                                                width: '24px!important',
                                                height: '24px!important',
                                            }}
                                        />
                                    ) : (
                                        <ActionIconButton
                                            tooltipText={t('download', tModal)}
                                            handleClick={() => downloadFile(documentPath)}
                                            icon={
                                                <CustomIconSelector
                                                    type="download"
                                                    sx={{ color: 'primary' }}
                                                />
                                            }
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : (
            <AlertNoData title={t('no_data_was_found')} />
        )}
        <SnackBar />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    documents: UserDocuments[];
    downloadFile(fileUrl: string): void;
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionDocuments.propTypes = propTypes;

export default ClientSectionDocuments;
