import * as React from 'react';
import St from './CustomAutoComplete.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Autocomplete } from '@mui/material';

const CustomAutoComplete = (props: Props) => {
    const {
        id,
        onChange,
        onInputChange,
        multiple = false,
        filterSelectedOptions = false,
        options,
        value,
        placeholder,
        helperText,
        error,
        renderOption,
        label,
        isLoading,
    } = props;

    return (
        <Autocomplete
            multiple={multiple}
            id={id}
            size="small"
            value={value}
            getOptionLabel={(option: { id: number | string; name?: string }) => option.name || ''}
            options={options}
            filterSelectedOptions={filterSelectedOptions}
            onChange={onChange}
            onInputChange={onInputChange ?? undefined}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={isLoading}
            renderInput={params => (
                <St.TextField
                    {...params}
                    variant="outlined"
                    label={label || ''}
                    placeholder={placeholder ?? ''}
                    error={!!error}
                    helperText={error || helperText}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <St.Chip label={option.name} {...getTagProps({ index })} />
                ))
            }
            renderOption={(props, option, { selected }) =>
                renderOption(props, option, { selected })
            }
        />
    );
};

const propTypes = {};

interface extraProps {
    multiple?: boolean;
    id: string;
    value: any;
    options: any[];
    filterSelectedOptions?: boolean;
    onChange: (e: any, value: any) => void;
    onInputChange?: (e: any, value: any) => void;
    placeholder?: string;
    helperText?: string;
    label?: string;
    error?: string;
    renderOption: (props: any, option: any, { selected }: any) => React.ReactNode;
    isLoading?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomAutoComplete.propTypes = propTypes;

export default CustomAutoComplete;
