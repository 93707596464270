import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientBankAccountsPage from './ClientBankAccountsPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { ClientBankAccountApi } from 'types/clientBankAccounts/clientBankAccounts.api.types';
import { getColorByStatus, getFullName } from 'utils/helpers/commonHelper';
import ClientBankAccountsDetails from './ClientBankAccountsDetails';
import { Chip } from '@mui/material';
import { tCommon } from 'constants/appConstants';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';

const ClientClientBankAccountsPageContainer = (props: Props) => {
    const { t } = useTranslation('clientBankAccounts');

    const columns: CommonGridColumnsProps<ClientBankAccountApi>[] = [
        {
            id: 'date',
            name: t('client_bank_accounts_list_date'),
            selector: row => formatDateWithHour(row.createdAt),
            exportOption: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'client',
            name: t('client_bank_accounts_list_client'),
            selector: row => getFullName(row.user),
            exportOption: row => getFullName(row.user),
            sortField: 'client',
            sortable: false,
            center: true,
        },
        {
            id: 'description',
            name: t('client_bank_accounts_list_description'),
            selector: row => row.description ?? '-',
            exportOption: row => row.description ?? '-',
            sortField: 'description',
            sortable: false,
            center: true,
        },
        {
            id: 'type',
            name: t('client_bank_accounts_list_account_type'),
            exportOption: row => t(row.type) as string,
            cell: row => (
                <Chip
                    color="secondary"
                    variant="outlined"
                    sx={{ m: 0.625, minWidth: '100%' }}
                    label={t(row.type)}
                />
            ),
            sortField: 'type',
            sortable: true,
            center: true,
        },
        {
            id: 'currency',
            name: t('client_bank_accounts_list_account_currency'),
            selector: row => row.currency,
            exportOption: row => row.currency,
            sortField: 'currency',
            sortable: true,
            center: true,
        },
        {
            id: 'status',
            name: t('client_bank_accounts_list_account_state'),
            exportOption: row => t(row.status) as string,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '100%', ...getColorByStatus(row.status) }}
                    label={t(row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            customizedTitle: ({ status, type }) => (
                <ChannelsModalHeader
                    modalName="client_bank_accounts"
                    status={status}
                    operationType={type}
                />
            ),
            component: (props: any) => <ClientBankAccountsDetails {...props} />,
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'client',
            label: t('extractions_filter_client'),
            type: 'text',
            placeholder: t('extractions_filter_client_placeholder') as string,
            query: 'customerName',
        },
        {
            id: 'dateFrom',
            label: t('extractions_filter_dateFrom'),
            type: 'date',
            placeholder: t('extractions_filter_dateFrom') as string,
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('extractions_filter_dateTo'),
            type: 'date',
            placeholder: t('extractions_filter_dateTo') as string,
            query: 'dateTo',
        },
        {
            id: 'customerCode',
            label: t('extractions_filter_customer_number'),
            type: 'number',
            placeholder: t('extractions_filter_customer_number_placeholder') as string,
            query: 'customerCode',
        },
        {
            id: 'accountNumber',
            label: t('extractions_filter_cbu'),
            type: 'number',
            placeholder: t('extractions_filter_cbu_placeholder') as string,
            query: 'accountNumber',
        },
        {
            id: 'currency',
            label: t('extractions_filter_currency'),
            type: 'select',
            placeholder: t('extractions_filter_currency') as string,
            query: 'currency',
            options: [
                { id: 'ARS', name: 'ARS' },
                { id: 'USD', name: 'USD' },
                { id: 'USD-C', name: 'USD-C' },
            ],
        },
        {
            id: 'status',
            label: t('extractions_filter_status'),
            type: 'select',
            placeholder: t('extractions_filter_status') as string,
            query: 'status',
            options: [
                { id: 'ACTIVE', name: t('ACTIVE', tCommon) },
                { id: 'PENDING_ACCEPT', name: t('PENDING_ACCEPT', tCommon) },
                { id: 'REJECTED', name: t('REJECTED', tCommon) },
                { id: 'PENDING_DELETE', name: t('PENDING_DELETE', tCommon) },
                { id: 'DELETED', name: t('DELETED', tCommon) },
            ],
        },
        {
            id: 'type',
            label: t('extractions_filter_type'),
            type: 'select',
            placeholder: t('extractions_filter_type') as string,
            query: 'type',
            options: [
                { id: 'CHECKING', name: t('LOCAL') },
                { id: 'EXTERIOR', name: t('EXTERIOR') },
                { id: 'BROKER', name: t('BROKER') },
            ],
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        headerFilters,
        t,
    };

    return <ClientBankAccountsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientClientBankAccountsPageContainer.propTypes = propTypes;

export default ClientClientBankAccountsPageContainer;
