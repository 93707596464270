import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const UsersBackofficeConfirmationAction = ({
    isLoadingEnable,
    isLoadingDisable,
    handleSubmit,
    t,
    close,
    action,
}: Props) => (
    <DialogContent>
        <DialogContentText>
            {t(`backoffice_users_confirmation_${action}_modal_question`)}
        </DialogContentText>
        <DialogActions>
            <ActionButton
                type="button"
                size="small"
                variant="outlined"
                onClick={close}
                color="primary">
                {t('cancel', tCommon)}
            </ActionButton>

            <ActionButton
                type="button"
                size="small"
                variant="contained"
                onClick={handleSubmit}
                loading={isLoadingDisable || isLoadingEnable}
                color="primary">
                {t('confirm', tCommon)}
            </ActionButton>
        </DialogActions>
    </DialogContent>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};
interface extraProps {
    isLoadingEnable: boolean;
    isLoadingDisable: boolean;
    handleSubmit(): void;
    action: string;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficeConfirmationAction.propTypes = propTypes;

export default UsersBackofficeConfirmationAction;
