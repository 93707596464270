import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { TextField } from '@mui/material';

const HeaderFilterText = (props: Props) => {
    const { onChange, query, value, label, placeholder, type } = props;

    return (
        <TextField
            size="small"
            fullWidth
            placeholder={placeholder ?? label}
            label={label}
            value={value}
            onChange={(e: any) => onChange(query, e.target.value)}
            type={type}
        />
    );
};

const propTypes = {
    onChange: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
};

interface extraProps {
    value: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFilterText.propTypes = propTypes;

export default HeaderFilterText;
