import { useState } from 'react';
import { Chip, Typography } from '@mui/material';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';
import {
    ASSIGNED,
    INSTRUMENTS_BASE_URL,
    LOCK,
    PENDING,
    UNLOCK,
    tChannelsCommon,
    tDate,
    tCommon,
    tModal,
    PENDING_ORDER,
} from 'constants/appConstants';
import { useLockOrUnlockOrder } from 'hooks/api/channelsOrders.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useTranslation } from 'react-i18next';
import { Order } from 'types/channelsOrders/channelsOrders.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import ChannelsOrdersDetail from './ChannelsOrdersDetail';
import { statusOptions } from 'utils/helpers/channelsCommonHelper';
import { convertCurrencyFormat, getFullName, getColorByStatus } from 'utils/helpers/commonHelper';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsOrdersPage from './ChannelsOrdersPage';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_ORDERS_MARKET } from 'constants/abilities.constants';
import ActionIconButton from 'components/common/ActionIconButton';
import { useDownloadFile } from 'hooks/api/utils.hooks';

const ChannelsOrdersPageContainer = (props: Props) => {
    const { t } = useTranslation('channelsOrders');
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { mutate } = useLockOrUnlockOrder(setSnackBarMessage);
    const { isHiddenByAbility } = useGetActionsReestrictions();
    const [refreshTable, setRefreshTable] = useState(false);
    const { downloadFile, isLoading, SnackBar: SnackBarFile } = useDownloadFile();
    const handleAcquireOrReleaseOrder = (infoToLockOrUnlock: { id: string; action: string }) =>
        mutate(infoToLockOrUnlock, {
            onSuccess: () => {
                setSnackBarMessage(t(`order_${infoToLockOrUnlock.action}_successfully`));
                setRefreshTable(prevValue => !prevValue);
            },
        });

    const columns: CommonGridColumnsProps<Order>[] = [
        {
            id: 'customerCode',
            name: t('orders_list_customer_number_column'),
            selector: row => row?.customerCode,
            exportOption: row => row?.customerCode,
            sortField: 'customerCode',
            sortable: true,
            center: true,
        },
        {
            id: 'customerName',
            name: t('orders_list_customer_name_column'),
            selector: row => getFullName(row.user),
            exportOption: row => getFullName(row.user),
            sortField: 'customerName',
            sortable: false,
            center: true,
            wrap: true,
        },
        {
            id: 'operationType',
            name: t('orders_list_operation_type_column'),
            exportOption: row => t(`orders_${row.buySell}`, tChannelsCommon) as string,
            cell: row => (
                <Chip
                    color="secondary"
                    variant="outlined"
                    sx={{ m: 0.625, minWidth: '100%' }}
                    label={t(`orders_${row.buySell}`, tChannelsCommon)}
                />
            ),
            sortField: 'buySell',
            sortable: true,
            center: true,
        },
        {
            id: 'instrument',
            name: t('orders_list_instrument_column'),
            exportOption: row => row?.ticker.ticker,
            cell: row => (
                <Typography fontSize={14} fontWeight={700} textAlign="center">
                    {row?.ticker.ticker || '-'}
                </Typography>
            ),
            sortField: 'instrument',
            sortable: false,
            center: true,
        },
        {
            id: 'amount',
            name: t('orders_list_amount_column'),
            selector: row => convertCurrencyFormat(row.amount, row.ticker.currency),
            exportOption: row => convertCurrencyFormat(row.amount, row.ticker.currency),
            sortField: 'amount',
            sortable: true,
            center: true,
        },
        {
            id: 'ddjjRequired',
            name: t('orders_list_ddjj_column'),
            exportOption: row => t(row.declaration ? 'yes' : 'no', tCommon) as string,
            cell: row => (
                <>
                    {t(row.declaration ? 'yes' : 'no', tCommon)}
                    {row.status === 'COMPLETED' && row?.declaration?.filePath && (
                        <ActionIconButton
                            tooltipText={t('download', tModal)}
                            disabled={isLoading}
                            icon={<CustomIconSelector type="download" />}
                            handleClick={() => downloadFile(row?.declaration?.filePath as string)}
                        />
                    )}
                </>
            ),
            sortField: 'ddjjRequired',
            sortable: false,
            center: true,
        },
        {
            id: 'status',
            name: t('orders_list_operation_status_column'),
            exportOption: row => t(row.status, tCommon) as string,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '100%', ...getColorByStatus(row.status) }}
                    label={t(row.status === PENDING ? PENDING_ORDER : row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
        {
            id: 'operator',
            name: t('orders_list_operator_column'),
            selector: row => getFullName(row?.operator),
            exportOption: row => getFullName(row?.operator),
            sortField: 'operator',
            sortable: false,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            width: 'xl',
            customizedTitle: row => (
                <ChannelsModalHeader
                    modalName="orders"
                    status={row.status}
                    operationType={`orders_${row.buySell}`}
                />
            ),
            component: (props: any) => (
                <ChannelsOrdersDetail
                    {...props}
                    handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
                />
            ),
        },
        {
            id: 'assing',
            icon: (props: any) => <CustomIconSelector type="assing" sx={props.sx} />,
            isHidden: (row: any) =>
                row?.status !== PENDING && !isHiddenByAbility(UPDATE_ORDERS_MARKET),
            onIconClick: ({ id }: Order) => handleAcquireOrReleaseOrder({ id, action: LOCK }),
        },
        {
            id: 'unassing',
            icon: (props: any) => <CustomIconSelector type="unassing" sx={props.sx} />,
            isHidden: (row: any) =>
                row?.status !== ASSIGNED && !isHiddenByAbility(UPDATE_ORDERS_MARKET),
            onIconClick: ({ id }: Order) => handleAcquireOrReleaseOrder({ id, action: UNLOCK }),
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'customerCode',
            label: t('customer_number', tChannelsCommon),
            type: 'text',
            placeholder: t('customer_number', tChannelsCommon) as string,
            query: 'customerCode',
            regex: /^\d{0,13}$/,
        },
        {
            id: 'instrument',
            label: t('instrument', tChannelsCommon),
            type: 'requestAutocomplete',
            placeholder: t('channels_affidavit_instruments_filter_placeholder') as string,
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/bo/`,
                param: 'ticker',
                optionLabel: option => option.name,
                keyTofilter: 'tickers[0].ticker',
            },
        },
        {
            id: 'status',
            label: t('status', tChannelsCommon),
            type: 'select',
            placeholder: '',
            query: 'status',
            options: statusOptions,
        },
        {
            id: 'dateFrom',
            label: t('from', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        actionColumns,
        headerFilters,
        refreshTable,
        SnackBar,
        SnackBarFile,
    };

    return <ChannelsOrdersPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsOrdersPageContainer.propTypes = propTypes;

export default ChannelsOrdersPageContainer;
