import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ClientProspectActions.styled';
import ActionButton from 'components/common/ActionButton';
import { ESCO_INFO, tCommon } from 'constants/appConstants';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AlertColor,
    Box,
    Typography,
} from '@mui/material';
import ClientProspectApproveModal from './ClientProspectApproveModal';
import ClientProspectRejectModalForm from './ClientProspectRejectModalForm';
import {
    ClientRiskProfile,
    ProspectMarketData,
    ProspectStateInfo,
} from 'types/clients/clients.types';
import ClientSectionProspectEscoInfo from '../ClientInfoSections/ClientSectionProspectEscoInfo';
import CircularSpinner from 'components/common/CircularSpinner';
import CustomIconSelector from 'components/common/CustomIconSelector';
import ClientProspectRelateAccountModal from './ClientProspectRelateAccountModal';
import { UPDATE_ONBOARDING } from 'constants/abilities.constants';

const ClientProspectActions = (props: Props) => {
    const {
        t,
        id,
        steps,
        action,
        fullName,
        docNumber,
        openModal,
        handleSetAction,
        handleCloseModal,
        setSnackBarMessage,
        marketInfo,
        isLoading,
        handleOpenEscoInfo,
        selectedSection,
        isHiddenByAbility,
        refetch,
        riskProfile,
    } = props;

    return isLoading ? (
        <Box mt={2}>
            <CircularSpinner />
        </Box>
    ) : (
        <>
            {marketInfo ? (
                <>
                    <Accordion
                        key={ESCO_INFO}
                        expanded={selectedSection === ESCO_INFO}
                        onChange={(_, expanded: boolean) =>
                            handleOpenEscoInfo(ESCO_INFO, expanded)
                        }>
                        <AccordionSummary
                            sx={{
                                '.MuiAccordionSummary-content': { alignItems: 'center' },
                            }}
                            expandIcon={<CustomIconSelector type="dropDown" />}>
                            <Typography mr={1}>
                                {t('clients_section_prospects_esco_info')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {marketInfo && (
                                <ClientSectionProspectEscoInfo marketInfo={marketInfo} />
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <St.ButtonsContainer spacing={2} marginTop={'20px'} direction="row">
                        <ActionButton onClick={() => handleSetAction('relate')}>
                            {t('relate_account')}
                        </ActionButton>
                    </St.ButtonsContainer>
                </>
            ) : (
                <St.ButtonsContainer spacing={2} direction="row">
                    <ActionButton variant="outlined" onClick={() => handleSetAction('reject')}>
                        {t('reject', tCommon)}
                    </ActionButton>
                    <ActionButton onClick={() => handleSetAction('approve')}>
                        {t('approve', tCommon)}
                    </ActionButton>
                </St.ButtonsContainer>
            )}
            {!isHiddenByAbility(UPDATE_ONBOARDING) && (
                <>
                    {action === 'approve' && (
                        <ClientProspectApproveModal
                            id={id}
                            open={openModal}
                            close={handleCloseModal}
                            setSnackBarMessage={setSnackBarMessage}
                        />
                    )}
                    {action === 'reject' && (
                        <ClientProspectRejectModalForm
                            id={id}
                            refetch={refetch}
                            open={openModal}
                            fullName={fullName}
                            docNumber={docNumber}
                            close={handleCloseModal}
                            setSnackBarMessage={setSnackBarMessage}
                            steps={steps}
                            riskProfile={riskProfile}
                        />
                    )}
                    {action === 'relate' && marketInfo && (
                        <ClientProspectRelateAccountModal
                            id={id}
                            open={openModal}
                            close={handleCloseModal}
                            setSnackBarMessage={setSnackBarMessage}
                            marketAccounts={marketInfo.marketAccounts}
                        />
                    )}
                </>
            )}
        </>
    );
};

const propTypes = {
    id: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleSetAction: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    docNumber: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    selectedSection: PropTypes.string.isRequired,
    refetch: PropTypes.func.isRequired,
};

interface extraProps {
    action: 'approve' | 'reject' | 'relate' | null;
    steps?: ProspectStateInfo[];
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    handleOpenEscoInfo: (selectedOption: string, expanded?: boolean) => void;
    marketInfo?: ProspectMarketData;
    riskProfile?: ClientRiskProfile;
    isHiddenByAbility: (ability: string) => boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectActions.propTypes = propTypes;

export default ClientProspectActions;
