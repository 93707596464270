import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfilePage from './ProfilePage';
import { useGetPersonalProfileData } from 'hooks/api/usersBackoffice.hooks';
import { useTranslation } from 'react-i18next';
import React from 'react';
import useSnackBar from 'hooks/common/snackbar.hooks';

export const ProfilePageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('profilePage');
    const { data, isLoading: myUserDataIsLoading } = useGetPersonalProfileData();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const [openModal, setOpenModal] = React.useState(false);

    const childProps = {
        t,
        data,
        myUserDataIsLoading,
        openModal,
        setOpenModal,
        SnackBar,
        setSnackBarMessage,
    };

    return <ProfilePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePageContainer.propTypes = propTypes;

export default ProfilePageContainer;
