import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MarketDollarQuotesDetail.styled';
import { DollarQuotes } from 'types/marketDollarQuotes/marketDollarQuotes.types';

const MarketDollarQuotesDetail = (props: Props) => {
    const { t, detailData } = props;
    const { title, instrument, updatedAt } = detailData;

    return (
        <St.MarketDollarQuotesDetail data-testid="MarketDollarQuotesDetail-page">
            <St.QuoteTitle>
                <St.Label>{t('dollar_quotes_list_title_column')}:</St.Label>
                <div
                    dangerouslySetInnerHTML={{
                        __html: title || '',
                    }}
                />
            </St.QuoteTitle>

            <St.DetailTitle sx={{ marginTop: '1rem' }}>{t('dollar_quotes_type')}</St.DetailTitle>

            <St.QuotesTypeBox>
                {instrument.tickers.map((ticker, index) => (
                    <St.QuoteTypeName key={index}>
                        {ticker.currency}
                        <St.QuoteTypeValue>{ticker.ticker}</St.QuoteTypeValue>
                    </St.QuoteTypeName>
                ))}
            </St.QuotesTypeBox>

            <St.QuoteUpdateContainer>
                <St.QuoteUpdateBox>
                    <St.QuoteUpdateTitle>
                        {t('market_dollar_quotes_detail_last_update_field_label')}:
                    </St.QuoteUpdateTitle>
                    {new Date(updatedAt).toLocaleString()}
                </St.QuoteUpdateBox>
            </St.QuoteUpdateContainer>
        </St.MarketDollarQuotesDetail>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    detailData: DollarQuotes;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketDollarQuotesDetail.propTypes = propTypes;

export default MarketDollarQuotesDetail;
