import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ImageReaderModal.styled';
import { IconButton, Modal } from '@mui/material';
import CustomIconSelector from '../CustomIconSelector';

const ImageReaderModal = (props: Props) => {
    const { open, handleClose, imgUrl } = props;

    return (
        <Modal open={open} sx={{ div: { paddingTop: 0 } }}>
            <St.ImageReaderContainer>
                <St.CloseContainer>
                    <IconButton onClick={handleClose}>
                        <CustomIconSelector type="close" />
                    </IconButton>
                </St.CloseContainer>
                <St.Image src={imgUrl ?? ''} />
            </St.ImageReaderContainer>
        </Modal>
    );
};

const propTypes = {
    open: PropTypes.bool.isRequired,
};

interface extraProps {
    handleClose: () => void;
    imgUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ImageReaderModal.propTypes = propTypes;

export default ImageReaderModal;
