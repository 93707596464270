import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AbilitiesComponent from './AbilitiesComponent';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { AbilitiesListDefinition, RolesDefinition } from 'types/usersRoles/usersRoles.types';
import { FormikValues } from 'formik';

const AbilitiesComponentContainer = (props: Props) => {
    const { isAbilitiesLoading, abilitiesList, setFieldValue, values, row } = props;
    const { t } = useTranslation('usersRoles');
    const [abilitiesSubtitle, setAbilitiesSubtitle] = React.useState<string[]>([]);
    const [editValuesIsLoading, setEditValuesIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (abilitiesSubtitle?.length > 0) return;

        setEditValuesIsLoading(true);
        const results: string[] | undefined = [];

        row?.abilities?.forEach(abilityFromValues => {
            abilitiesList?.forEach(abilityFromList => {
                abilityFromList?.list?.forEach(ab => {
                    if (abilityFromValues.id === ab.id && abilityFromValues.name === ab.name) {
                        results.push(abilityFromList.name);
                    }
                });
            });
        });

        const uniqueArray = [...new Set(results)];
        setAbilitiesSubtitle(uniqueArray);
        setEditValuesIsLoading(false);
    }, [abilitiesList, row]);

    const handleSelectedItemsChange = (event: React.SyntheticEvent, ids: string[]) => {
        if (ids[0] === undefined) {
            setFieldValue('abilities', []);
            return;
        }

        const existInList = values?.abilities?.find(
            (ability: number) => ability === Number(ids[0]),
        );

        if (existInList) {
            setFieldValue(
                'abilities',
                ids?.map((ability: string) => Number(ability)),
            );
        } else {
            setFieldValue('abilities', [...values?.abilities, Number(ids[0])]);
        }
    };

    const handleExpandedItemsChange = (event: React.SyntheticEvent, itemIds: string[]) => {
        setAbilitiesSubtitle(itemIds);
    };

    const childProps = {
        ...props,
        t,
        isAbilitiesLoading,
        abilitiesList,
        handleSelectedItemsChange,
        values,
        abilitiesSubtitle,
        handleExpandedItemsChange,
        editValuesIsLoading,
    };

    return <AbilitiesComponent {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isAbilitiesLoading: boolean;
    abilitiesList: AbilitiesListDefinition[] | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    values: FormikValues;
    row: RolesDefinition | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AbilitiesComponentContainer.propTypes = propTypes;

export default AbilitiesComponentContainer;
