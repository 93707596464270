import PropTypes from 'prop-types';
import St from './ProfilePage.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomLoader from 'components/common/CustomLoader';
import { BackofficeUserProfileDefinition } from 'types/usersBackoffice/usersBackoffice.types';
import { AlertColor, Button, Grid } from '@mui/material';
import ProfilePageForm from './ProfilePageForm';
import { RoleData } from 'types/auth/auth.types';

export const ProfilePage = (props: Props) => {
    const { t, data, myUserDataIsLoading, openModal, setOpenModal, SnackBar, setSnackBarMessage } =
        props;

    return (
        <St.ProfileInputsBox>
            <St.ProfilePage data-testid="ProfilePage-page">
                <Grid container spacing={12} alignItems="start">
                    <Grid item xs={6}>
                        <St.ProfilePageTitle>{t('profile_page_title')}</St.ProfilePageTitle>
                    </Grid>
                    <Grid item xs={6}>
                        {data && !myUserDataIsLoading && (
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenModal(true);
                                }}>
                                {t('profile_page_change_password')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {openModal && (
                    <ProfilePageForm
                        openModal={openModal}
                        title={t('profile_page_change_password')}
                        setOpenModal={setOpenModal}
                        setSnackBarMessage={setSnackBarMessage}
                        uuid={data?.id}
                    />
                )}
                <SnackBar />
                {myUserDataIsLoading ? (
                    <CustomLoader />
                ) : (
                    <>
                        <Grid container spacing={12}>
                            <Grid item xs={5}>
                                <St.ProfileData>
                                    <St.Label>{t('profile_page_name')}:</St.Label>
                                    {data?.name}
                                </St.ProfileData>
                                <St.ProfileData>
                                    <St.Label>{t('profile_page_last_name')}:</St.Label>
                                    {data?.lastName}
                                </St.ProfileData>
                                <St.ProfileData>
                                    <St.Label>{t('profile_page_roles')}:</St.Label>
                                    {data?.assignedRoles?.length === 0
                                        ? '-'
                                        : data?.assignedRoles?.map(
                                              (rol: RoleData) => `${rol?.name} `,
                                          )}
                                </St.ProfileData>
                            </Grid>
                            <Grid item xs={5}>
                                <St.ProfileData>
                                    <St.Label>{t('profile_page_email')}:</St.Label>
                                    {data?.email}
                                </St.ProfileData>
                                <St.ProfileData>
                                    <St.Label>{t('profile_page_user_name')}:</St.Label>
                                    {data?.userName}
                                </St.ProfileData>
                            </Grid>
                        </Grid>
                    </>
                )}
            </St.ProfilePage>
        </St.ProfileInputsBox>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    data: BackofficeUserProfileDefinition | undefined;
    myUserDataIsLoading: boolean;
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    SnackBar: () => JSX.Element;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePage.propTypes = propTypes;

export default ProfilePage;
