import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { CollateralDefinition } from 'types/channelsCollateral/channelsCollateral.types';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCollateralDefinitions } from 'hooks/api/channelsCollateral.hooks';
import CustomLoader from 'components/common/CustomLoader/CustomLoader';
import { Modal } from '@mui/material';

const ChannelsCollateralPage = (props: Props) => {
    const { t, SnackBar, columns, actionColumns, isLoadingSubmit, refreshTable, headerFilters } =
        props;
    return (
        <>
            <CommonGrid
                title={t('collateral_list_title')}
                columns={columns}
                resource="channels_collateral"
                useGetData={useCollateralDefinitions}
                defaultSortFieldId="createdAt"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                refreshTable={refreshTable}
            />
            <SnackBar />
            {isLoadingSubmit && (
                <Modal open={true}>
                    <CustomLoader />
                </Modal>
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<CollateralDefinition>[];
    actionColumns: ActionColumn[];
    headerFilters?: CommonFilter[];
    refreshTable: boolean;
    isLoadingSubmit: boolean;
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralPage.propTypes = propTypes;

export default ChannelsCollateralPage;
