import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid, Typography } from '@mui/material';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { UserAddress } from 'types/users/users.api.types';
import AlertNoData from 'components/common/AlertNoData';
import { tCommon } from 'constants/appConstants';
import St from './ClientSectionAddress.styled';

const ClientSectionAddress = ({ contacts, addressTitle, t }: Props) => (
    <Grid container>
        {contacts.length ? (
            contacts.map(
                (
                    {
                        streetName,
                        streetNum,
                        addressExtra,
                        floor,
                        apartment,
                        sector,
                        tower,
                        square,
                        zipCode,
                        city,
                        state,
                        country,
                        contactType,
                    },
                    item,
                ) => (
                    <Fragment key={item}>
                        <Grid item xs={12} my={0.625}>
                            <Typography fontSize={16}>{addressTitle[contactType]}</Typography>
                        </Grid>
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_street')}
                            text={streetName}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_number')}
                            text={streetNum}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_addressExtra')}
                            text={addressExtra}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_floor')}
                            text={floor}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_apartment')}
                            text={apartment}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_sector')}
                            text={sector}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_tower')}
                            text={tower}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_square')}
                            text={square}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_city')}
                            text={city}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_state')}
                            text={state}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_country')}
                            text={country}
                        />
                        <ChannelsModalItemGrid
                            column={6}
                            label={t('clients_address_zipCode')}
                            text={zipCode}
                        />
                    </Fragment>
                ),
            )
        ) : (
            <St.AlertContainer>
                <AlertNoData title={t('no_data_was_found', tCommon)} />
            </St.AlertContainer>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    contacts: UserAddress[];
    addressTitle: { [key: string]: string };
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionAddress.propTypes = propTypes;

export default ClientSectionAddress;
