import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import UsersBackofficeForm from './UsersBackofficeForm';
import { useTranslation } from 'react-i18next';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { TFunction } from 'i18next';
import { FormikValues, useFormik } from 'formik';
import { BackofficeUsersRow } from 'types/usersBackoffice/usersBackoffice.types';
import { useCreateUsersBackoffice, useEditUsersBackoffice } from 'hooks/api/usersBackoffice.hooks';
import i18n from 'utils/i18n';
import { VALID_PASSWORD_REGEX } from 'utils/helpers/constants';
import { useGetRoles } from 'hooks/api/usersRoles.hooks';
import { useState, useEffect } from 'react';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (isEdit: boolean, row: BackofficeUsersRow) => ({
    name: isEdit ? row?.name : '',
    lastName: isEdit ? row?.lastName : '',
    email: isEdit ? row?.email : '',
    username: isEdit ? row?.username : '',
    password: isEdit ? row?.password : '',
    repeatPassword: isEdit ? row?.repeatPassword : '',
    roles: isEdit ? row?.assignedRoles : [],
});

const getValidationSchema = (t: TFunction<'usersBackoffice', undefined, 'usersBackoffice'>) =>
    Yup.lazy(() =>
        Yup.object().shape({
            name: Yup.string().required(tRequiredFieldError),
            lastName: Yup.string().required(tRequiredFieldError),
            email: Yup.string().required(tRequiredFieldError),
            username: Yup.string().required(tRequiredFieldError),
            password: Yup.string()
                .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
                .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
                .when('isEdit', {
                    is: false,
                    then: Yup.string().required(tRequiredFieldError),
                })
                .matches(
                    VALID_PASSWORD_REGEX,
                    String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
                ),
            repeatPassword: Yup.string()
                .oneOf(
                    [Yup.ref('password'), null],
                    String(
                        i18n.t(
                            'change_password_screen_passwords_field_do_not_match_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .when('isEdit', {
                    is: false,
                    then: Yup.string().required(tRequiredFieldError),
                }),
            roles: Yup.array().min(1, tRequiredFieldError),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const UsersBackofficeFormContainer = (props: Props) => {
    const { isEdit = false, row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('usersBackoffice');
    const [disableButton, setDisableButton] = useState(false);

    const { mutate: onCreateUsersBackoffice } = useCreateUsersBackoffice(close, setSnackBarMessage);
    const { mutate: onEditUsersBackoffice } = useEditUsersBackoffice(close, setSnackBarMessage);
    const { data: rolesData, isLoading: isRolesLoading } = useGetRoles();

    const handleSubmit = React.useCallback(
        async (data: FormikValues) => {
            const payload = {
                name: data?.name,
                lastName: data?.lastName,
                email: data?.email,
                username: data?.username,
                password: data?.password,
                roleIds: data?.roles?.map((rol: any) => rol?.id),
            };
            const payloadEdit = {
                name: data?.name,
                lastName: data?.lastName,
                email: data?.email,
                roleIds: data?.roles?.map((rol: any) => rol?.id),
            };
            isEdit
                ? onEditUsersBackoffice({ ...payloadEdit, id: row!.id })
                : onCreateUsersBackoffice(payload);
        },
        [isEdit, onCreateUsersBackoffice, onEditUsersBackoffice, row],
    );
    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(isEdit ?? false, row as BackofficeUsersRow),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit, isEdit, row, t],
    );

    const formik = useFormik(formikInitProps);

    const { values } = formik;

    useEffect(() => {
        setDisableButton(true);
        Object.entries(values).forEach(([key, value]) => {
            if (value === '' || value?.length === 0) setDisableButton(false);
        });
    }, [values]);

    const childProps = {
        ...props,
        t,
        close,
        formik,
        isEdit,
        rolesData,
        isRolesLoading,
        disableButton,
    };
    return <UsersBackofficeForm {...childProps} />;
};
const propTypes = {
    isEdit: PropTypes.bool,
};
interface extraProps {
    row?: BackofficeUsersRow;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
    actionSelector: { id: number; name: string } | null;
    setActionSelector: React.Dispatch<React.SetStateAction<null>>;
}
interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficeFormContainer.propTypes = propTypes;

export default UsersBackofficeFormContainer;
