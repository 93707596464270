import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HelmetLayout from './HelmetLayout';
import { useTranslation } from 'react-i18next';

const HelmetLayoutContainer = (props: Props) => {
    const { withOutlet = true } = props;
    const { t } = useTranslation();

    const childProps = {
        ...props,
        withOutlet: withOutlet ?? true,
        t,
    };

    return <HelmetLayout {...childProps} />;
};

const propTypes = {
    withOutlet: PropTypes.bool,
};

interface extraTypes {}

interface Props extends InferPropsExtended<typeof propTypes, extraTypes> {}
HelmetLayoutContainer.propTypes = propTypes;

export default HelmetLayoutContainer;
