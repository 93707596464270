import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionExtraInfo from './ClientSectionExtraInfo';
import { ClientExtraInfo } from 'types/clients/clients.types';
import { useTranslation } from 'react-i18next';

const ClientSectionExtraInfoContainer = (props: Props) => {
    const { t } = useTranslation('clients');
    const childProps = {
        ...props,
        t,
    };

    return <ClientSectionExtraInfo {...childProps} />;
};

const propTypes = {};

interface extraProps extends ClientExtraInfo {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionExtraInfoContainer.propTypes = propTypes;

export default ClientSectionExtraInfoContainer;
