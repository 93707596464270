import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import { useBackoffice } from 'hooks/api/usersBackoffice.hooks';
import { BackofficeUsersDefinition } from 'types/usersBackoffice/usersBackoffice.types';

const UsersBackofficePage = (props: Props) => {
    const { t, columns, headerActions, actionColumns, headerFilters } = props;

    return (
        <CommonGrid
            title={t('backoffice_users_list_title')}
            columns={columns}
            resource="backoffice"
            useGetData={useBackoffice}
            defaultSortFieldId="createdAt"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            headerFilters={headerFilters}
            rowHeight={40}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<BackofficeUsersDefinition>[];
    headerActions: ActionHeader[];
    actionColumns: ActionColumn[];
    headerFilters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficePage.propTypes = propTypes;

export default UsersBackofficePage;
