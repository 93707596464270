import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import UserRolesForm from './UserRolesForm';
import { useTranslation } from 'react-i18next';
import { tRequiredFieldError } from 'constants/appConstants';
import { TFunction } from 'i18next';
import {
    AssignedAbilityDefinition,
    RolesDefinition,
    RolesFormValues,
} from 'types/usersRoles/usersRoles.types';
import { useCreateUsersRole, useEditUsersRole, useGetAbilities } from 'hooks/api/usersRoles.hooks';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getAbilitiesIds = (abilities: AssignedAbilityDefinition[]) => {
    return abilities?.map((a: AssignedAbilityDefinition) => a.id);
};

const getInitialValues = (isEdit: boolean, row: RolesDefinition) => ({
    name: isEdit ? row?.name : '',
    description: isEdit ? row?.description : '',
    abilities: isEdit ? getAbilitiesIds(row?.abilities as AssignedAbilityDefinition[]) : [],
});

const getValidationSchema = (t: TFunction<'userRoles', undefined, 'userRoles'>) =>
    Yup.lazy(() =>
        Yup.object().shape({
            name: Yup.string().required(tRequiredFieldError),
            description: Yup.string().required(tRequiredFieldError),
            abilities: Yup.array().min(1, tRequiredFieldError),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const UserRolesFormContainer = (props: Props) => {
    const { isEdit = false, row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('usersRoles');

    const { mutate: onCreateUsersRole } = useCreateUsersRole(close, setSnackBarMessage);
    const { mutate: onEditUsersRole } = useEditUsersRole(close, setSnackBarMessage);
    const { abilitiesData: abilitiesList, isLoading: isAbilitiesLoading } = useGetAbilities();

    const handleSubmit = React.useCallback(
        async (data: RolesFormValues) => {
            const payload = {
                name: data?.name,
                description: data?.description,
                abilities: data?.abilities,
                enabled: true,
            };
            isEdit ? onEditUsersRole({ ...payload, id: row!.id }) : onCreateUsersRole(payload);
        },
        [isEdit, onCreateUsersRole, onEditUsersRole, row],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(isEdit ?? false, row as RolesDefinition),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit, isEdit, row, t],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        close,
        disabledForm:
            JSON.stringify(formik.values) ===
            JSON.stringify(getInitialValues(isEdit ?? false, row as RolesDefinition)),
        isAbilitiesLoading,
        abilitiesList,
        row,
    };

    return <UserRolesForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool,
};

interface extraProps {
    row?: RolesDefinition;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserRolesFormContainer.propTypes = propTypes;

export default UserRolesFormContainer;
