import { useCallback } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientAcceptDeleteAccount from './ClientAcceptDeleteAccount';
import { ModalActionProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useTranslation } from 'react-i18next';
import { useAcceptDeleteAccountRequest } from 'hooks/api/clientDeleteAccountRequest.hooks';

const ClientAcceptDeleteAccountContainer = ({ row: { id }, close, setSnackBarMessage }: Props) => {
    const { t } = useTranslation('clientDeleteAccountRequest');
    const { mutate, isLoading } = useAcceptDeleteAccountRequest(close, setSnackBarMessage);

    const handleSubmit = useCallback(() => mutate({ id }), []);

    const childProps = {
        isLoading,
        t,
        close,
        handleSubmit,
    };

    return <ClientAcceptDeleteAccount {...childProps} />;
};

const propTypes = {};

interface extraProps extends ModalActionProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientAcceptDeleteAccountContainer.propTypes = propTypes;

export default ClientAcceptDeleteAccountContainer;
