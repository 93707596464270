import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Button, Grid, Typography } from '@mui/material';
import { getActionsByStatus } from 'utils/helpers/commonHelper';
import { TFunction } from 'i18next';

const ChannelsModalActionButton = ({ status, setAction, textByType, t }: Props) => (
    <Grid container item direction="column" alignSelf="baseline" xs={4}>
        <Typography fontSize={16} fontWeight={600}>
            {t('actions')}
        </Typography>
        {getActionsByStatus(status)?.map(type => (
            <Grid key={type} item xs mt={1.75}>
                <Button
                    size="small"
                    variant="outlined"
                    color={type === 'reject' ? 'error' : 'primary'}
                    fullWidth
                    onClick={() => setAction(type)}>
                    {textByType[type]}
                </Button>
            </Grid>
        ))}
    </Grid>
);

const propTypes = {};

interface extraProps {
    status: string;
    textByType: { [key: string]: string };
    setAction(action: string): void;
    t: TFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalActionButton.propTypes = propTypes;

export default ChannelsModalActionButton;
