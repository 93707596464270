import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientBankAccountsDetailActions from './ClientBankAccountsDetailActions';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
    useApproveBankAccount,
    useEditBankAccount,
    useRejectBankAccount,
} from 'hooks/api/clientBankAccounts.hooks';
import { AlertColor } from '@mui/material';

const ClientBankAccountsDetailActionsContainer = (props: Props) => {
    const { id, type, currency, close, setSnackBarMessage } = props;
    const { t } = useTranslation('clientBankAccounts');
    const [reason, setReason] = useState('');
    const [action, setAction] = useState('');
    const [approveInformation, setApproveInformation] = useState({
        type,
        currency,
    } as {
        type: string;
        currency: string;
    });
    const { mutate: mutateApprove, isLoading: loadingApprove } = useApproveBankAccount(
        setSnackBarMessage,
        close,
    );
    const { mutate: mutateEdit, isLoading: loadingEdit } = useEditBankAccount(
        setSnackBarMessage,
        close,
    );
    const { mutate: mutateReject, isLoading: loadingReject } = useRejectBankAccount(
        setSnackBarMessage,
        close,
    );
    const { mutate: mutateDelete, isLoading: loadingDelete } = useApproveBankAccount(
        setSnackBarMessage,
        close,
    );

    const handleChange = (name: string, value: string | null) =>
        setApproveInformation({ ...approveInformation, [name]: value });

    const handleSubmit = () => {
        switch (action) {
            case 'approve':
                return mutateApprove({ id, body: approveInformation });
            case 'edit':
                return mutateEdit({ id, body: approveInformation });
            case 'reject':
                return mutateReject({ id, body: { reason } });
            case 'delete':
                return mutateDelete({ id, body: approveInformation });
            default:
                break;
        }
    };

    const childProps = {
        ...props,
        loading: loadingApprove || loadingEdit || loadingReject || loadingDelete,
        disabled:
            loadingApprove ||
            loadingEdit ||
            loadingReject ||
            loadingDelete ||
            (action === 'reject' && !reason),
        approveInformation,
        action,
        reason,
        setReason,
        setAction,
        handleSubmit,
        handleChange,
        t,
    };

    return <ClientBankAccountsDetailActions {...childProps} />;
};

const propTypes = {};

interface extraProps {
    id: string;
    status: string;
    type: string;
    currency: string;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsDetailActionsContainer.propTypes = propTypes;

export default ClientBankAccountsDetailActionsContainer;
