import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableHeaderActions from './TableHeaderActions';
import {
    ActionHeader,
    ExtraQueryFiltersProps,
    GridHookParams,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { CommonGridContext } from 'context/common/commonGrid.context';

const TableHeaderActionsContainer = (props: Props) => {
    const { setSearchText, exportName, resource, total, filterHeaderString, extraFilters } = props;
    const { matchesSm } = useBreakpoints();
    const { showDisabled, setShowDisabled } = React.useContext(CommonGridContext);

    const handleSearchChange = async (e: any) => {
        setTimeout(() => {
            setSearchText(e.target.value);
        }, 1000);
    };

    const childProps = {
        ...props,
        matchesSm,
        showDisabled,
        setShowDisabled,
        handleSearchChange,
        filterHeaderString:
            extraFilters && extraFilters.length > 0
                ? extraFilters
                      .map(el => `${el.query}=${el.queryValue}`)
                      .join('&')
                      .concat(filterHeaderString)
                : filterHeaderString,
        exportTitle: (exportName || resource) ?? '',
        total: total ?? 0,
    };

    return <TableHeaderActions {...childProps} />;
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    canDisable: PropTypes.bool,
    canSearch: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    setSearchText: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    exportName: PropTypes.string,
};

interface extraProps {
    extraActionsInHeader?: ActionHeader[];
    filterHeaderString: string;
    fetchData?: (params: GridHookParams) => any;
    canExport?: boolean;
    total?: number;
    extraFilters?: ExtraQueryFiltersProps[];
    exportDateRangeEnabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderActionsContainer.propTypes = propTypes;

export default TableHeaderActionsContainer;
