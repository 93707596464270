import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ActionIconButton from './ActionIconButton';
import { ActionIconButtonProps } from 'types/common/channels.types';

const ActionIconButtonContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <ActionIconButton {...childProps} />;
};

const propTypes = {};

interface extraProps extends ActionIconButtonProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ActionIconButtonContainer.propTypes = propTypes;

export default ActionIconButtonContainer;
