import { Stack } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import ErrorText from 'components/common/ErrorText';
import { Form, FormikProvider } from 'formik';
import { TFunction } from 'i18next';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MarketExteriorInstrumentForm.styled';
import ActionButton from 'components/common/ActionButton';
import { TICKER_REGEX } from 'utils/helpers/constants';
import { tCommon } from 'constants/appConstants';

const MarketExteriorInstrumentForm = (props: Props) => {
    const { formik, t, isEdit = false, isLoadingSubmitForm, close } = props;

    return (
        <FormikProvider value={formik} data-testid="nonWorkingDaysForm-form">
            <Form>
                <Stack spacing={2} marginTop={'10px'} padding={'10px'}>
                    <CustomInput
                        values={formik.values}
                        errors={formik.errors}
                        field="ticker"
                        placeholder={
                            t('market_exterior_instruments_form_ticker_placeholder') as string
                        }
                        required
                        setFieldValue={formik.setFieldValue}
                        autoFocus
                        label={t('market_exterior_instruments_form_ticker_label')}
                        regex={TICKER_REGEX}
                    />
                    <CustomInput
                        values={formik.values}
                        errors={formik.errors}
                        field="description"
                        placeholder={
                            t('market_exterior_instruments_form_description_placeholder') as string
                        }
                        label={t('market_exterior_instruments_form_description_label')}
                        required
                        setFieldValue={formik.setFieldValue}
                    />
                    {formik.errors.lenght && (
                        <ErrorText text={t('market_exterior_instruments_requerired_fields')} />
                    )}
                </Stack>
                <St.FooterButtons direction={'row'} spacing={1}>
                    <ActionButton onClick={close} variant="outlined">
                        {t('cancel', tCommon)}
                    </ActionButton>
                    <ActionButton loading={isLoadingSubmitForm} type="submit" variant="contained">
                        {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                    </ActionButton>
                </St.FooterButtons>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isEdit: PropTypes.bool,
    isLoadingSubmitForm: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: any;
    t: TFunction;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketExteriorInstrumentForm.propTypes = propTypes;

export default MarketExteriorInstrumentForm;
