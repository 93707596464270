import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AssociatedPersonTable from './AssociatedPersonTable';
import { useTranslation } from 'react-i18next';
import { AssociatedPersonTableContainerProps } from 'types/clientImportClientVB/clientImportClientVB.types';
import { useSendCredentials } from 'hooks/api/clientImportClientVB.hooks';
import { tCommon } from 'constants/appConstants';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

const AssociatedPersonTableContainer = (props: Props) => {
    const { email, setSnackBarMessage } = props;
    const { t } = useTranslation('clientImportClientVB');
    const [registeModal, setRegisterModal] = React.useState(false);
    const [showRegisterButton, setShowRegisterButton] = React.useState(props.enableRegister);

    const { mutate: mutateSendCredentials, isLoading: isLoadingSendCredentials } =
        useSendCredentials();
    const sendCredentials = () =>
        mutateSendCredentials(
            { email },
            {
                onSuccess: () => setSnackBarMessage(t('action_performed_successfully', tCommon)),
                onError(error) {
                    setSnackBarMessage(errorResponseHelper(error), 'error');
                },
            },
        );

    const childProps = {
        ...props,
        isLoadingSendCredentials,
        sendCredentials,
        registeModal,
        setRegisterModal,
        showRegisterButton,
        setShowRegisterButton,
        t,
    };

    return <AssociatedPersonTable {...childProps} />;
};

const propTypes = {};

interface extraProps extends AssociatedPersonTableContainerProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AssociatedPersonTableContainer.propTypes = propTypes;

export default AssociatedPersonTableContainer;
