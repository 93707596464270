import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { InstrumentRuleConditions } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { currenciesBaseArray, tCommonGrid, termsBaseArray } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';

const InstrumentConditionsTable = (props: Props) => {
    const {
        t,
        conditions,
        hasDeleteAction = false,
        handleCheckTermInRow,
        instrumentTypesOptions,
        handleCheckCurrencyInRow,
        handleDeleteInstrumentCondition,
    } = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="instrument conditions table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('type_or_name_column')}</TableCell>
                        <TableCell>{t('terms_and_currencies')}</TableCell>
                        {hasDeleteAction && (
                            <TableCell align="right" sx={{ pr: 4 }}>
                                {t('actions', tCommonGrid)}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {conditions.map((condition: InstrumentRuleConditions, index: number) => (
                        <TableRow key={index}>
                            <TableCell>
                                {condition.ticker ||
                                    instrumentTypesOptions?.filter(
                                        i => i.value === condition.instrumentType,
                                    )[0]?.name}
                            </TableCell>
                            <TableCell>
                                <FormGroup aria-label="position" row>
                                    {termsBaseArray.map(t => (
                                        <FormControlLabel
                                            key={t}
                                            onChange={() => handleCheckTermInRow(condition, t)}
                                            control={
                                                <Checkbox checked={condition.terms.includes(t)} />
                                            }
                                            label={t}
                                            labelPlacement="top"
                                        />
                                    ))}
                                    {currenciesBaseArray.map(t => (
                                        <FormControlLabel
                                            key={t}
                                            onChange={() => handleCheckCurrencyInRow(condition, t)}
                                            control={
                                                <Checkbox
                                                    checked={condition.currencies.includes(t)}
                                                />
                                            }
                                            label={t}
                                            labelPlacement="top"
                                        />
                                    ))}
                                </FormGroup>
                            </TableCell>
                            {hasDeleteAction && (
                                <TableCell align="right" sx={{ pr: 5 }}>
                                    <IconButton onClick={() => handleDeleteInstrumentCondition()}>
                                        <CustomIconSelector
                                            type="delete"
                                            sx={{ color: defaultTheme.palette.error.main }}
                                        />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    hasDeleteAction: PropTypes.bool,
    handleCheckTermInRow: PropTypes.func.isRequired,
    handleCheckCurrencyInRow: PropTypes.func.isRequired,
    handleDeleteInstrumentCondition: PropTypes.func.isRequired,
};

interface extraProps {
    conditions: InstrumentRuleConditions[];
    instrumentTypesOptions: { id: number; name: string; value: string }[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentConditionsTable.propTypes = propTypes;

export default InstrumentConditionsTable;
