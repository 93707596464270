import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { tCommon } from 'constants/appConstants';
import CustomInput from 'components/common/CustomInput';

const ClientDeleteOrRejectAccontRequestForm = ({
    formik,
    isDelete,
    isLoading,
    t,
    close,
}: Props) => (
    <FormikProvider value={formik} data-testid="ClientDeleteOrRejectAccontRequestForm-form">
        <Form>
            <DialogContent>
                <DialogContentText mb={1.25}>
                    {t(`delete_account_request_modal_question_${isDelete ? 'delete' : 'reject'}`)}
                </DialogContentText>
                <CustomInput
                    field="reason"
                    errors={formik.errors}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values?.reason}
                    label={t('delete_account_request_add_reason')}
                    placeholder={t('delete_account_request_add_reason')}
                    required
                />
            </DialogContent>
            <DialogActions>
                <ActionButton
                    type="button"
                    size="small"
                    variant="outlined"
                    onClick={close}
                    color="primary">
                    {t('cancel', tCommon)}
                </ActionButton>

                <ActionButton
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={isLoading}
                    loading={isLoading}
                    color="primary">
                    {t('confirm', tCommon)}
                </ActionButton>
            </DialogActions>
        </Form>
    </FormikProvider>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    isDelete?: boolean;
    isLoading: boolean;
    formik: any;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDeleteOrRejectAccontRequestForm.propTypes = propTypes;

export default ClientDeleteOrRejectAccontRequestForm;
