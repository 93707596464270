import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import SettingsNonWorkingDaysForm from './SettingsNonWorkingDaysForm';
import { useTranslation } from 'react-i18next';
import {
    MarketOptions,
    SettingsNonWorkingDays,
    SettingsNonWorkingDaysFormValues,
} from 'types/settingsNonWorkingDays/settingsNonWorking.types';
import { tRequiredFieldError } from 'constants/appConstants';
import {
    useCreateMarketnonWorkingDay,
    useEditMarketnonWorkingDay,
    useGetMarkets,
} from 'hooks/api/settingsNonWorkingDays.hooks';
import { TFunction } from 'i18next';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (isEdit: boolean, row: SettingsNonWorkingDays) => ({
    markets: isEdit ? row.markets : [],
    dateFrom: isEdit ? new Date(row.dateFrom).getTime() : '',
    dateTo: isEdit ? new Date(row.dateTo).getTime() : '',
});

const getValidationSchema = (
    t: TFunction<'SettingsNonWorkingDays', undefined, 'SettingsNonWorkingDays'>,
) =>
    Yup.lazy(() =>
        Yup.object().shape({
            markets: Yup.array().min(1, tRequiredFieldError),
            dateFrom: Yup.string().required(tRequiredFieldError),
            dateTo: Yup.string().when('dateFrom', (dateFrom, schema) => {
                return schema.test({
                    test: (dateTo: any) => {
                        if (!dateTo || !dateFrom) {
                            return true;
                        }
                        return dateTo >= dateFrom;
                    },
                    message: t('market_non_working_days_form_date_range_error'),
                });
            }),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const SettingsNonWorkingDaysFormContainer = (props: Props) => {
    const { isEdit = false, row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('SettingsNonWorkingDays');

    const { data: marketsData, isLoading: isMarketsLoading } = useGetMarkets();

    const { mutate: onCreatenonWorkingDay } = useCreateMarketnonWorkingDay(
        close,
        setSnackBarMessage,
    );
    const { mutate: onEditnonWorkingDay } = useEditMarketnonWorkingDay(close, setSnackBarMessage);

    const handleSubmit = React.useCallback(
        async (data: SettingsNonWorkingDaysFormValues) => {
            const marketIDs: number[] = data.markets.map((market: MarketOptions) => market.id);

            const payload = {
                dateFrom: data.dateFrom,
                dateTo: data.dateTo === '' ? data.dateFrom : data.dateTo,
                marketIds: marketIDs,
            };

            isEdit
                ? onEditnonWorkingDay({ ...payload, id: row!.id })
                : onCreatenonWorkingDay(payload);
        },
        [isEdit, onCreatenonWorkingDay, onEditnonWorkingDay, row],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(isEdit ?? false, row as SettingsNonWorkingDays),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit, isEdit, row, t],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        close,
        marketOptions: isMarketsLoading ? [] : marketsData?.data || [],
        disabledForm:
            JSON.stringify(formik.values) ===
            JSON.stringify(getInitialValues(isEdit ?? false, row as SettingsNonWorkingDays)),
    };

    return <SettingsNonWorkingDaysForm {...childProps} />;
};

const propTypes = {
    isEdit: PropTypes.bool,
};

interface extraProps {
    row?: SettingsNonWorkingDays;
    close: () => void;
    setSnackBarMessage: (message: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsNonWorkingDaysFormContainer.propTypes = propTypes;

export default SettingsNonWorkingDaysFormContainer;
