import React, { useEffect, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BankAccountsDetails from './BankAccountsDetails';
import { UserBankAccount } from 'types/users/users.api.types';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import { useGetUserBankAccounts } from 'hooks/api/users.hooks';
import { useTranslation } from 'react-i18next';

const BankAccountsDetailsContainer = (props: Props) => {
    const { t } = useTranslation();
    const { setSnackBarMessage } = useSnackBar();
    const [bankAccountsDetails, setBankAccountsDetails] = useState([] as UserBankAccount[]);
    const { mutate, isLoading } = useGetUserBankAccounts(setSnackBarMessage);
    const [pagination, setPagination] = useState(
        {} as { total: number; page: number; pageSize: number },
    );

    const getClientBankAccounts = () =>
        mutate(
            {
                pagination: `page=${pagination.page || 1}`,
                filter: `&userUuid=${props.id}&status=ACTIVE`,
            },
            {
                onSuccess: (res: any) => {
                    setBankAccountsDetails(res.data.items);
                    setPagination({ ...res.data });
                },
            },
        );

    const onPageChange = (newPage: number) => setPagination({ ...pagination, page: newPage + 1 });

    useEffect(() => {
        if (props.id && props.showBankDetails) getClientBankAccounts();
    }, [props.id, pagination.page]);

    const childProps = {
        ...props,
        isLoading,
        bankAccountsDetails,
        pagination,
        onPageChange,
        t,
    };

    return <BankAccountsDetails {...childProps} />;
};

const propTypes = {};

interface extraProps {
    id: string;
    showBankDetails: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountsDetailsContainer.propTypes = propTypes;

export default BankAccountsDetailsContainer;
