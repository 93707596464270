import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { SettingsNonWorkingDays } from 'types/settingsNonWorkingDays/settingsNonWorking.types';
import CommonGrid from 'components/common/CommonGrid';
import {
    useDeleteMarketnonWorkingDay,
    useSettingsNonWorkingDays,
} from 'hooks/api/settingsNonWorkingDays.hooks';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';

const SettingsNonWorkingPage = (props: Props) => {
    const { t, columns, headerActions, actionColumns, headerFilters } = props;

    return (
        <CommonGrid
            title={t('market_non_working_days_list_title')}
            columns={columns}
            resource="market-non-working-days"
            useGetData={useSettingsNonWorkingDays}
            defaultSortFieldId="dateFrom"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            deleteHook={useDeleteMarketnonWorkingDay}
            rowHeight={40}
            headerFilters={headerFilters}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<SettingsNonWorkingDays>[];
    headerActions: ActionHeader[];
    actionColumns: ActionColumn[];
    headerFilters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsNonWorkingPage.propTypes = propTypes;

export default SettingsNonWorkingPage;
