import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/alerts/alerts.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { alertsAdapter } from 'adapters/api/alerts.adapter';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useAlerts = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['alerts', filterQueryString],
        () => endpoints.getAlerts(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return alertsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
export const useGetAlerts = (isAuthenticated: boolean) => {
    const { data, isLoading, error } = useQuery(['get-alerts'], () => endpoints.getAlerts(), {
        enabled: isAuthenticated,
        select: data => data.data,
    });

    return {
        data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useCreateAlert = () => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.createAlert);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: errorResponseHelper(error as any),
        successfulMessage: t('alerts_created_successfully'),
    };
};

export const useEditAlert = () => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.editAlert);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: errorResponseHelper(error as any),
        successfulMessage: t('alerts_edited_successfully'),
    };
};

export const useDeleteAlert = () => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteAlert);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: errorResponseHelper(error as any),
        successfulMessage: t('alerts_deleted_successfully'),
    };
};
