import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid } from '@mui/material';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';

const ClientSectionTaxSituation = (props: Props) => {
    const { earningRegistration, t, taxCondition } = props;
    return (
        <Grid container>
            <ChannelsModalItemGrid
                label={t('clients_tax_situation_taxesCondition')}
                text={taxCondition}
            />
            <ChannelsModalItemGrid
                label={t('clients_tax_situation_earningsRegistration')}
                text={earningRegistration}
            />
        </Grid>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    earningRegistration: PropTypes.string.isRequired,
    taxCondition: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionTaxSituation.propTypes = propTypes;

export default ClientSectionTaxSituation;
