import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomTableAction from './CustomTableAction';
import { useTranslation } from 'react-i18next';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import useDialog from 'hooks/common/CommonGrid/dialog.hooks';
import {
    CustomIconProps,
    ModalActionProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { SXBreakpoint } from 'types/common/general.types';
import { ReactElement } from 'react';

const CustomTableActionContainer = (props: Props) => {
    const { width = '40%' } = props;
    const { isOpen, open, close } = useDialog();
    const { setSnackBarMessage } = useSnackBar();
    const { t } = useTranslation();

    const childProps = {
        t,
        isOpen,
        open,
        close,
        setSnackBarMessage,
        width,
        ...props,
    };

    return <CustomTableAction {...childProps} />;
};

const propTypes = {
    modalTitle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

interface extraProps {
    component?: (props: ModalActionProps) => JSX.Element;
    Icon: (props: CustomIconProps) => JSX.Element;
    row?: any;
    width?: SXBreakpoint<string | number>;
    ButtonElement?: (props: { open: () => void }) => JSX.Element;
    customizedTitle?: (row: any) => ReactElement;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomTableActionContainer.propTypes = propTypes;

export default CustomTableActionContainer;
