import { ALL_USERS } from 'constants/abilities.constants';
import { useGetAlerts } from 'hooks/api/alerts.hooks';
import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { getIsAuthenticated } from 'storage/auth.storage';
import { AlertsApi } from 'types/alerts/alerts.api.types';
import { AbilityData, RoleData, UserData } from 'types/auth/auth.types';

interface IntialState {
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
    userType: string;
    setUserType: Dispatch<SetStateAction<string>>;
    userAbilities: string[] | null;
    handleUserAbilities: (roles: RoleData[] | null, abilities: AbilityData[] | null) => void;
    userData: UserData | null;
    setUserData: Dispatch<SetStateAction<UserData | null>>;
    alertNotification: AlertsApi[] | undefined;
    setAlertNotification: Dispatch<SetStateAction<AlertsApi[] | undefined>>;
    isLoadingAlert: boolean;
    errorMessageAlert: string | null;
}

export const AuthContext = createContext({} as IntialState);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(getIsAuthenticated());
    const [userData, setUserData] = React.useState<UserData | null>(null);
    const [userType, setUserType] = React.useState<string>('default');
    const [userAbilities, setUserAbilities] = React.useState<string[] | null>(
        JSON.parse(localStorage.getItem('userAbilities') as string) ?? null,
    );

    const [alertNotification, setAlertNotification] = React.useState<AlertsApi[] | undefined>();
    const {
        data: alerts,
        errorMessage: errorMessageAlert,
        isLoading: isLoadingAlert,
    } = useGetAlerts(isAuthenticated);

    React.useEffect(() => {
        if (isAuthenticated && alerts?.items) {
            const filteredAlerts = alerts.items.filter(
                alert => alert.active && alert.recipients.includes('backofficeUsers'),
            );
            setAlertNotification(prev =>
                JSON.stringify(prev) !== JSON.stringify(filteredAlerts) ? filteredAlerts : prev,
            );
        }
    }, [alerts, isAuthenticated, userType]);

    const handleUserAbilities = useCallback(
        (roles: RoleData[] | null, abilities: AbilityData[] | null) => {
            if (!roles && !abilities) setUserAbilities(null);
            const aux = [];
            roles?.forEach(r => {
                if (r.assignedAbilities) {
                    aux.push(...r.assignedAbilities);
                }
            });
            if (abilities) {
                aux.push(...abilities);
            }
            const asignedAbilities = [
                ALL_USERS,
                ...[...new Set(aux)].filter(a => a.enabled).map(a => a.name),
            ];
            setUserAbilities(asignedAbilities);
            localStorage.setItem('userAbilities', JSON.stringify(asignedAbilities) ?? '');
        },
        [setUserAbilities],
    );

    const memoedValue = React.useMemo(() => {
        const value = {
            isAuthenticated,
            setIsAuthenticated,
            userType,
            setUserType,
            userData,
            setUserData,
            userAbilities,
            handleUserAbilities,
            alertNotification,
            setAlertNotification,
            isLoadingAlert,
            errorMessageAlert,
        };
        return value;
    }, [
        isAuthenticated,
        setIsAuthenticated,
        userType,
        setUserType,
        userData,
        setUserData,
        userAbilities,
        handleUserAbilities,
        alertNotification,
        setAlertNotification,
        isLoadingAlert,
        errorMessageAlert,
    ]);

    return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
