import {
    SENT,
    EXPIRED,
    PENDING,
    ASSIGNED,
    REJECTED,
    CANCELLED,
    COMPLETED,
    PARTIALLY_FILLED,
    EXPIRED_PARTIALLY,
    CANCELLED_PARTIALLY,
    PENDING_ORDER,
} from 'constants/appConstants';
import { t } from 'i18next';

export const statesToShowActions = [ASSIGNED, COMPLETED];

export const statesToShowActionsContainer = [PENDING, ASSIGNED];

export const statusOptions = [
    { id: PENDING, name: t(PENDING_ORDER) },
    { id: SENT, name: t(SENT) },
    { id: PARTIALLY_FILLED, name: t(PARTIALLY_FILLED) },
    { id: COMPLETED, name: t(COMPLETED) },
    { id: EXPIRED, name: t(EXPIRED) },
    { id: EXPIRED_PARTIALLY, name: t(EXPIRED_PARTIALLY) },
    { id: CANCELLED, name: t(CANCELLED) },
    { id: CANCELLED_PARTIALLY, name: t(CANCELLED_PARTIALLY) },
    { id: REJECTED, name: t(REJECTED) },
    { id: ASSIGNED, name: t(ASSIGNED) },
];
