import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './AppBar.styled';
import { IconButton } from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector';

const AppBar = (props: Props) => {
    const { mainLogo, handleDrawerOpen } = props;
    return (
        <St.FixedAppBar>
            <St.Toolbar>
                <IconButton aria-label="open drawer" onClick={handleDrawerOpen}>
                    <CustomIconSelector type="menu" />
                </IconButton>
                <St.Logo inheritViewBox component={mainLogo} />
            </St.Toolbar>
        </St.FixedAppBar>
    );
};

const propTypes = {
    handleDrawerOpen: PropTypes.func.isRequired,
};

interface extraProps {
    mainLogo: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AppBar.propTypes = propTypes;

export default AppBar;
