// import * as React from 'react';
import i18n from 'utils/i18n';
import { AvailabityType, RouteInfo, SubRouteInfo } from 'utils/helpers/routesHelper';
// Components
import MainLayout from 'components/layouts/MainLayout';
import HelmetLayout from 'components/layouts/HelmetLayout';
import ErrorPage from 'components/pages/ErrorPage';
import NoPermissionsPage from 'components/pages/NoPermissionsPage';
import LoginPage from 'components/pages/LoginPage';
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage';
import EmailVerificationPage from 'components/pages/EmailVerificationPage';
import SettingsNonWorkingPage from 'components/pages/SettingsNonWorkingPage';
import SettingsInstrumentRulesPage from 'components/pages/SettingsInstrumentRulesPage';
import MarketExteriorInstrumentPage from 'components/pages/MarketExteriorInstrumentPage';
import MarketDollarQuotesPage from 'components/pages/MarketDollarQuotesPage';
import ClientsPage from 'components/pages/ClientsPage';
import SettingsAlertsPage from 'components/pages/SettingsAlertsPage';
import ClientBankAccountsPage from 'components/pages/ClientBankAccountsPage';
import ClientDeleteAccountRequestPage from 'components/pages/ClientDeleteAccountRequestPage';
import ChannelsAffidavitPage from 'components/pages/ChannelsAffidavitPage';
import ChannelsCollateralPage from 'components/pages/ChannelsCollateralPage';
import ClientDepositsPage from 'components/pages/ClientDepositsPage';
import ChannelsOrdersPage from 'components/pages/ChannelsOrdersPage';
import ChannelsFundingPage from 'components/pages/ChannelsFundingPage';
import ClientExtractionsPage from 'components/pages/ClientExtractionsPage';
import ClientImportClientVBPage from 'components/pages/ClientImportClientVBPage';
import ClientDetail from 'components/pages/ClientsPage/ClientDetail';
import UserRolesPage from 'components/pages/UsersRolesPage';
import UsersBackofficePage from 'components/pages/UsersBackofficePage';
import ProfilePage from 'components/pages/ProfilePage';
import UsersBrokerPage from 'components/pages/UsersBrokerPage';
import SettingsBlocksPage from 'components/pages/SettingsBlocksPage';
import ChannelsExchangePage from 'components/pages/ChannelsExchangePage';
import UsersBusinessAssistantPage from 'components/pages/UsersBusinessAssistantPage';

// Icons
import MarketIcon from 'assets/icons/bank.svg?react';
import SettingsNonWorkingDaysIcon from 'assets/icons/calendar-close.svg?react';
import SettingsInstrumentRuleIcon from 'assets/icons/survey.svg?react';
import MarketExteriorInstrumentIcon from 'assets/icons/red-packet.svg?react';
import MarketDollarQuotesIcon from 'assets/icons/exchange-dollar.svg?react';
import ClientIcon from 'assets/icons/account-circle.svg?react';
import ClientsIcon from 'assets/icons/group-2.svg?react';
import ClientsProspectsIcon from 'assets/icons/file-list-2.svg?react';
import ClientsDepositsIcon from 'assets/icons/coupon.svg?react';
import ClientsExtractionsIcon from 'assets/icons/share-forward-2.svg?react';
import ClientsDeleteAccountRequestIcon from 'assets/icons/file-shred.svg?react';
import ClientsClientBankAccountsIcon from 'assets/icons/database.svg?react';
import AlertsIcon from 'assets/icons/notification-2.svg?react';
import ChannelsIcon from 'assets/icons/layout-4.svg?react';
import ChannelsCollateralIcon from 'assets/icons/safe-2.svg?react';
import ChannelsComercialIcon from 'assets/icons/book-2.svg?react';
import ChannelsFundingIcon from 'assets/icons/coins.svg?react';
import ChannelsOrdersIcon from 'assets/icons/shopping-basket.svg?react';
import ChannelsAffidavitIcon from 'assets/icons/file-user.svg?react';
import LogoutIcon from 'assets/icons/logout-box-r.svg?react';
import rolIcon from 'assets/icons/user-star-line.svg?react';
import usersIcon from 'assets/icons/team-line.svg?react';
import backofficeIcon from 'assets/icons/contacts-line.svg?react';
import userProfileIcon from 'assets/icons/account-pin-box-line.svg?react';
import commercialAgentIcon from 'assets/icons/user-voice-line.svg?react';
import ConfigurationIcon from 'assets/icons/settings-4.svg?react';
import BlockageIcon from 'assets/icons/shield-keyhole.svg?react';
import ChannelsExchangeIcon from 'assets/icons/bar-chart-box.svg?react';
import BusinessAssistantIcon from 'assets/icons/user-add-line.svg?react';
import getEnvVariables from 'utils/helpers/envVarsHelper';

// constants
import {
    ADMIN,
    ALL_USERS,
    GET_ALERTS,
    GET_APP_USERS,
    GET_APP_USERS_BANK_ACCOUNTS,
    GET_APP_USERS_REMOVE_REQUESTS,
    GET_DATES,
    GET_DECLARATIONS,
    GET_DEPOSITS,
    GET_DOCUMENTS,
    GET_LOCKED_FUNCTIONALITY,
    GET_ONBOARDING,
    GET_ORDERS_COLLATERAL,
    GET_ORDERS_EXCHANGE,
    GET_ORDERS_FUND,
    GET_ORDERS_MARKET,
    GET_QUOTATIONS,
    GET_RULES,
    GET_WITHDRAWALS,
} from 'constants/abilities.constants';
const { VITE_CMS_URL } = getEnvVariables();

export const hiddenMenuRoutes: Array<SubRouteInfo> = [];

export const menuMainRoutes = () => [
    {
        name: 'users',
        title: i18n.t('layout_main_navigation_users') as string,
        icon: usersIcon,
        abilities: [ADMIN],
        availability: 'logged_in' as AvailabityType,
        subRoutes: [
            {
                name: 'backoffice',
                path: '/users/backoffice',
                title: i18n.t('layout_main_navigation_backoffice') as string,
                component: UsersBackofficePage,
                icon: backofficeIcon,
                abilities: [ADMIN],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'roles',
                path: '/users/roles',
                title: i18n.t('layout_main_navigation_Roles') as string,
                component: UserRolesPage,
                icon: rolIcon,
                abilities: [ADMIN],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'business-assistant',
                path: '/users/business-assistant',
                title: i18n.t('layout_main_navigation_business_assistant') as string,
                component: UsersBusinessAssistantPage,
                icon: BusinessAssistantIcon,
                abilities: [ADMIN],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'broker',
                path: '/users/broker',
                title: i18n.t('layout_main_navigation_commercial_agent') as string,
                component: UsersBrokerPage,
                icon: commercialAgentIcon,
                abilities: [ADMIN],
                availability: 'logged_in' as AvailabityType,
            },
        ],
    },
    {
        name: 'market',
        title: i18n.t('layout_main_navigation_market') as string,
        icon: MarketIcon,
        abilities: [ALL_USERS],
        availability: 'logged_in' as AvailabityType,
        subRoutes: [
            {
                name: 'exterior_instrument',
                path: '/market/exterior-instrument',
                title: i18n.t('layout_main_navigation_exterior_instrument') as string,
                component: MarketExteriorInstrumentPage,
                icon: MarketExteriorInstrumentIcon,
                abilities: [ALL_USERS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'dollar_quotes',
                path: '/market/dollar-quotes',
                title: i18n.t('layout_main_navigation_dollar_quotes') as string,
                component: MarketDollarQuotesPage,
                icon: MarketDollarQuotesIcon,
                abilities: [ADMIN, GET_QUOTATIONS],
                availability: 'logged_in' as AvailabityType,
            },
        ],
    },
    {
        name: 'client',
        title: i18n.t('layout_main_navigation_client') as string,
        icon: ClientIcon,
        abilities: [
            ADMIN,
            GET_APP_USERS,
            GET_WITHDRAWALS,
            GET_APP_USERS_BANK_ACCOUNTS,
            GET_APP_USERS_REMOVE_REQUESTS,
            GET_DEPOSITS,
            GET_ONBOARDING,
        ],
        availability: 'logged_in' as AvailabityType,
        subRoutes: [
            {
                name: 'clients',
                path: '/client/clients',
                title: i18n.t('layout_main_navigation_clients') as string,
                component: () => <ClientsPage />,
                icon: ClientsIcon,
                abilities: [ADMIN, GET_APP_USERS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'prospects',
                path: '/client/prospects',
                title: i18n.t('layout_main_navigation_prospect') as string,
                component: () => <ClientsPage isProspect />,
                icon: ClientsProspectsIcon,
                abilities: [ADMIN, GET_ONBOARDING],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'extractions',
                path: '/client/extractions',
                title: i18n.t('layout_main_navigation_extractions') as string,
                component: ClientExtractionsPage,
                icon: ClientsExtractionsIcon,
                abilities: [ADMIN, GET_WITHDRAWALS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'bank_accounts',
                path: '/client/bank-accounts',
                title: i18n.t('layout_main_navigation_client_bank_accounts') as string,
                component: ClientBankAccountsPage,
                icon: ClientsClientBankAccountsIcon,
                abilities: [ADMIN, GET_APP_USERS_BANK_ACCOUNTS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'delete_account_request',
                path: '/client/delete-account-request',
                title: i18n.t('layout_main_navigation_delete_account_request') as string,
                component: ClientDeleteAccountRequestPage,
                icon: ClientsDeleteAccountRequestIcon,
                abilities: [ADMIN, GET_APP_USERS_REMOVE_REQUESTS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'deposits',
                path: '/client/deposits',
                title: i18n.t('layout_main_navigation_deposits') as string,
                component: ClientDepositsPage,
                icon: ClientsDepositsIcon,
                abilities: [ADMIN, GET_DEPOSITS],
                availability: 'logged_in' as AvailabityType,
            },
        ],
    },
    {
        name: 'channels',
        title: i18n.t('layout_main_navigation_channels') as string,
        availability: 'logged_in' as AvailabityType,
        icon: ChannelsIcon,
        abilities: [ALL_USERS],
        subRoutes: [
            {
                name: 'orders',
                path: '/channels/orders',
                title: i18n.t('layout_main_navigation_orders') as string,
                component: ChannelsOrdersPage,
                icon: ChannelsOrdersIcon,
                abilities: [ADMIN, GET_ORDERS_MARKET],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'exchange',
                path: '/channels/exchange',
                title: i18n.t('layout_main_navigation_exchange') as string,
                component: ChannelsExchangePage,
                icon: ChannelsExchangeIcon,
                abilities: [ADMIN, GET_ORDERS_EXCHANGE],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'collateral',
                path: '/channels/collateral',
                title: i18n.t('layout_main_navigation_collateral') as string,
                component: ChannelsCollateralPage,
                icon: ChannelsCollateralIcon,
                abilities: [ADMIN, GET_ORDERS_COLLATERAL],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'affidavit',
                path: '/channels/affidavit',
                title: i18n.t('layout_main_navigation_channels_affidavit') as string,
                component: ChannelsAffidavitPage,
                icon: ChannelsAffidavitIcon,
                abilities: [ADMIN, GET_DECLARATIONS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'funding',
                path: '/channels/funding',
                title: i18n.t('layout_main_navigation_funding') as string,
                component: ChannelsFundingPage, // TODO: Cambiar a su correcto componente
                abilities: [ADMIN, GET_ORDERS_FUND],
                icon: ChannelsFundingIcon,
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'commercial',
                title: i18n.t('layout_main_navigation_commercial') as string,
                icon: ChannelsComercialIcon,
                availability: 'logged_in' as AvailabityType,
                abilities: [ALL_USERS, GET_DOCUMENTS],
                externalUrl: VITE_CMS_URL,
            },
        ],
    },
    {
        name: 'configuration',
        title: i18n.t('layout_main_navigation_configuration'),
        availability: 'logged_in' as AvailabityType,
        icon: ConfigurationIcon,
        abilities: [ADMIN, GET_ALERTS, GET_LOCKED_FUNCTIONALITY],
        subRoutes: [
            {
                name: 'non_working_days',
                path: '/settings/non-working-days',
                title: i18n.t('layout_main_navigation_non_working_days') as string,
                component: SettingsNonWorkingPage,
                icon: SettingsNonWorkingDaysIcon,
                abilities: [ADMIN, GET_DATES],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'rules',
                path: '/settings/rules',
                title: i18n.t('layout_main_navigation_instrument_rule') as string,
                component: SettingsInstrumentRulesPage,
                icon: SettingsInstrumentRuleIcon,
                abilities: [ADMIN, GET_RULES],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'alerts',
                path: '/settings/alerts',
                title: i18n.t('layout_main_navigation_alerts') as string,
                component: SettingsAlertsPage,
                icon: AlertsIcon,
                abilities: [ADMIN, GET_ALERTS],
                availability: 'logged_in' as AvailabityType,
            },
            {
                name: 'blocks',
                path: '/settings/blocks',
                title: i18n.t('layout_main_navigation_blockages'),
                component: () => <SettingsBlocksPage />,
                icon: BlockageIcon,
                abilities: [ADMIN, GET_LOCKED_FUNCTIONALITY],
                availability: 'logged_in' as AvailabityType,
            },
        ],
    },
    {
        name: 'usersProfile',
        path: '/usersProfile',
        title: i18n.t('layout_main_navigation_users_profile') as string,
        component: ProfilePage,
        icon: userProfileIcon,
        abilities: [ALL_USERS],
        availability: 'logged_in' as AvailabityType,
    },
];

const extraSubRoutes = [
    {
        name: 'clients-detail',
        path: '/client/client-detail',
        component: () => <ClientDetail />,
        abilities: [ADMIN, GET_APP_USERS],
        availability: 'logged_in' as AvailabityType,
    },
    {
        name: 'prospects',
        path: '/prospect/prospect-detail',
        component: () => <ClientDetail isProspect />,
        abilities: [ADMIN, GET_ONBOARDING],
        availability: 'logged_in' as AvailabityType,
    },
    {
        name: 'import',
        path: '/clients/import',
        title: i18n.t('layout_main_navigation_clients_import') as string,
        component: ClientImportClientVBPage,
        icon: ClientsDepositsIcon,
        abilities: [ADMIN, GET_APP_USERS],
        availability: 'logged_in' as AvailabityType,
    },
];

export const privateSubRoutes = () => {
    return [...menuMainRoutes(), ...extraSubRoutes];
};

export const loggedOutSubroutes: Array<SubRouteInfo> = [
    {
        name: 'login',
        path: '/',
        icon: LogoutIcon,
        title: i18n.t('layout_main_navigation_login') as string,
        component: LoginPage,
        availability: 'logged_out',
    },
    {
        name: 'login',
        path: '/login',
        icon: LogoutIcon,
        title: i18n.t('layout_main_navigation_login') as string,
        component: LoginPage,
        availability: 'logged_out',
    },
    {
        name: 'forgot_password',
        path: '/forgot-password',
        icon: LogoutIcon,
        title: i18n.t('layout_main_navigation_forgot_password') as string,
        component: ForgotPasswordPage,
        availability: 'logged_out',
    },
    {
        name: 'email_verification',
        path: '/email-verification',
        icon: LogoutIcon,
        title: i18n.t('layout_main_navigation_email_verification') as string,
        component: EmailVerificationPage,
        availability: 'logged_out',
    },
];

export const publicSubRoutes: Array<SubRouteInfo> = [
    {
        name: 'errorPage',
        path: '*',
        title: 'Error',
        component: ErrorPage,
        availability: 'public',
        icon: LogoutIcon,
    },
    {
        name: 'noPermission',
        path: '/no-permissions',
        title: 'No Permission',
        component: NoPermissionsPage,
        availability: 'public',
        icon: LogoutIcon,
    },
];

export const routes: Array<RouteInfo> = [
    {
        name: 'main_layout',
        component: MainLayout,
        subRoutes: privateSubRoutes(),
        availability: 'logged_in' as AvailabityType,
        abilities: [ALL_USERS],
    },
    {
        name: 'logged_out_layout',
        component: HelmetLayout,
        subRoutes: loggedOutSubroutes,
        availability: 'logged_out',
    },
    {
        name: 'public_layout',
        component: HelmetLayout,
        subRoutes: publicSubRoutes,
        availability: 'public',
    },
];

export const logoutItemData = () =>
    ({
        name: 'logout',
        path: '/login',
        icon: LogoutIcon,
        title: i18n.t('layout_main_navigation_logout') as string,
        component: LoginPage,
        availability: 'logged_in' as AvailabityType,
    }) as SubRouteInfo;

export default routes;
