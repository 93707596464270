import { useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsExchangePage from './ChannelsExchangePage';
import { useTranslation } from 'react-i18next';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ExchangeDefinition } from 'types/channelsExchange/channelsExchange.api.types';
import { useLockOrUnlockExchange } from 'hooks/api/channelsExchange.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { convertCurrencyFormat, getColorByStatus, getFullName } from 'utils/helpers/commonHelper';
import { Chip, Typography } from '@mui/material';
import {
    ASSIGNED,
    INSTRUMENTS_BASE_URL,
    LOCK,
    PENDING,
    tChannelsCommon,
    tCommon,
    tDate,
    UNLOCK,
    USD,
} from 'constants/appConstants';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';
import { statusOptions } from 'utils/helpers/channelsCommonHelper';
import ChannelsExchangeDetail from './ChannelsExchangeDetail';
import ChannelsDDJJCellInfo from 'components/common/ChannelsDDJJCellInfo';
import { UPDATE_ORDERS_EXCHANGE } from 'constants/abilities.constants';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';

const ChannelsExchangePageContainer = (props: Props) => {
    const { t } = useTranslation('channelsExchange');
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { mutate } = useLockOrUnlockExchange(setSnackBarMessage);
    const { isHiddenByAbility } = useGetActionsReestrictions();
    const [refreshTable, setRefreshTable] = useState(false);

    const handleAcquireOrReleaseOrder = (infoToLockOrUnlock: { id: string; action: string }) =>
        mutate(infoToLockOrUnlock, {
            onSuccess: () => {
                setSnackBarMessage(t(`exchange_${infoToLockOrUnlock.action}_successfully`));
                setRefreshTable(prevValue => !prevValue);
            },
        });

    const columns: CommonGridColumnsProps<ExchangeDefinition>[] = [
        {
            id: 'customerCode',
            name: t('exchange_list_customer_code_column'),
            selector: row => row?.customerCode,
            exportOption: row => row?.customerCode,
            sortField: 'customerCode',
            sortable: true,
            center: true,
        },
        {
            id: 'user',
            name: t('exchange_list_customer_name_column'),
            selector: row => getFullName(row.user),
            exportOption: row => getFullName(row.user),
            sortField: 'user',
            sortable: false,
            center: true,
            wrap: true,
        },
        {
            id: 'buySell',
            name: t('exchange_list_operation_type_column'),
            exportOption: row => t(`orders_${row.buySell}`, tChannelsCommon) as string,
            cell: row => (
                <Chip
                    color="secondary"
                    variant="outlined"
                    label={t(`orders_${row.buySell}`, tChannelsCommon)}
                />
            ),
            sortField: 'buySell',
            sortable: true,
            center: true,
        },
        {
            id: 'instrument',
            name: t('exchange_list_instrument_column'),
            exportOption: row => row?.ticker?.ticker || '-',
            cell: row => (
                <Typography fontSize={14} fontWeight={700} textAlign="center">
                    {row?.ticker?.ticker || '-'}
                </Typography>
            ),
            sortField: 'instrument',
            sortable: false,
            center: true,
        },
        {
            id: 'amount',
            name: t('exchange_list_amount_column'),
            selector: row => convertCurrencyFormat(row.amount, USD),
            exportOption: row => convertCurrencyFormat(row.amount, USD),
            sortField: 'amount',
            sortable: true,
            center: true,
        },
        {
            id: 'ddjjRequired',
            name: t('exchange_list_ddjj_column'),
            exportOption: row => t(row.declaration?.filePath ? 'yes' : 'no') as string,
            cell: row => <ChannelsDDJJCellInfo {...row} />,
            sortField: 'ddjjRequired',
            sortable: false,
            center: true,
        },
        {
            id: 'status',
            name: t('exchange_list_status_column'),
            exportOption: row => t(row.status, tCommon) as string,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '100%', ...getColorByStatus(row.status) }}
                    label={t(row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
        {
            id: 'operator',
            name: t('exchange_list_operator_column'),
            selector: row => getFullName(row?.operator),
            exportOption: row => getFullName(row?.operator),
            sortField: 'operator',
            sortable: false,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            width: 'xl',
            customizedTitle: row => (
                <ChannelsModalHeader
                    modalName="exchange"
                    status={row.status}
                    operationType={`orders_${row.buySell}`}
                />
            ),
            component: (props: any) => (
                <ChannelsExchangeDetail
                    {...props}
                    handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
                />
            ),
        },
        {
            id: 'assing',
            icon: (props: any) => <CustomIconSelector type="assing" sx={props.sx} />,
            isHidden: (row: any) =>
                row?.status !== PENDING && !isHiddenByAbility(UPDATE_ORDERS_EXCHANGE),
            onIconClick: ({ id }: ExchangeDefinition) =>
                handleAcquireOrReleaseOrder({ id, action: LOCK }),
        },
        {
            id: 'unassing',
            icon: (props: any) => <CustomIconSelector type="unassing" sx={props.sx} />,
            isHidden: (row: any) =>
                row?.status !== ASSIGNED && !isHiddenByAbility(UPDATE_ORDERS_EXCHANGE),
            onIconClick: ({ id }: ExchangeDefinition) =>
                handleAcquireOrReleaseOrder({ id, action: UNLOCK }),
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'customerCode',
            label: t('customer_number', tChannelsCommon),
            type: 'text',
            placeholder: t('customer_number', tChannelsCommon) as string,
            query: 'customerCode',
            regex: /^\d{0,13}$/,
        },
        {
            id: 'instrument',
            label: t('instrument', tChannelsCommon),
            type: 'requestAutocomplete',
            placeholder: t('channels_affidavit_instruments_filter_placeholder') as string,
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/bo/`,
                param: 'ticker',
                optionLabel: option => option.name,
                keyTofilter: 'tickers[0].ticker',
            },
        },
        {
            id: 'status',
            label: t('status', tChannelsCommon),
            type: 'select',
            placeholder: '',
            query: 'status',
            options: statusOptions,
        },
        {
            id: 'dateFrom',
            label: t('from', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        actionColumns,
        headerFilters,
        refreshTable,
        SnackBar,
    };

    return <ChannelsExchangePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsExchangePageContainer.propTypes = propTypes;

export default ChannelsExchangePageContainer;
