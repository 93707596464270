import { Box, Chip, Tooltip, Typography } from '@mui/material';
import CustomIconSelector from 'components/common/CustomIconSelector/CustomIconSelector';
import {
    INSTRUMENTS_BASE_URL,
    PENDING,
    PENDING_ORDER,
    tChannelsCommon,
    tCommon,
    tDate,
} from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsAffidavitPage from './ChannelsAffidavitPage';
import ChannelsAffidavitDetail from './ChannelsAffidavitDetail';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';
import { getFullName, getColorByStatus } from 'utils/helpers/commonHelper';
import ChannelsAffidavitCounters from './ChannelsAffidavitCounters';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { statusOptions } from 'utils/helpers/channelsCommonHelper';
import { useResendEmail } from 'hooks/api/channelsAffidavit.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';

const ChannelsAffidavitPageContainer = (props: Props) => {
    const { t } = useTranslation('channelsAffidavit');
    const { resendEmail, SnackBar } = useResendEmail();
    const { setSnackBarMessage } = useSnackBar();

    const handleResendEmail = (row: ChannnelsAffidavit) => {
        if (row.declaration?.id !== undefined) {
            resendEmail(row.declaration.id);
        } else {
            setSnackBarMessage(t('resend_email_error'), 'error');
        }
    };

    const columns: CommonGridColumnsProps<ChannnelsAffidavit>[] = [
        {
            id: 'date',
            name: t('channels_affidavit_list_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            exportOption: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
            wrap: true,
        },
        {
            id: 'channel',
            name: t('channels_affidavit_list_channel_column'),
            selector: row => getFullName(row.channel),
            exportOption: row => getFullName(row.channel),
            sortField: 'channel',
            sortable: false,
            wrap: true,
            center: true,
        },
        {
            id: 'customer_code',
            name: t('channels_affidavit_list_customer_code_column'),
            exportOption: row =>
                `${row.customerCode}  ${row.user ? `-${getFullName(row.user)} ` : ''} `,
            sortField: 'customerCode',
            sortable: true,
            center: true,
            cell: ({ user, customerCode }) => (
                <Tooltip title={getFullName(user)} placement="right">
                    <Box sx={{ width: 'fit-content' }}>{customerCode}</Box>
                </Tooltip>
            ),
        },

        {
            id: 'instrument',
            name: t('channels_affidavit_list_instrument_column'),
            exportOption: row => row?.ticker?.ticker || '-',
            cell: row => (
                <Typography fontSize={14} fontWeight={700} textAlign="center">
                    {row?.ticker?.ticker || '-'}
                </Typography>
            ),
            sortField: 'instrument',
            sortable: false,
            center: true,
        },
        {
            id: 'currency',
            name: t('channels_affidavit_list_currency_column'),
            selector: row => row.ticker.currency,
            exportOption: row => row.ticker.currency,
            sortField: 'currency',
            sortable: false,
            center: true,
        },
        {
            id: 'status-order',
            name: t('channels_affidavit_list_status_order_column'),
            exportOption: row => t(row.status, tCommon) as string,
            cell: row => (
                <Chip
                    sx={{
                        m: 0.625,
                        fontSize: '0.8rem',
                        minWidth: '100%',
                        ...getColorByStatus(row.status as string),
                    }}
                    label={t(row.status === PENDING ? PENDING_ORDER : row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: false,
            center: true,
        },
        {
            id: 'status',
            name: t('channels_affidavit_list_status_ddjj_column'),
            exportOption: row => t(row.declaration?.status as string, tCommon) as string,
            cell: row => (
                <Chip
                    sx={{
                        m: 0.625,
                        fontSize: '0.8rem',
                        minWidth: '100%',
                        ...getColorByStatus(row.declaration?.status as string),
                    }}
                    label={t(row.declaration?.status as string, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: false,
            center: true,
        },
        {
            id: 'buySell',
            name: t('channels_affidavit_list_operation_type_column'),
            exportOption: row => t(`orders_${row.buySell}`, tChannelsCommon) as string,
            sortField: 'buySell',
            sortable: true,
            center: true,
            cell: row => (
                <Chip
                    variant="outlined"
                    color="secondary"
                    sx={{ m: 0.625, minWidth: '100%' }}
                    label={t(`orders_${row.buySell}`, tChannelsCommon)}
                />
            ),
        },
        {
            id: 'adjunted',
            name: t('channels_affidavit_list_operation_adjunted_column'),
            selector: row =>
                (row.declaration?.filePath ? t('yes', tCommon) : t('no', tCommon)) as string,
            exportOption: row =>
                (row.declaration?.filePath ? t('yes', tCommon) : t('no', tCommon)) as string,
            sortField: 'buySell',
            sortable: false,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <ChannelsAffidavitDetail {...props} />,
            width: '60%',
            customizedTitle: ({ declaration, buySell }: ChannnelsAffidavit) => (
                <ChannelsModalHeader
                    modalName="affidavit"
                    status={declaration?.status as string}
                    operationType={`orders_${buySell}`}
                />
            ),
        },
        {
            id: 'email',
            icon: (props: any) => <CustomIconSelector type="email" sx={props.sx} />,
            onIconClick: (row: any) => handleResendEmail(row),
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'counters',
            component: () => <ChannelsAffidavitCounters />,
        },
    ];

    const operationsTypeOptions = [
        { id: 'BUY', name: 'Compra' },
        { id: 'SELL', name: 'Venta' },
    ];
    const ddjjStatusFilters = [
        { id: 'REJECTED', name: t('REJECTED', tCommon) },
        { id: 'PENDING', name: t('PENDING', tCommon) },
        { id: 'ACCEPTED', name: t('ACCEPTED', tCommon) },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'customer-code',
            label: t('channels_affidavit_customer_code_filter_label'),
            type: 'text',
            placeholder: t('channels_affidavit_customer_code_filter_placeholder') as string,
            query: 'customerCode',
            regex: /^\d{0,13}$/,
        },
        {
            id: 'channel',
            label: t('channels_affidavit_channel_filter_label'),
            type: 'text',
            placeholder: t('channels_affidavit_channel_filter_placeholder') as string,
            query: 'channel',
        },
        {
            id: 'instruments',
            label: t('channels_affidavit_instruments_filter_label'),
            type: 'requestAutocomplete',
            placeholder: t('channels_affidavit_instruments_filter_placeholder') as string,
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/bo/`,
                param: 'ticker',
                optionLabel: option => option.customerCode,
                keyTofilter: 'tickers[0].ticker',
            },
        },
        {
            id: 'operationsType',
            label: t('channels_affidavit_operations_type_filter_label'),
            type: 'select',
            placeholder: t('channels_affidavit_operations_type_filter_placeholder') as string,
            query: 'buySell',
            options: operationsTypeOptions,
        },
        {
            id: 'dateFrom',
            label: t('from', tDate),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tDate),
            type: 'date',
            query: 'dateTo',
        },
        {
            id: 'status',
            label: t('status', tChannelsCommon),
            type: 'select',
            placeholder: '',
            query: 'status',
            options: statusOptions,
        },
        {
            id: 'ddjjStatus',
            label: t('channels_affidavit_ddjj_status_filter_label'),
            type: 'select',
            placeholder: t('channels_affidavit_ddjj_status_filter_placeholder') as string,
            query: 'declarationStatus',
            options: ddjjStatusFilters,
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        t,
        headerFilters,
        headerActions,
        SnackBar,
    };

    return <ChannelsAffidavitPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitPageContainer.propTypes = propTypes;

export default ChannelsAffidavitPageContainer;
