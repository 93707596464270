import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconSelector from './CustomIconSelector';

const CustomIconSelectorContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <CustomIconSelector {...childProps} />;
};

const propTypes = {};

interface extraProps {
    type:
        | 'enable'
        | 'disable'
        | 'detail'
        | 'edit'
        | 'delete'
        | 'approve'
        | 'reject'
        | 'assing'
        | 'unassing'
        | 'check'
        | 'close'
        | 'back'
        | 'download'
        | 'add'
        | 'filterOn'
        | 'filterOff'
        | 'visibilityOff'
        | 'refresh'
        | 'menu'
        | 'dropUp'
        | 'dropDown'
        | 'search'
        | 'fileUpload'
        | 'copy'
        | 'generate'
        | 'person'
        | 'arrowL'
        | 'arrowR'
        | 'alert';
    sx?: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomIconSelectorContainer.propTypes = propTypes;

export default CustomIconSelectorContainer;
