import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionCustomerCodes from './ClientSectionCustomerCodes';
import { useTranslation } from 'react-i18next';
import { UserAccount } from 'types/users/users.api.types';

const ClientSectionCustomerCodesContainer = (props: Props) => {
    const { t } = useTranslation('clients');

    const childProps = {
        ...props,
        t,
    };

    return <ClientSectionCustomerCodes {...childProps} />;
};

const propTypes = {};

interface extraProps {
    marketAccounts: UserAccount[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionCustomerCodesContainer.propTypes = propTypes;

export default ClientSectionCustomerCodesContainer;
