import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { ItemGridProps } from 'types/common/channels.types';
import { Grid, Typography } from '@mui/material';

const ChannelsModalItemGrid = ({ label, text, column }: Props) => (
    <Grid item xs={column || 12} display="flex">
        <Typography fontSize={14} fontWeight={600} ml={1.25}>
            {label || '-'}:
        </Typography>
        <Typography fontSize={14} ml={0.625}>
            {text || '-'}
        </Typography>
    </Grid>
);
const propTypes = {};

interface extraProps extends ItemGridProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalItemGrid.propTypes = propTypes;

export default ChannelsModalItemGrid;
