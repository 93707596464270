import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './ClientProspectRejectModalForm.styled';
import { Form, FormikProvider } from 'formik';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import InfoLabel from 'components/common/InfoLabel';
import CustomInput from 'components/common/CustomInput';
import { ProspectStateInfo } from 'types/clients/clients.types';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

const ClientProspectRejectModalForm = (props: Props) => {
    const { formik, t, close, docNumber, fullName, id, isLoading, open, steps } = props;
    const { values, errors, setFieldValue } = formik;

    return (
        <Dialog open={open} onClose={close}>
            <FormikProvider value={formik} data-testid="ClientProspectRejectModalForm-form">
                <Form>
                    <DialogTitle>{t('prospect_reject_modal_title')}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InfoLabel label={t('prospect_reject_modal_ref_field')} text={id} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InfoLabel
                                    label={t('prospect_reject_modal_doc_number_field')}
                                    text={docNumber}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InfoLabel
                                    label={t('prospect_reject_modal_full_name_field')}
                                    text={fullName}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} marginTop={0}>
                            <Grid item xs={12} md={6}>
                                <Typography mb="15px">
                                    {t('prospect_reject_modal_reject_reason_label')}
                                </Typography>
                                <CustomInput
                                    multiline
                                    rows={5}
                                    values={values}
                                    field="reason"
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    maxLength={250}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography mb="15px">
                                    {t('prospect_reject_modal_steps_label')}
                                </Typography>
                                <CustomAutoComplete
                                    options={steps ?? []}
                                    id="steps_autocomplete"
                                    onChange={(e, value) => {
                                        setFieldValue('steps', value);
                                    }}
                                    filterSelectedOptions
                                    value={values.steps ?? []}
                                    error={errors.steps}
                                    multiple
                                    renderOption={(props, option) => (
                                        <Box {...props}>{option?.name}</Box>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <ActionButton variant="outlined" disabled={isLoading} onClick={close}>
                                {t('cancel', tCommon)}
                            </ActionButton>
                            <ActionButton
                                loading={isLoading}
                                disabled={!Object.values(values).length || isLoading}
                                type="submit">
                                {t('reject', tCommon)}
                            </ActionButton>
                        </DialogActions>
                    </DialogContent>
                </Form>
            </FormikProvider>
        </Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    docNumber: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
};

interface extraProps {
    formik: any;
    steps?: ProspectStateInfo[];
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectRejectModalForm.propTypes = propTypes;

export default ClientProspectRejectModalForm;
