import { FormControlLabel, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Tprop } from '../../../../../types/common/general.types';
import { tCommonGrid } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';

const DisableFilter = (props: Props) => {
    const { isLoading, disabled, resource, setShowDisabled, t } = props;

    return (
        <FormControlLabel
            disabled={isLoading}
            control={
                <IconButton
                    disabled={isLoading}
                    onClick={() => setShowDisabled((prevState: boolean) => !prevState)}>
                    <CustomIconSelector type={disabled ? 'visibilityOff' : 'detail'} />
                </IconButton>
            }
            label={
                disabled
                    ? t(`hide_${resource.replaceAll('-', '_')}`, tCommonGrid)
                    : t(`show_${resource.replaceAll('-', '_')}`, tCommonGrid)
            }
        />
    );
};

const propTypes = {
    disabled: PropTypes.bool.isRequired,
    matchesSm: PropTypes.bool.isRequired,
    resource: PropTypes.string.isRequired,
};

type extraProps = InferPropsExtended<
    {
        isLoading?: boolean;
        setShowDisabled: (prevState: boolean) => boolean;
    },
    Tprop
>;

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DisableFilter.propTypes = propTypes;

export default DisableFilter;
