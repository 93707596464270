import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeaderFilters from './HeaderFilters';
import { CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useTranslation } from 'react-i18next';

const HeaderFiltersContainer = (props: Props) => {
    const { filterHeaderString, setFilterHeaderString } = props;
    const { t } = useTranslation('commonGrid');
    const [filterString, setFilterString] = React.useState<string>(filterHeaderString ?? '');

    const onSubmit = () => {
        setFilterHeaderString(filterString);
    };
    const onClear = () => {
        setFilterHeaderString('');
        setFilterString('');
    };

    const childProps = {
        ...props,
        t,
        filterString,
        onSubmit,
        onClear,
        setFilterString,
    };

    return <HeaderFilters {...childProps} />;
};

const propTypes = {
    filterHeaderString: PropTypes.string.isRequired,
    setFilterHeaderString: PropTypes.func.isRequired,
};

interface extraProps {
    filters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HeaderFiltersContainer.propTypes = propTypes;

export default HeaderFiltersContainer;
