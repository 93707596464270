import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CircularSpinner.styled';
import { CircularProgress } from '@mui/material';

const CircularSpinner = (props: Props) => {
    const { size, color } = props;

    return (
        <St.Box data-testid="CircularSpinner-default">
            <CircularProgress size={size as number} color={color || 'primary'} />
        </St.Box>
    );
};

const propTypes = {};

interface extraProps {
    size?: number;
    color?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CircularSpinner.propTypes = propTypes;

export default CircularSpinner;
