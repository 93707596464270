import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { InstrumentObj } from 'types/common/instrument.types';
import { Box } from '@mui/material';
import CustomAutoComplete from '../CustomAutoComplete';

const InstrumentsAutocomplete = (props: Props) => {
    const { t, value, errors, instrumentsOptions, onChange, isLoading, setFilterQueryString } =
        props;
    return (
        <CustomAutoComplete
            id="instrument"
            value={value || ''}
            onChange={(e, value) => onChange(value)}
            options={instrumentsOptions ?? []}
            label={t('instrument_autocomplete_label') as string}
            placeholder={t('instrument_autocomplete_label') as string}
            filterSelectedOptions
            isLoading={isLoading}
            onInputChange={(_, value) => setFilterQueryString(value)}
            renderOption={(props, option) => (
                <Box {...props} key={option.id}>
                    {option.name}
                </Box>
            )}
            error={errors?.instrument ? errors?.instrument : ''}
            helperText={t('instrument_autocomplete_helper_text')}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    filterQueryString: PropTypes.string.isRequired,
    setFilterQueryString: PropTypes.func.isRequired,
};

interface extraProps {
    instrumentsOptions: InstrumentObj[];
    value?: number;
    errors?: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentsAutocomplete.propTypes = propTypes;

export default InstrumentsAutocomplete;
