import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsAlertsPage from './SettingsAlertsPage';
import { useTranslation } from 'react-i18next';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { Alerts } from 'types/alerts/alerts.types';
import SettingsAlertsFormContainer from './SettingsAlertsForm/SettingsAlertsForm.container';
import { ICON_CELL_SIZE, tCommon, tDate } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_ALERTS } from 'constants/abilities.constants';

const getIconCell = (value: boolean) =>
    value ? (
        <CustomIconSelector
            type="check"
            sx={{ color: defaultTheme.palette.success.main, ...ICON_CELL_SIZE }}
        />
    ) : (
        <CustomIconSelector
            type="close"
            sx={{ color: defaultTheme.palette.secondary.main, ...ICON_CELL_SIZE }}
        />
    );

const SettingsAlertsPageContainer = (props: Props) => {
    const { t } = useTranslation('alerts');
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const columns: CommonGridColumnsProps<Alerts>[] = [
        {
            id: 'createdAt',
            name: t('alerts_list_creation_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            exportOption: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'updatedAt',
            name: t('alerts_list_modified_date_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            exportOption: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'name',
            name: t('alerts_list_name_column'),
            selector: row => row.name,
            exportOption: row => row.name,
            sortField: 'name',
            sortable: true,
            wrap: true,
        },
        {
            id: 'active',
            name: t('alerts_list_enabled_column'),
            selector: row => t(row.active ? 'yes' : 'no', tCommon) as string,
            exportOption: row => t(row.active ? 'yes' : 'no', tCommon) as string,
            cell: row => getIconCell(row.active),
            sortField: 'active',
            sortable: true,
            center: true,
        },
        {
            id: 'blocking',
            name: t('alerts_list_blocked_column'),
            selector: row => t(row.blocking ? 'yes' : 'no', tCommon) as string,
            exportOption: row => t(row.blocking ? 'yes' : 'no', tCommon) as string,
            cell: row => getIconCell(row.blocking),
            sortField: 'blocking',
            sortable: true,
            center: true,
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <></>}
                    modalTitle="alerts"
                    width="xl"
                    component={(props: any) => <SettingsAlertsFormContainer {...props} />}
                />
            ),
            isHidden: isHiddenByAbility(UPDATE_ALERTS),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            width: 'xl',
            component: (props: any) => <SettingsAlertsFormContainer isEdit {...props} />,
            isHidden: () => isHiddenByAbility(UPDATE_ALERTS),
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'active',
            label: t('alerts_active'),
            type: 'select',
            query: 'active',
            options: [
                { id: 'true', name: t('alerts_active') },
                { id: 'false', name: t('alerts_inactive') },
            ],
        },
        {
            id: 'blocking',
            label: t('alerts_blocker'),
            type: 'select',
            query: 'blocking',
            options: [
                { id: 'true', name: t('alerts_blocker') },
                { id: 'false', name: t('alerts_non_blocking') },
            ],
        },
        {
            id: 'dateFrom',
            label: t('from', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'dateTo',
        },
        {
            id: 'name',
            label: t('alerts_name'),
            type: 'text',
            placeholder: t('alerts_name_placeholder') as string,
            query: 'name',
        },
    ];
    const childProps = {
        ...props,
        t,
        headerActions,
        headerFilters,
        actionColumns,
        columns,
    };

    return <SettingsAlertsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsAlertsPageContainer.propTypes = propTypes;

export default SettingsAlertsPageContainer;
