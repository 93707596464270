import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UserRolesForm.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import Stack from '@mui/material/Stack';
import { tCommon } from 'constants/appConstants';
import ActionButton from 'components/common/ActionButton';
import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import AbilitiesComponent from './AbilitiesComponent';
import { AbilitiesListDefinition, RolesDefinition } from 'types/usersRoles/usersRoles.types';

const UserRolesForm = (props: Props) => {
    const {
        t,
        formik,
        isEdit = false,
        disabledForm,
        close,
        isAbilitiesLoading,
        abilitiesList,
        row,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} data-testid="UserRolesForm-form">
            <Form>
                <St.UsersRoleForm>
                    <Stack spacing={4}>
                        <Grid item xs={12} md={10}>
                            <CustomInput
                                required
                                field="name"
                                label={t('users_roles_form_name')}
                                placeholder={t('users_roles_form_name_placeholder') as string}
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <CustomInput
                                required
                                field="description"
                                label={t('users_roles_form_description')}
                                placeholder={
                                    t('users_roles_form_description_placeholder') as string
                                }
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                        <Typography mb={0.625}>{t('users_roles_form_abilities')}</Typography>
                        <AbilitiesComponent
                            isAbilitiesLoading={isAbilitiesLoading}
                            abilitiesList={abilitiesList}
                            setFieldValue={setFieldValue}
                            values={values}
                            row={row}
                        />
                    </Stack>
                    <St.ActionSectionForm direction="row" spacing={1}>
                        <ActionButton onClick={close} variant="outlined">
                            {t('cancel', tCommon)}
                        </ActionButton>
                        <ActionButton
                            size="medium"
                            type="submit"
                            disabled={isEdit ? disabledForm : false}
                            color="primary"
                            variant="contained">
                            {isEdit ? t('edit', tCommon) : t('add', tCommon)}
                        </ActionButton>
                    </St.ActionSectionForm>
                </St.UsersRoleForm>
            </Form>
        </FormikProvider>
    );
};

const propTypes = {
    isEdit: PropTypes.bool,
    t: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    close: () => void;
    isAbilitiesLoading: boolean;
    abilitiesList: AbilitiesListDefinition[] | undefined;
    row: RolesDefinition | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserRolesForm.propTypes = propTypes;

export default UserRolesForm;
