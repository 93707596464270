import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientExtractionsPage from './ClientExtractionsPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import ClientExtractionsDetail from './ClientExtractionsDetail';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { convertCurrencyFormat, getColorByStatus, getFullName } from 'utils/helpers/commonHelper';
import { ExtractionsApi } from 'types/clientExtractions/clientExtractions.api.types';
import { Chip } from '@mui/material';
import {
    APPROVED_API,
    APPROVED_MANUALLY,
    PENDING,
    REJECTED,
    tChannelsCommon,
} from 'constants/appConstants';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';

const ClientExtractionsPageContainer = (props: Props) => {
    const { t } = useTranslation('clientExtractions');

    const columns: CommonGridColumnsProps<ExtractionsApi>[] = [
        {
            id: 'entryDate',
            name: t('extractions_list_entry_date_column'),
            selector: row => formatDateWithHour(row.entryDate),
            exportOption: row => formatDateWithHour(row.entryDate),
            sortField: 'entryDate',
            sortable: true,
            center: true,
        },
        {
            id: 'customerName',
            name: t('extractions_list_customer_name_column'),
            selector: row => getFullName(row.user),
            exportOption: row => getFullName(row.user),
            sortField: 'name',
            sortable: false,
            center: true,
        },
        {
            id: 'customerNumber',
            name: t('extractions_list_customer_number_column'),
            selector: row => row.customerCode,
            exportOption: row => row.customerCode,
            sortField: 'customerCode',
            sortable: true,
            center: true,
        },
        {
            id: 'currency',
            name: t('extractions_list_currency_column'),
            selector: row => row.currency,
            exportOption: row => row.currency,
            sortField: 'currency',
            sortable: false,
            center: true,
        },
        {
            id: 'amount',
            name: t('extractions_list_amount_column'),
            selector: row => convertCurrencyFormat(row.amount, row.currency),
            exportOption: row => convertCurrencyFormat(row.amount, row.currency),
            sortField: 'amount',
            sortable: true,
            center: true,
        },
        {
            id: 'status',
            name: t('extractions_list_status_column'),
            exportOption: row => t(row.status, { ns: 'clientExtractions' }) as string,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '90%', ...getColorByStatus(row.status) }}
                    label={t(row.status, { ns: 'clientExtractions' })}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
        {
            id: 'type',
            name: t('extractions_list_type_column'),
            exportOption: row => t(row.bankAccount.type, tChannelsCommon) as string,
            cell: row => (
                <Chip
                    color="secondary"
                    variant="outlined"
                    sx={{ m: 0.625, minWidth: '100%' }}
                    label={t(row.bankAccount.type, tChannelsCommon)}
                />
            ),
            sortField: 'type',
            sortable: false,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            customizedTitle: ({ status, bankAccount }: any) => (
                <ChannelsModalHeader
                    modalName="extractions"
                    status={status}
                    operationType={bankAccount.type}
                />
            ),
            component: (props: any) => <ClientExtractionsDetail {...props} />,
            width: '70%',
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'client',
            label: t('extractions_filter_client'),
            type: 'text',
            placeholder: t('extraction_filter_client_placeholder') as string,
            query: 'client',
        },
        {
            id: 'dateFrom',
            label: t('extractions_filter_dateFrom'),
            type: 'date',
            placeholder: t('extractions_filter_dateFrom') as string,
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('extractions_filter_dateTo'),
            type: 'date',
            placeholder: t('extractions_filter_dateTo') as string,
            query: 'dateTo',
        },
        {
            id: 'customerCode',
            label: t('extractions_filter_customer_number'),
            type: 'number',
            placeholder: t('extractions_filter_customer_number_placeholder') as string,
            query: 'customerCode',
        },
        {
            id: 'accountNumber',
            label: t('extractions_filter_cbu'),
            type: 'number',
            placeholder: t('extractions_filter_cbu_placeholder') as string,
            query: 'accountNumber',
        },
        {
            id: 'currency',
            label: t('extractions_filter_currency'),
            type: 'select',
            placeholder: t('extractions_filter_currency') as string,
            query: 'currency',
            options: [
                { id: 'ARS', name: 'ARS' },
                { id: 'USD', name: 'USD' },
                { id: 'USD-C', name: 'USD-C' },
                { id: 'USD-MT', name: 'USD-MT' },
                { id: 'USDC-T', name: 'USDC-T' },
                { id: 'USDC-EXT', name: 'USDC-EXT' },
            ],
        },
        {
            id: 'status',
            label: t('extractions_filter_status'),
            type: 'multiple',
            placeholder: t('extractions_filter_status') as string,
            query: 'status',
            options: [
                { id: PENDING, name: t(PENDING) },
                { id: REJECTED, name: t(REJECTED) },
                { id: APPROVED_MANUALLY, name: t(APPROVED_MANUALLY) },
                { id: APPROVED_API, name: t(APPROVED_API) },
            ],
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        actionColumns,
        headerFilters,
    };

    return <ClientExtractionsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientExtractionsPageContainer.propTypes = propTypes;

export default ClientExtractionsPageContainer;
