import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { ActionHeader, GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DisableFilter from '../DisableFilter';
import Search from '../Search';
import St from './TableHeaderActions.styled';
import ExportButton from 'components/common/ExportButton';

const TableHeaderActions = (props: Props) => {
    const {
        resource,
        canDisable,
        canExport,
        canSearch,
        loading,
        extraActionsInHeader,
        handleSearchChange,
        setShowDisabled,
        showDisabled,
        filterHeaderString,
        columns,
        exportTitle,
        total,
        fetchData,
        exportDateRangeEnabled,
    } = props;

    return (
        <St.Container direction={{ md: 'row', sm: 'column' }} spacing={1}>
            {canSearch && <Search handleSearchChange={handleSearchChange} disabled={loading} />}
            {canDisable && (
                <DisableFilter
                    resource={resource}
                    setShowDisabled={setShowDisabled}
                    isLoading={loading}
                    disabled={showDisabled}
                />
            )}
            {loading && extraActionsInHeader ? (
                <St.LoadingContainer>
                    <CircularProgress color="primary" size="1rem" />
                </St.LoadingContainer>
            ) : (
                extraActionsInHeader
                    ?.filter((item: ActionHeader) => !item.isHidden)
                    .map((item: ActionHeader) => <item.component key={item.id} />)
            )}
            {canExport && fetchData && (
                <ExportButton
                    columns={columns}
                    filterHeaderString={filterHeaderString}
                    total={total}
                    exportTitle={exportTitle}
                    fetchData={fetchData}
                    showDateRange={exportDateRangeEnabled}
                />
            )}
        </St.Container>
    );
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    canDisable: PropTypes.bool,
    canExport: PropTypes.bool,
    canSearch: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    showDisabled: PropTypes.bool.isRequired,
    setShowDisabled: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    total: PropTypes.number.isRequired,
};

interface extraProps {
    extraActionsInHeader?: ActionHeader[];
    filterHeaderString: string;
    exportTitle: string;
    fetchData?: (params: GridHookParams) => any;
    exportDateRangeEnabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderActions.propTypes = propTypes;

export default TableHeaderActions;
