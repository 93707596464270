import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './UsersRolesDeleteModal.styled';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const UsersRolesDeleteModal = (props: Props) => {
    const { t, isLoading, close, handleDelete } = props;

    return (
        <>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('deleting_roles_message')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton variant="outlined" color="secondary" size="small" onClick={close}>
                    {t('delete_dialog_cancel_button_text', tCommon)}
                </ActionButton>
                <ActionButton
                    size="small"
                    variant="contained"
                    loading={isLoading}
                    onClick={handleDelete}>
                    {t('delete_dialog_submit_button_text', tCommon)}
                </ActionButton>
            </DialogActions>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersRolesDeleteModal.propTypes = propTypes;

export default UsersRolesDeleteModal;
