import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientsPage from './ClientsPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ClientOrProspectItem } from 'types/clients/clients.types';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { COMPLETED, PENDING, REJECTED, tCommon, tDate } from 'constants/appConstants';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import ClientConfirmationAction from './ClientConfirmationAction';
import { useGetClientPDF } from 'hooks/api/clients.hooks';
import { useEffect } from 'react';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionButton from 'components/common/ActionButton';
import { downloadFromURL, getColorByStatus } from 'utils/helpers/commonHelper';
import { Chip } from '@mui/material';

const ClientsPageContainer = (props: Props) => {
    const { isProspect } = props;
    const { t } = useTranslation('clients');
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { mutate, isLoading } = useGetClientPDF(setSnackBarMessage);
    const navigate = useNavigate();
    const location = useLocation();

    const generateClientPDF = (id: string) =>
        mutate(id, {
            onSuccess: (res: any) => downloadFromURL(res.data.downloadPresignedUrl),
        });

    const columns: CommonGridColumnsProps<ClientOrProspectItem>[] = [
        {
            id: 'createdAt',
            name: t('clients_list_createdAt_column'),
            selector: row => formatDateWithHour(row.createdAt),
            exportOption: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'updatedAt',
            name: t('clients_list_updatedAt_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            exportOption: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'fullName',
            name: t('clients_list_full_name_column'),
            selector: row => row.fullName,
            exportOption: row => row.fullName,
            sortField: isProspect ? 'fullName' : 'name',
            sortable: true,
            center: true,
        },
        {
            id: 'docNumber',
            name: t('clients_general_id'),
            selector: row => row?.docNumber || '-',
            exportOption: row => row?.docNumber || '-',
            sortField: 'document',
            sortable: isProspect,
            center: true,
        },
    ];

    const prospectColumns: CommonGridColumnsProps<ClientOrProspectItem>[] = [
        ...columns,
        {
            id: 'phone_number',
            name: t('clients_general_phoneNumber'),
            selector: row => row?.phone || '-',
            exportOption: row => row?.phone || '-',
            sortField: 'phone_number',
            sortable: true,
            center: true,
        },
        {
            id: 'email',
            name: t('clients_general_email'),
            selector: row => row?.email || '-',
            exportOption: row => row?.email || '-',
            sortField: 'email',
            sortable: true,
            center: true,
        },
        {
            id: 'status',
            name: t('clients_general_status'),
            exportOption: row => t(`${row.status}_PROSPECT`, tCommon) as string,
            cell: row => (
                <Chip
                    sx={{
                        m: 0.625,
                        minWidth: '100%',
                        ...getColorByStatus(row.status),
                    }}
                    label={t(`${row.status}_PROSPECT`, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            onIconClick: ({ id }: any) => {
                localStorage.setItem('clientId', id);
                navigate(isProspect ? '/prospect/prospect-detail' : '/client/client-detail', {
                    state: { id },
                });
            },
            width: '100%',
        },
        {
            id: 'generate_pdf',
            icon: (props: any) => <CustomIconSelector type="generate" sx={props.sx} />,
            isHidden: () => isProspect as boolean,
            onIconClick: (row: any) => generateClientPDF(row.id),
        },
        {
            id: 'disable_client',
            icon: (props: any) => <CustomIconSelector type="disable" sx={props.sx} />,
            isHidden: (row: any) => isProspect || !row?.enabled,
            component: (props: any) => <ClientConfirmationAction {...props} action="disable" />,
        },
        {
            id: 'enable_client',
            icon: (props: any) => <CustomIconSelector type="enable" sx={props.sx} />,
            isHidden: (row: any) => isProspect || row?.enabled,
            component: (props: any) => <ClientConfirmationAction {...props} action="enable" />,
        },
    ];

    const clientFilters: CommonFilter[] = [
        {
            id: 'fullName',
            label: t('name', tCommon),
            type: 'text',
            placeholder: t('name', tCommon) as string,
            query: 'fullName',
        },
        {
            id: 'uuids',
            label: t('clients_list_ref_column'),
            type: 'text',
            placeholder: t('clients_list_ref_column') as string,
            query: 'uuids',
        },

        {
            id: 'fromCreatedAt',
            label: t('from', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'fromCreatedAt',
        },
        {
            id: 'toCreatedAt',
            label: t('to', tDate),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'toCreatedAt',
        },
    ];

    const prospectFilters: CommonFilter[] = [
        ...clientFilters,
        {
            id: 'document',
            label: t('document', tCommon),
            type: 'text',
            placeholder: t('document', tCommon) as string,
            query: 'document',
        },
        {
            id: 'status',
            label: t('clients_general_status'),
            type: 'select',
            query: 'status',
            options: [
                { id: PENDING, name: t(`${PENDING}_PROSPECT`, tCommon) },
                { id: COMPLETED, name: t(`${COMPLETED}_PROSPECT`, tCommon) },
                { id: REJECTED, name: t(`${REJECTED}_PROSPECT`, tCommon) },
            ],
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'import_vb_client',
            component: () => (
                <ActionButton
                    variant="contained"
                    aria-label="import"
                    onClick={() => navigate('/clients/import')}
                    sx={{ width: '140px' }}>
                    {t('btn_redirect_import_vb')}
                </ActionButton>
            ),
            isHidden: isProspect,
        },
    ];

    useEffect(() => {
        if (location?.state?.showProspectApprovedMessage) {
            setSnackBarMessage(t('prospect_approved_message_text', 'warning'));
        }
    }, [location.state]);

    useEffect(() => {
        if (isLoading) setSnackBarMessage(t('client_generating_pdf'));
    }, [isLoading]);

    const childProps = {
        ...props,
        columns: isProspect ? prospectColumns : columns,
        actionColumns,
        headerFilters: isProspect ? prospectFilters : clientFilters,
        headerActions,
        SnackBar,
        t,
    };

    return <ClientsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {
    isProspect?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsPageContainer.propTypes = propTypes;

export default ClientsPageContainer;
