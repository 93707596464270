import { useEffect, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsExchangeDetailActions from './ChannelsExchangeDetailActions';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import {
    useApproveExchange,
    useEditExchange,
    useRejectExchange,
} from 'hooks/api/channelsExchange.hooks';
import { tRequiredFieldError } from 'constants/appConstants';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';

const ChannelsExchangeDetailActionsContainer = (props: Props) => {
    const { buyPrice, sellPrice, id, ticker, operatedAmount, setSnackBarMessage, close } = props;
    const { t } = useTranslation('channelsOrders');
    const [action, setAction] = useState('');
    const [price, setPrice] = useState(
        {} as { buyPrice: number; sellPrice: number; operatedAmount: number },
    );
    const [rejectReason, setRejectReason] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { isLoading: isLoadingApprove, approveExchange } = useApproveExchange(
        setSnackBarMessage,
        close,
    );
    const { isLoading: isLoadingReject, rejectExchange } = useRejectExchange(
        setSnackBarMessage,
        close,
    );
    const { EditExchange, isLoading: isLoadingEdit } = useEditExchange(setSnackBarMessage, close);

    const handleAction = (type: string) => {
        setAction(type);
        setErrorMessage(null);
    };

    const handleSubmit = () => {
        if (action === 'reject' && !rejectReason) return setErrorMessage(tRequiredFieldError);

        setErrorMessage(null);
        switch (action) {
            case 'approve':
                return approveExchange({ id, body: { ...price, ticker } });
            case 'edit':
                return EditExchange({ id, body: { ...price, ticker } });
            case 'reject':
                return rejectExchange({ id, body: { reason: rejectReason } });

            default:
                return close();
        }
    };

    const childProps = {
        ...props,
        action,
        rejectReason,
        isLoading: isLoadingApprove || isLoadingEdit || isLoadingReject,
        errorMessage,
        price,
        setPrice,
        handleAction,
        setRejectReason,
        handleSubmit,
        t,
    };

    useEffect(() => {
        setPrice({ buyPrice, sellPrice, operatedAmount });
    }, []);

    return <ChannelsExchangeDetailActions {...childProps} />;
};

const propTypes = {};

interface extraProps {
    id: string;
    status: string;
    buyPrice: number;
    sellPrice: number;
    operatedAmount: number;
    ticker: string;
    declaration?: AffidavitObject;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
    close(): void;
    setSnackBarMessage(msj: string, sever?: AlertColor): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsExchangeDetailActionsContainer.propTypes = propTypes;

export default ChannelsExchangeDetailActionsContainer;
