import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './StatusTable.styled';
import { StateTableInfo } from 'types/common/general.types';
import { Chip, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { getColorByStatus } from 'utils/helpers/commonHelper';
import { tCommon } from 'constants/appConstants';

const StatusTable = (props: Props) => {
    const { content, t } = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="steps">
                <TableHead>
                    <TableRow sx={{ display: 'flex' }}>
                        {content.map(s => (
                            <St.CenteredCell key={s.id}>{s.name}</St.CenteredCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ display: 'flex' }}>
                        {content.map(s => (
                            <St.CenteredCell key={s.id}>
                                <Chip
                                    sx={{ backgroundColor: getColorByStatus(s.status) }}
                                    label={t(s.status, tCommon)}
                                />
                            </St.CenteredCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    content: StateTableInfo<string>[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
StatusTable.propTypes = propTypes;

export default StatusTable;
