import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import AppBar from './AppBar';
import mainLogo from 'assets/img/general/consultatio_logo.svg?react';

const AppBarContainer = (props: Props) => {
    const { setIsOpen } = props;

    const handleDrawerOpen = () => setIsOpen(true);

    const childProps = {
        ...props,
        mainLogo,
        handleDrawerOpen,
    };

    return <AppBar {...childProps} />;
};

const propTypes = {
    setIsOpen: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AppBarContainer.propTypes = propTypes;

export default AppBarContainer;
