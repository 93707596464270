import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsModalActions from './ChannelsModalActions';
import { ChannelsModalActionsProps } from 'types/common/channels.types';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from 'hooks/api/utils.hooks';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';

const ChannelsModalActionsContainer = (props: Props) => {
    const { type } = props;
    const { t } = useTranslation('channelsCommon');
    const { downloadFile, SnackBar } = useDownloadFile();
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const childProps = {
        ...props,
        downloadFile,
        SnackBar,
        t,
        canAcquireOrRelease: !isHiddenByAbility(`UPDATE_ORDERS_${type}`),
    };

    return <ChannelsModalActions {...childProps} />;
};

const propTypes = {};

interface extraProps extends ChannelsModalActionsProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalActionsContainer.propTypes = propTypes;

export default ChannelsModalActionsContainer;
