import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import RejectForm from './RejectForm';
import { useTranslation } from 'react-i18next';

const RejectFormContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('channelsCommon'); // Analizar de modificar en otro momento

    const childProps = {
        ...props,
        t,
    };

    return <RejectForm {...childProps} />;
};

const propTypes = {
    reason: PropTypes.string,
    setReason: PropTypes.func.isRequired,
};

interface extraProps {
    errorMessage?: string | null;
    disabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RejectFormContainer.propTypes = propTypes;

export default RejectFormContainer;
