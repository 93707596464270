import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './Search.styled';
import { Tprop } from 'types/common/general.types';
import { tCommonGrid } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';

const Search = (props: Props) => {
    const { disabled, handleSearchChange, t } = props;

    return (
        <St.SearchStyled>
            <St.SearchIconWrapper>
                <CustomIconSelector type="search" />
            </St.SearchIconWrapper>
            <St.StyledInputBase
                disabled={disabled}
                placeholder={t('search_bar', tCommonGrid) ?? ''}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearchChange}
            />
        </St.SearchStyled>
    );
};

const propTypes = {
    handleSearchChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

type extraProps = InferPropsExtended<{}, Tprop>;

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
Search.propTypes = propTypes;

export default Search;
