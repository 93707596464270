import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { collaterals } from 'types/channelsCollateral/channelsCollateral.types';
import { convertCurrencyFormat } from 'utils/helpers/commonHelper';
import { ARS, USD } from 'constants/appConstants';

const ChannelsCollateralDetailWarranty = ({
    localInstrumentsUnderWarranty,
    totalAmount,
    inputsDisabled,
    currency,
    t,
    handleChange,
}: Props) => (
    <>
        <Grid item xs={12}>
            <Typography fontSize={16} fontWeight={500} mb={1.25}>
                {t('collateral_table_title')}
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="instruments under warranty">
                    <TableHead>
                        <TableRow>
                            <TableCell size="small">{t('collateral_table_cell_species')}</TableCell>
                            <TableCell size="small">
                                {t('collateral_table_cell_quantity')}
                            </TableCell>
                            <TableCell size="small">
                                {t('collateral_table_cell_measured_price')}
                            </TableCell>
                            <TableCell size="small">
                                {t('collateral_table_cell_guarantee_amount')}
                            </TableCell>
                            <TableCell size="small">{t('collateral_table_cell_amount')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localInstrumentsUnderWarranty.map(
                            ({ ticker: { id, ticker }, price, quantity, amount }: collaterals) => (
                                <TableRow key={id}>
                                    <TableCell size="small">{ticker}</TableCell>
                                    <TableCell size="small">{quantity}</TableCell>
                                    <TableCell size="small">
                                        <TextField
                                            size="small"
                                            type="number"
                                            name="price"
                                            value={price}
                                            disabled={inputsDisabled}
                                            onChange={({ target: { name, value } }) =>
                                                handleChange(ticker, name, 'quantity', value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell size="small">
                                        <TextField
                                            size="small"
                                            type="number"
                                            name="quantity"
                                            value={quantity}
                                            disabled={inputsDisabled}
                                            onChange={({ target: { name, value } }) =>
                                                handleChange(ticker, name, 'price', value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell size="small">
                                        {convertCurrencyFormat(
                                            amount || quantity * price,
                                            currency === ARS ? ARS : USD,
                                        )}
                                    </TableCell>
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="end">
            <Typography fontSize={16} fontWeight={500} mb={1.25}>
                {t('collateral_table_total_amount')}: &nbsp;
                {convertCurrencyFormat(totalAmount, currency === ARS ? ARS : USD)}
            </Typography>
        </Grid>
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    currency: string;
    inputsDisabled: boolean;
    localInstrumentsUnderWarranty: collaterals[];
    totalAmount: number;
    handleChange(instrument: string, name: string, keyToChange: string, value: string): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralDetailWarranty.propTypes = propTypes;

export default ChannelsCollateralDetailWarranty;
