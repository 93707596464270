import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    CollateralDefinition,
    collaterals,
} from 'types/channelsCollateral/channelsCollateral.types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TAKER } from 'constants/appConstants';
import ChannelsCollateralDetail from './ChannelsCollateralDetail';

const ChannelsCollateralDetailContainer = (props: Props) => {
    const { t } = useTranslation('channelsCommon');
    const {
        row: { collaterals, buySell },
    } = props;
    const [localInstrumentsUnderWarranty, setLocalInstrumentsUnderWarranty] = useState(
        [] as collaterals[],
    );
    const [totalAmount, setTotalAmount] = useState(0);
    const [action, setAction] = useState('');

    const getNewAmount = (item: collaterals, keyToChange: string, value: string) =>
        item[keyToChange] * Number(value);

    const getTotalAmount = () =>
        localInstrumentsUnderWarranty.reduce(
            (accum: number, { quantity, price }) => (accum = accum + quantity * price),
            0,
        );

    const handleChange = (instrument: string, name: string, keyToChange: string, value: string) => {
        setLocalInstrumentsUnderWarranty(prevState =>
            prevState.map(item =>
                item.ticker.ticker === instrument
                    ? {
                          ...item,
                          [name]: Number(value),
                          amount: getNewAmount(item, keyToChange, value),
                      }
                    : item,
            ),
        );
    };

    const childProps = {
        ...props,
        localInstrumentsUnderWarranty,
        totalAmount,
        action,
        handleChange,
        setAction,
        t,
    };

    useEffect(() => {
        if (buySell === TAKER && collaterals?.length) setLocalInstrumentsUnderWarranty(collaterals);
    }, [buySell]);

    useEffect(() => {
        setTotalAmount(getTotalAmount());
    }, [localInstrumentsUnderWarranty]);

    return <ChannelsCollateralDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: CollateralDefinition;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralDetailContainer.propTypes = propTypes;

export default ChannelsCollateralDetailContainer;
