import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsCollateralDetailWarranty from './ChannelsCollateralDetailWarranty';
import { useTranslation } from 'react-i18next';
import { collaterals } from 'types/channelsCollateral/channelsCollateral.types';

const ChannelsCollateralDetailWarrantyContainer = (props: Props) => {
    const { t } = useTranslation('channelsCollateral');

    const childProps = {
        ...props,
        t,
    };

    return <ChannelsCollateralDetailWarranty {...childProps} />;
};

const propTypes = {};

interface extraProps {
    currency: string;
    inputsDisabled: boolean;
    localInstrumentsUnderWarranty: collaterals[];
    totalAmount: number;
    handleChange(instrument: string, name: string, keyToChange: string, value: string): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralDetailWarrantyContainer.propTypes = propTypes;

export default ChannelsCollateralDetailWarrantyContainer;
