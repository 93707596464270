import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import CommonGrid from 'components/common/CommonGrid';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { Order } from 'types/channelsOrders/channelsOrders.types';
import { useOrders } from 'hooks/api/channelsOrders.hooks';

const ChannelsOrdersPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters, refreshTable, SnackBar, SnackBarFile } =
        props;
    return (
        <>
            <CommonGrid
                title={t('orders_list_title')}
                columns={columns}
                resource="orders"
                useGetData={useOrders}
                defaultSortFieldId="createdAt"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                rowHeight={43}
                refreshTable={refreshTable}
                extraFilters={[{ query: 'isManual', queryValue: true }]}
            />
            <SnackBar />
            <SnackBarFile />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<Order>[];
    actionColumns: ActionColumn[];
    headerFilters?: CommonFilter[];
    refreshTable: boolean;
    SnackBar: any;
    SnackBarFile: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsOrdersPage.propTypes = propTypes;

export default ChannelsOrdersPage;
