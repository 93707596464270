import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableHeader from './TableHeader';
import { CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';

const TableHeaderContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <TableHeader {...childProps} />;
};

const propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setShowHeaderFilters: PropTypes.func.isRequired,
    showHeaderFilters: PropTypes.bool.isRequired,
};

type extraProps = {
    refetch: (value: boolean) => void;
    headerFilters?: CommonFilter[];
};

type Props = InferPropsExtended<typeof propTypes, extraProps>;
TableHeaderContainer.propTypes = propTypes;

export default TableHeaderContainer;
