import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ErrorText from './ErrorText';

const ErrorTextContainer = (props: Props) => {
    const { text = 'Error text' } = props;

    const childProps = {
        text,
    };

    return <ErrorText {...childProps} />;
};

const propTypes = {
    text: PropTypes.string,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ErrorTextContainer.propTypes = propTypes;

export default ErrorTextContainer;
