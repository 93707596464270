import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './MenuItem.styled';
import { List } from '@mui/material';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import CustomIconSelector from 'components/common/CustomIconSelector';
import ExternalLinkIcon from 'assets/icons/external-link.svg?react';

const MenuItem = (props: Props) => {
    const {
        drawerOpened,
        isCollapseOpen,
        itemData,
        selectedMenu,
        handleNavigate,
        setIsCollapseOpen,
        hasSubroutes,
        menuIsSelected,
        selectedMenuSegment,
    } = props;

    return (
        <St.ListItem dense disablePadding>
            <St.DrawerMenuButton
                ownerState={{
                    pl: '40px',
                    drawerOpened,
                    justify: `${drawerOpened ? 'initial' : 'space-between'}`,
                    menuIsSelected,
                }}
                onClick={() =>
                    hasSubroutes ? setIsCollapseOpen(!isCollapseOpen) : handleNavigate(itemData)
                }
                selected={menuIsSelected}>
                <St.MenuItemIcon
                    className="icon"
                    ownerState={{
                        isSelected: menuIsSelected,
                        drawerOpened,
                    }}>
                    <itemData.icon />
                </St.MenuItemIcon>
                <St.MenuItemText primary={itemData.title} drawerOpened={drawerOpened} />
                {itemData.externalUrl && !hasSubroutes && drawerOpened && (
                    <ExternalLinkIcon style={{ height: '20px', fill: '#BDBDBD' }} />
                )}
                {hasSubroutes && drawerOpened && (
                    <St.MenuItemIcon
                        className="icon"
                        ownerState={{
                            drawerOpened,
                            isArrowIcon: true,
                        }}>
                        <CustomIconSelector
                            type={isCollapseOpen ? 'dropUp' : 'dropDown'}
                            sx={{ color: '#BDBDBD' }}
                        />
                    </St.MenuItemIcon>
                )}
            </St.DrawerMenuButton>
            {hasSubroutes && (
                <St.Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
                    <List component="div" dense disablePadding>
                        {itemData.subRoutes?.map(subRoute => {
                            const subRouteNameWithHyphen = subRoute.name.replace(/_/g, '-');
                            return (
                                <St.DrawerSubmenuButton
                                    ownerState={{
                                        pl: `${drawerOpened ? '44px' : '32px'}`,
                                        drawerOpened,
                                    }}
                                    key={subRoute.name}
                                    onClick={() => handleNavigate(subRoute)}
                                    selected={
                                        `${selectedMenuSegment}/${subRouteNameWithHyphen}` ===
                                        selectedMenu
                                    }>
                                    <St.MenuItemIcon
                                        ownerState={{
                                            isSelected: subRoute.name === selectedMenu,
                                            drawerOpened,
                                        }}
                                        className="sub_icon">
                                        <subRoute.icon />
                                    </St.MenuItemIcon>
                                    <St.MenuItemText
                                        primary={subRoute.title}
                                        drawerOpened={drawerOpened}
                                    />
                                    {subRoute.externalUrl && (
                                        <ExternalLinkIcon
                                            style={{ marginBottom: '10px', height: '20px' }}
                                        />
                                    )}
                                </St.DrawerSubmenuButton>
                            );
                        })}
                    </List>
                </St.Collapse>
            )}
        </St.ListItem>
    );
};

const propTypes = {
    drawerOpened: PropTypes.bool.isRequired,
    isCollapseOpen: PropTypes.bool.isRequired,
    handleNavigate: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
    setIsCollapseOpen: PropTypes.func.isRequired,
    hasSubroutes: PropTypes.bool.isRequired,
    menuIsSelected: PropTypes.bool.isRequired,
    selectedMenuSegment: PropTypes.string.isRequired,
};

interface extraProps {
    // theme: Theme;
    itemData: SubRouteInfo;
    isSubmenuSelected: boolean | undefined;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MenuItem.propTypes = propTypes;

export default MenuItem;
