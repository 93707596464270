import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid } from '@mui/material';
import { tChannelsCommon, tCommon } from 'constants/appConstants';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { UserAccount } from 'types/users/users.api.types';
import AlertNoData from 'components/common/AlertNoData';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

const ClientSectionCustomerCodes = ({ marketAccounts, t }: Props) => (
    <Grid container spacing={1}>
        {marketAccounts?.length > 0 ? (
            marketAccounts.map(({ customerCode, createdAt, enabled }) => (
                <Fragment key={customerCode}>
                    <ChannelsModalItemGrid
                        column={4}
                        label={t('customer_number', tChannelsCommon)}
                        text={customerCode}
                    />
                    <ChannelsModalItemGrid
                        column={5}
                        label={t('clients_list_createdAt_column')}
                        text={formatDateWithHour(createdAt)}
                    />
                    <ChannelsModalItemGrid
                        column={3}
                        label={t('clients_list_enabled_column')}
                        text={t(enabled ? 'yes' : 'no', tCommon)}
                    />
                </Fragment>
            ))
        ) : (
            <AlertNoData title={t('no_data_was_found')} />
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    marketAccounts: UserAccount[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionCustomerCodes.propTypes = propTypes;

export default ClientSectionCustomerCodes;
