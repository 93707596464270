import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconSelector from 'components/common/CustomIconSelector';

const BackToBaseButton = (props: Props) => {
    const { t, backToBase } = props;

    return (
        <Tooltip title={t('back')} sx={{ width: '10%' }}>
            <IconButton onClick={() => backToBase()}>
                <CustomIconSelector type="back" />
            </IconButton>
        </Tooltip>
    );
};

const propTypes = {
    disabled: PropTypes.bool,
    t: PropTypes.any,
};

type extraProps = {
    backToBase: () => void;
};

type Props = InferPropsExtended<typeof propTypes, extraProps>;
BackToBaseButton.propTypes = propTypes;

export default BackToBaseButton;
