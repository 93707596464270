import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InfoLabel from './InfoLabel';
import { SXBreakpoint } from 'types/common/general.types';

const InfoLabelContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <InfoLabel {...childProps} />;
};

const propTypes = {
    label: PropTypes.string.isRequired,
};

interface extraProps {
    text?: string | number;
    fontSize?: SXBreakpoint<string>;
    Component?: React.ReactElement;
    withoutSeparation?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InfoLabelContainer.propTypes = propTypes;

export default InfoLabelContainer;
