import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useClientsDefinitions, useProspectsDefinitions } from 'hooks/api/clients.hooks';
import CommonGrid from 'components/common/CommonGrid';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ClientOrProspectItem } from 'types/clients/clients.types';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';

const ClientsPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters, headerActions, SnackBar, isProspect } = props;
    return (
        <>
            <CommonGrid
                title={t(isProspect ? 'prospects_list_title' : 'clients_list_title')}
                columns={columns}
                resource={isProspect ? 'prospect' : 'clients'}
                useGetData={isProspect ? useProspectsDefinitions : useClientsDefinitions} // TODO Reemplazar por get Onboarding (MULTUM 158)
                defaultSortFieldId="createdAt"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                extraActionsInHeader={headerActions}
                rowHeight={isProspect ? 38 : 30}
            />
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<ClientOrProspectItem>[];
    actionColumns: ActionColumn[];
    headerActions: ActionHeader[];
    headerFilters?: CommonFilter[];
    SnackBar: any;
    isProspect?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsPage.propTypes = propTypes;

export default ClientsPage;
