import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import ConfirmationPage from './ConfirmationForm';
import { useForgotPasswordConfirmation } from 'hooks/api/auth.hooks';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18n';
import { VALID_PASSWORD_REGEX } from 'utils/helpers/constants';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    confirmationCode: '',
    newPassword: '',
    newPasswordConfirmation: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            confirmationCode: Yup.string()
                .min(
                    6,
                    String(
                        i18n.t(
                            'change_password_screen_code_field_min_length_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .required(tRequiredFieldError),
            newPassword: Yup.string()
                .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
                .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
                .required(tRequiredFieldError)
                .matches(
                    VALID_PASSWORD_REGEX,
                    String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
                ),
            newPasswordConfirmation: Yup.string()
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    String(
                        i18n.t(
                            'change_password_screen_passwords_field_do_not_match_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .required(tRequiredFieldError),
        }),
    );

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const ConfirmationFormContainer = ({ emailSent }: Props) => {
    const { t } = useTranslation('auth');
    const { mutate, isLoading, errorMessage } = useForgotPasswordConfirmation();

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const confirmationValues = {
                code: values.confirmationCode,
                username: emailSent,
                password: values.newPassword,
            };
            mutate(confirmationValues);
        },
        [emailSent, mutate],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        t,
        isLoading,
        formik,
        errorMessage,
    };

    return <ConfirmationPage {...childProps} />;
};

const propTypes = {};

interface extraProps {
    emailSent: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ConfirmationFormContainer.propTypes = propTypes;

export default ConfirmationFormContainer;
