import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DeleteDialog from '../DeleteDialog';
import { ICON_CELL_SIZE, tCommonGrid } from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';

const CanDelete = (props: Props) => {
    const { id, resource, t, deleteModal, setDeleteModal, onDelete, isLoading } = props;

    return (
        <>
            <Tooltip title={t('delete_tooltip', tCommonGrid)}>
                <IconButton onClick={() => setDeleteModal(true)}>
                    <CustomIconSelector type="delete" sx={ICON_CELL_SIZE} />
                </IconButton>
            </Tooltip>
            <DeleteDialog
                title={t('delete_title', tCommonGrid)}
                contentText={t('deleting_dialog_form_content_can_delete', {
                    item: t(`${resource.replaceAll('-', '_')}_modal_text`, tCommonGrid),
                    pronoun: [
                        'companies',
                        'areas',
                        'market-dollar-quotes',
                        'client-delete-account-requests',
                        'alerts',
                        'market-instrument-rules',
                    ].includes(resource)
                        ? 'esta'
                        : 'este',
                    ...tCommonGrid,
                })}
                open={deleteModal}
                setOpen={setDeleteModal}
                onDelete={() => onDelete(id as number)}
                deleteStatus={isLoading}
            />
        </>
    );
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    deleteModal: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.any.isRequired,
};

interface extraProps {
    id: number | string;
    setDeleteModal: (state: boolean) => void;
    onDelete: (elementId: number) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CanDelete.propTypes = propTypes;

export default CanDelete;
