import React from 'react';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { exportFromJson, extractDateRange, lowerFirstLetter } from 'utils/helpers/commonHelper';

const useExportData = (
    useGetData: (params: GridHookParams) => any,
    columns: Array<any>,
    exportTitle?: string,
    extraFilters?: string,
    backTableId?: number,
    showDateRange?: boolean,
) => {
    const [isExporting, setIsExporting] = React.useState<boolean | null>(null);
    const filterQueryString = extraFilters;

    const {
        data: dataFromQuery,
        isLoading: loading,
        refetch,
    } = useGetData({
        filterQueryString,
        forceDisable: true,
        backTableId,
    } as GridHookParams);

    React.useEffect(() => {
        if (dataFromQuery && isExporting) {
            try {
                const columnsToShow = columns.filter(column => column.exportOption);

                const formattedData = dataFromQuery.data?.map((data: any) => {
                    const row: any = {};
                    columnsToShow.forEach(
                        ({ name, exportOption }: { name: string; exportOption: any }) => {
                            row[name as keyof typeof row] = exportOption
                                ? exportOption(data)
                                : '--';
                        },
                    );
                    return row;
                });

                exportFromJson(
                    formattedData,
                    columnsToShow.map(x => x.name),
                    lowerFirstLetter(exportTitle ?? 'planilla'),
                    showDateRange ? extractDateRange(filterQueryString) : undefined,
                );
            } catch (error) {
                console.log('error', error);
            } finally {
                setIsExporting(false);
            }
        }
    }, [dataFromQuery, isExporting]);

    const handleExportData = async () => {
        await refetch();
        setIsExporting(true);
    };
    return { handleExportData, loading };
};

export default useExportData;
