import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputBaseComponentProps,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import CustomIconSelector from '../CustomIconSelector';

const PasswordField = (props: Props) => {
    const {
        name,
        label,
        variant = 'outlined',
        size = 'small',
        placeholder,
        value,
        onChange,
        fullWidth,
        error,
        helperText,
        inputProps,
        autoFocus,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
    } = props;

    return (
        <FormControl variant={variant} fullWidth={fullWidth} error={error} size={size}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                id={name}
                type={showPassword ? 'text' : 'password'}
                value={value || undefined}
                onChange={onChange}
                placeholder={placeholder}
                inputProps={{
                    ...inputProps,
                    autoComplete: inputProps?.autoComplete
                        ? inputProps?.autoComplete
                        : 'one-time-code',
                }}
                autoFocus={autoFocus}
                aria-describedby={`${name}-helper-text`}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            <CustomIconSelector
                                type={showPassword ? 'visibilityOff' : 'detail'}
                                sx={{ width: 20, height: 20 }}
                            />
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
            />
            <FormHelperText id={`${name}-helper-text`}>{helperText}</FormHelperText>
        </FormControl>
    );
};

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    inputProps: PropTypes.string,
    showPassword: PropTypes.bool.isRequired,
    handleClickShowPassword: PropTypes.func.isRequired,
    handleMouseDownPassword: PropTypes.func.isRequired,
};
interface extraProps {
    variant?: 'standard' | 'outlined' | 'filled';
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    error?: boolean;
    autoFocus?: boolean;
    inputProps?: InputBaseComponentProps;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
PasswordField.propTypes = propTypes;

export default PasswordField;
