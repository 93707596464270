import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfilePageForm from './ProfilePageForm';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import i18n from 'utils/i18n';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import { VALID_PASSWORD_REGEX } from 'utils/helpers/constants';
import { useFormik } from 'formik';
import { useChangePassword } from 'hooks/api/usersBackoffice.hooks';
import { AlertColor } from '@mui/material';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = (uuid: string) => ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    uuid,
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            oldPassword: Yup.string()
                .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
                .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
                .required(tRequiredFieldError)
                .matches(
                    VALID_PASSWORD_REGEX,
                    String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
                ),
            newPassword: Yup.string()
                .min(8, String(i18n.t('password_field_min_length_validation', tErrorsContext)))
                .max(20, String(i18n.t('password_field_max_length_validation', tErrorsContext)))
                .required(tRequiredFieldError)
                .matches(
                    VALID_PASSWORD_REGEX,
                    String(i18n.t('password_field_invalid_regex_validation', tErrorsContext)),
                ),
            newPasswordConfirmation: Yup.string()
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    String(
                        i18n.t(
                            'change_password_screen_passwords_field_do_not_match_validation',
                            tErrorsContext,
                        ),
                    ),
                )
                .required(tRequiredFieldError),
        }),
    );

const ProfilePageFormContainer = (props: Props) => {
    const { setOpenModal, uuid, setSnackBarMessage } = props;
    const { t } = useTranslation('profilePage');
    const handleClose = () => {
        setOpenModal(false);
    };

    const { mutate: onChangePassword } = useChangePassword(handleClose, setSnackBarMessage);

    const handleSubmit = React.useCallback(
        async (data: any) => {
            const payload = {
                oldPassword: data?.oldPassword as string,
                newPassword: data?.newPassword as string,
            };

            onChangePassword(payload);
        },
        [onChangePassword],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(uuid as string),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        t,
        handleClose,
        formik,
        ...props,
    };

    return <ProfilePageForm {...childProps} />;
};

const propTypes = {
    title: PropTypes.string.isRequired,
    openModal: PropTypes.bool.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    uuid: string | undefined;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePageFormContainer.propTypes = propTypes;

export default ProfilePageFormContainer;
