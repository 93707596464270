import * as React from 'react';
// import PropTypes from 'prop-types';
import St from './ActionButton.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';

const ActionButton = (props: Props) => {
    const {
        variant = 'contained',
        size = 'small',
        color = 'primary',
        type = 'button',
        onClick,
        loading = false,
        loadingPosition = 'center',
        children,
        disabled = false,
        sx,
    } = props;

    return (
        <St.ActionButton
            variant={variant}
            loading={loading}
            loadingPosition={loadingPosition}
            size={size}
            color={color}
            type={type}
            disabled={disabled}
            sx={sx}
            onClick={onClick}>
            {children}
        </St.ActionButton>
    );
};

const propTypes = {};

interface extraProps {
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    // title: string;
    loading?: boolean;
    loadingPosition?: 'start' | 'center' | 'end';
    children: React.ReactNode;
    disabled?: boolean;
    sx?: Object;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ActionButton.propTypes = propTypes;

export default ActionButton;
