import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionActivity from './ClientSectionActivity';
import { ClientActivity } from 'types/clients/clients.types';
import { useTranslation } from 'react-i18next';

const ClientSectionActivityContainer = (props: Props) => {
    const { t } = useTranslation();
    const childProps = {
        ...props,
        t,
    };

    return <ClientSectionActivity {...childProps} />;
};

const propTypes = {};

interface extraProps extends ClientActivity {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionActivityContainer.propTypes = propTypes;

export default ClientSectionActivityContainer;
