import * as React from 'react';
import PropTypes from 'prop-types';
// import St from './UserExceptionsTable.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Alert,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { tCommon, tCommonGrid } from 'constants/appConstants';
import ActionButton from 'components/common/ActionButton';
import CustomIconSelector from '../CustomIconSelector';
import defaultTheme from 'utils/theme/defaultTheme';
import { UserException } from 'types/common/users.types';

const UserExceptionsTable = (props: Props) => {
    const {
        deleteUserException,
        errorMessageUser,
        handleSearchUser,
        isLoadingUser,
        setUserException,
        t,
        userException,
        exceptions,
        errorMessage,
        setErrorMessage,
    } = props;

    return (
        <>
            <Typography fontSize={20}>{t('user_exceptions_table_exceptions')}</Typography>
            <Grid item xs={12} mt={0.625} display="flex">
                <TextField
                    id="username-search-input"
                    fullWidth
                    size="small"
                    placeholder={t('user_exceptions_table_user_exception_placeholder')}
                    value={userException}
                    onChange={({ target: { value } }) => setUserException(value)}
                />
                <ActionButton
                    color="secondary"
                    sx={{
                        py: 1,
                        px: 0,
                        mt: 0,
                        ml: 2.5,
                        width: '30%',
                        color: '#FFF',
                    }}
                    loading={isLoadingUser}
                    disabled={isLoadingUser || !userException}
                    onClick={handleSearchUser}>
                    {t('add', tCommon)}
                </ActionButton>
            </Grid>
            <Grid item xs={12} mt={2}>
                {errorMessage && (
                    <Alert
                        severity="error"
                        sx={{ marginBottom: '15px' }}
                        onClose={() => setErrorMessage(null)}>
                        {errorMessage}
                    </Alert>
                )}
                {errorMessageUser && (
                    <Alert severity="error" sx={{ marginBottom: '15px' }}>
                        {errorMessageUser}
                    </Alert>
                )}
            </Grid>
            {exceptions && exceptions?.length > 0 && (
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="user's exception table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell align="right" sx={{ pr: 5 }}>
                                        {t('actions', tCommonGrid)}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {exceptions.map((e, index: number) => (
                                    <TableRow key={e.id}>
                                        <TableCell>{e.username}</TableCell>
                                        <TableCell align="right" sx={{ pr: 4 }}>
                                            <IconButton onClick={() => deleteUserException(index)}>
                                                <CustomIconSelector
                                                    type="delete"
                                                    sx={{ color: defaultTheme.palette.error.main }}
                                                />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
};

interface extraProps {
    userException: string;
    isLoadingUser: boolean;
    errorMessageUser: string;
    exceptions?: UserException[];
    handleSearchUser(): void;
    setUserException(userException: string): void;
    deleteUserException(index: number): void;
    errorMessage: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserExceptionsTable.propTypes = propTypes;

export default UserExceptionsTable;
