import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientProspectActions from './ClientProspectActions';
import { useTranslation } from 'react-i18next';
import { AlertColor } from '@mui/material';
import { ClientRiskProfile, ProspectStateInfo } from 'types/clients/clients.types';
import { useGetProspectMarketData } from 'hooks/api/clients.hooks';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';

const ClientProspectActionsContainer = (props: Props) => {
    const { id } = props;
    const { t } = useTranslation('clients');
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [action, setAction] = React.useState<'approve' | 'reject' | 'relate' | null>(null);
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const { data: marketInfo, isLoading } = useGetProspectMarketData(id);

    const handleSetAction = (action: 'approve' | 'reject' | 'relate') => {
        setAction(action);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setAction(null);
        setOpenModal(false);
    };

    const childProps = {
        ...props,
        t,
        handleSetAction,
        handleCloseModal,
        openModal,
        action,
        marketInfo,
        isLoading,
        isHiddenByAbility,
    };

    return <ClientProspectActions {...childProps} />;
};

const propTypes = {
    id: PropTypes.string.isRequired,
    docNumber: PropTypes.string.isRequired,
    selectedSection: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    refetch: PropTypes.func.isRequired,
};

interface extraProps {
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    steps?: ProspectStateInfo[];
    riskProfile?: ClientRiskProfile;
    handleOpenEscoInfo: (selectedOption: string, expanded?: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectActionsContainer.propTypes = propTypes;

export default ClientProspectActionsContainer;
