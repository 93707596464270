import { BrokerUsersDefinition } from 'types/usersBroker/usersBroker.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UsersBrokerDetail.styled';
import PropTypes from 'prop-types';
import InfoLabel from 'components/common/InfoLabel';

export const UsersBrokerDetail = (props: Props) => {
    const { t, detailData } = props;

    return (
        <St.UsersBrokerDetail data-testid="UsersBrokerDetail-page">
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('broker_users_form_name_input') + ':'}
                text={detailData?.name}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('broker_users_form_last_name_input') + ':'}
                text={detailData?.lastName}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('broker_users_form_email_input') + ':'}
                text={detailData?.email}
            />
            <InfoLabel
                fontSize={'14px'}
                withoutSeparation
                label={t('broker_users_form_user_name_input') + ':'}
                text={detailData?.username}
            />
            {detailData?.channelInfo?.length > 0 &&
                detailData.channelInfo.map(({ isInternal, vbCode, referralCode }) => (
                    <>
                        <InfoLabel
                            fontSize={'14px'}
                            withoutSeparation
                            label={t('broker_users_form_vb_internal_input') + ':'}
                            text={t(
                                `broker_users_form_user_${isInternal ? 'internal' : 'external'}`,
                            )}
                        />
                        <InfoLabel
                            fontSize={'14px'}
                            withoutSeparation
                            label={t('broker_users_form_vb_code_input') + ':'}
                            text={vbCode}
                        />
                        <InfoLabel
                            fontSize={'14px'}
                            withoutSeparation
                            label={t('broker_users_form_referral_code_input') + ':'}
                            text={referralCode}
                        />
                    </>
                ))}
        </St.UsersBrokerDetail>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    detailData: BrokerUsersDefinition;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBrokerDetail.propTypes = propTypes;

export default UsersBrokerDetail;
