import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableHeaderButton from './TableHeaderButton';
import { ReactElement } from 'react';

const TableHeaderButtonContainer = (props: Props) => {
    const { variant = 'contained', disabled = false } = props;

    const childProps = {
        ...props,
        variant,
        disabled,
    };

    return <TableHeaderButton {...childProps} />;
};

const propTypes = {
    text: PropTypes.string.isRequired,
};

interface extraProps {
    icon?: ReactElement;
    handleClick: () => void;
    variant?: 'outlined' | 'contained' | 'text';
    disabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableHeaderButtonContainer.propTypes = propTypes;

export default TableHeaderButtonContainer;
