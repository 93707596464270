import * as React from 'react';
// import St from './CustomCheckbox.styled';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';

const CustomCheckbox = ({ handleChange, name, checked, label }: Props) => (
    <FormControlLabel
        label={label}
        control={<Checkbox name={name} id={name} onChange={handleChange} checked={checked} />}
    />
);

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

interface extraProps {
    name: string;
    checked: boolean;
    handleChange(event: ChangeEvent<HTMLInputElement>): void;
    label?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCheckbox.propTypes = propTypes;

export default CustomCheckbox;
