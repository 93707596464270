import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { MutableRefObject } from 'react';
import JoditEditor from 'jodit-react';
import { Typography } from '@mui/material';

const CustomTextEditor = ({ messageError, editorRef, config, value, onBlur }: Props) => (
    <>
        <JoditEditor ref={editorRef} config={config} value={value} onBlur={onBlur} />
        {messageError && (
            <Typography fontSize={14} color="error" mb={1.25}>
                {messageError}
            </Typography>
        )}
    </>
);

const propTypes = {};

interface extraProps {
    editorRef: MutableRefObject<any>;
    config: Object;
    value: string;
    onBlur(val: string): void;
    messageError?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomTextEditor.propTypes = propTypes;

export default CustomTextEditor;
