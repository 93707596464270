import * as React from 'react';
import * as Yup from 'yup';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { tErrorsContext } from 'constants/appConstants';
import i18n from 'utils/i18n';
import { SidebarContext } from 'context/sidebar.context';
import ClientImportClientVBPage from './ClientImportClientVBPage';
import { useGetClientVBById } from 'hooks/api/clientImportClientVB.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useNavigate } from 'react-router-dom';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    clientNumber: '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        clientNumber: Yup.string().required(
            i18n.t('required_client_number', tErrorsContext) as string,
        ),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const ClientImportClientVBPageContainer = (props: Props) => {
    const { t } = useTranslation('clientImportClientVB');
    const { setSnackBarMessage, SnackBar } = useSnackBar();
    const { drawerWidth } = React.useContext(SidebarContext);
    const [submitId, setSubmitId] = React.useState<number | false>(false);
    const { data, isLoading, remove, error } = useGetClientVBById(submitId);
    const navigate = useNavigate();

    const removeData = () => {
        if (data) {
            setSubmitId(false);
            remove();
        }
    };

    const handleSubmit = React.useCallback(
        async (data: any) => {
            setSubmitId(data.clientNumber as number);
        },
        [setSubmitId],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        returnPreviousPage: () => navigate('/client/clients'),
        removeData,
        drawerWidth,
        setSnackBarMessage,
        btnDisable: isLoading,
        isLoading,
        SnackBar,
        formik,
        error,
        data,
        t,
    };

    return <ClientImportClientVBPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientImportClientVBPageContainer.propTypes = propTypes;

export default ClientImportClientVBPageContainer;
