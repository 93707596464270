import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CommonGrid from 'components/common/CommonGrid';
import { Deposits } from 'types/clientDeposits/clientDeposits.types';
import { useDepositsDefinitions } from 'hooks/api/clientDeposits.hooks';

const ClientDepositsPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters, SnackBar } = props;
    return (
        <>
            <CommonGrid
                title={t('deposits_list_title')}
                columns={columns}
                resource="client-deposits"
                useGetData={useDepositsDefinitions}
                defaultSortFieldId="transferDate"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
                rowHeight={45}
            />
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<Deposits>[];
    actionColumns: ActionColumn[];
    headerFilters: CommonFilter[];
    SnackBar: () => JSX.Element;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDepositsPage.propTypes = propTypes;

export default ClientDepositsPage;
