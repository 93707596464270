import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconSelector from '../CustomIconSelector';
import { IconButton } from '@mui/material';
import defaultTheme from 'utils/theme/defaultTheme';

const RefreshButton = (props: Props) => {
    const { refresh, disabled } = props;

    return (
        <IconButton disabled={disabled} aria-label="refresh" onClick={() => refresh(true)}>
            <CustomIconSelector type="refresh" sx={{ color: defaultTheme.palette.primary.main }} />
        </IconButton>
    );
};

const propTypes = {};

interface extraProps {
    refresh: (value: boolean) => void;
    disabled?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RefreshButton.propTypes = propTypes;

export default RefreshButton;
