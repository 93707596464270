import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsFundDetail from './ChannelsFundDetail';
import { useTranslation } from 'react-i18next';
import { FundingApi } from 'types/channelsFundings/channelsFundings.api.types';

const ChannelsFundDetailContainer = (props: Props) => {
    const { t } = useTranslation('channelsCommon');

    const childProps = {
        ...props,
        t,
    };

    return <ChannelsFundDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: FundingApi;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsFundDetailContainer.propTypes = propTypes;

export default ChannelsFundDetailContainer;
