import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import Drawer from './Drawer';
import consultatioBigLogo from 'assets/img/general/consultatio_big_logo.svg?react';
import consultatioSmallLogo from 'assets/img/general/consultatio_small_logo.png';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import { useNavigate } from 'react-router-dom';
import { RouteInfo } from 'utils/helpers/routesHelper';

const DrawerContainer = (props: Props) => {
    const { setIsOpen, menuCollapsed, setMenuCollapsed, open } = props;
    const { matchesMd } = useBreakpoints();
    const navigate = useNavigate();
    const handleDrawerOpen = () => {
        if (!open) setIsOpen(true);
    };
    const handleDrawerClose = () => {
        if (open) setIsOpen(false);
    };
    const handleNavigate = (listItem: RouteInfo) => {
        if (listItem.externalUrl) {
            window.open(listItem.externalUrl);
            return;
        }
        navigate(listItem.path ?? '/');
    };
    const handleMobileNavigate = (listItem: RouteInfo) => {
        if (listItem.externalUrl) {
            window.open(listItem.externalUrl);
            handleDrawerClose();
            return;
        }
        handleNavigate(listItem);
        handleDrawerClose();
    };
    const handleMenuCollapse = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const childProps = {
        ...props,
        mainLogo: consultatioBigLogo,
        smallLogo: consultatioSmallLogo,
        matchesMd,
        handleDrawerOpen,
        handleDrawerClose,
        handleMenuCollapse,
        handleNavigate,
        handleMobileNavigate,
    };

    return <Drawer {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    drawerWidth: PropTypes.number.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    menuCollapsed: PropTypes.bool.isRequired,
    setMenuCollapsed: PropTypes.func.isRequired,
    selectedMenu: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DrawerContainer.propTypes = propTypes;

export default DrawerContainer;
