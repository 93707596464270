import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentConditionsTable from './InstrumentConditionsTable';
import { InstrumentRuleConditions } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import { useTranslation } from 'react-i18next';

const InstrumentConditionsTableContainer = (props: Props) => {
    const { handleChange, conditions } = props;
    const { t } = useTranslation('settingsInstrumentRules');
    // Sacar ns especifico si se mueve a common en el futuro

    const handleCheckTermInRow = (condition: InstrumentRuleConditions, type: string) => {
        const index = conditions.findIndex(c => c === condition);
        if (conditions[index].terms.includes(type)) {
            const i = conditions[index].terms.indexOf(type);
            conditions[index].terms.splice(i, 1);
        } else {
            const aux = [...conditions[index].terms, type];
            conditions[index].terms = aux;
        }
        handleChange({
            target: {
                name: 'conditions',
                value: conditions,
            },
        });
    };

    const handleCheckCurrencyInRow = (condition: InstrumentRuleConditions, type: string) => {
        const index = conditions.findIndex(c => c === condition);

        if (conditions[index].currencies.includes(type)) {
            const i = conditions[index].currencies.indexOf(type);
            conditions[index].currencies.splice(i, 1);
        } else {
            const aux = [...conditions[index].currencies, type];
            conditions[index].currencies = aux;
        }

        handleChange({
            target: {
                name: 'conditions',
                value: conditions,
            },
        });
    };

    const handleDeleteInstrumentCondition = (index: number) => {
        conditions?.splice(index, 1);

        handleChange({
            target: {
                name: 'conditions',
                value: conditions,
            },
        });
    };
    const childProps = {
        ...props,
        t,
        handleDeleteInstrumentCondition,
        handleCheckTermInRow,
        handleCheckCurrencyInRow,
    };

    return <InstrumentConditionsTable {...childProps} />;
};

const propTypes = {
    handleChange: PropTypes.func.isRequired,
    hasDeleteAction: PropTypes.bool,
};

interface extraProps {
    conditions: InstrumentRuleConditions[];
    instrumentTypesOptions: { id: number; name: string; value: string }[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentConditionsTableContainer.propTypes = propTypes;

export default InstrumentConditionsTableContainer;
