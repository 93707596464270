import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn, CommonFilter } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CommonGrid from 'components/common/CommonGrid';
import { useClientBankAccountsDefinitions } from 'hooks/api/clientBankAccounts.hooks';
import { ClientBankAccountApi } from 'types/clientBankAccounts/clientBankAccounts.api.types';

const ClientBankAccountsPage = (props: Props) => {
    const { t, columns, actionColumns, headerFilters } = props;
    return (
        <>
            <CommonGrid
                title={t('client_bank_accounts_list_title')}
                columns={columns}
                resource="client_bank_accounts"
                useGetData={useClientBankAccountsDefinitions}
                defaultSortFieldId="createdAt"
                canExport
                customActionColumns={actionColumns}
                headerFilters={headerFilters}
            />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<ClientBankAccountApi>[];
    actionColumns: ActionColumn[];
    headerFilters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsPage.propTypes = propTypes;

export default ClientBankAccountsPage;
