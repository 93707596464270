import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBrokerConfirmationAction from './UsersBrokerConfirmationAction';
import { useTranslation } from 'react-i18next';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import { useDisableUsersBroker, useEnableUsersBroker } from 'hooks/api/usersBroker.hooks';
import PropTypes from 'prop-types';

const UsersBrokerConfirmationActionContainer = (props: Props) => {
    const { id, action, close } = props;
    const { t } = useTranslation('usersBroker');
    const { setSnackBarMessage } = useSnackBar();
    const { mutate: mutateEnable, isLoading: isLoadingEnable } =
        useEnableUsersBroker(setSnackBarMessage);
    const { mutate: mutateDisable, isLoading: isLoadingDisable } =
        useDisableUsersBroker(setSnackBarMessage);

    const handleSubmit = () =>
        action !== 'disable'
            ? mutateEnable(
                  { id },
                  {
                      onSuccess: () =>
                          setSnackBarMessage(t(`broker_users_confirmation_${action}_successfully`)),
                  },
              )
            : mutateDisable(
                  { id },
                  {
                      onSuccess: () =>
                          setSnackBarMessage(t(`broker_users_confirmation_${action}_successfully`)),
                  },
              );

    const childProps = {
        ...props,
        isLoadingEnable,
        isLoadingDisable,
        handleSubmit,
        t,
        action,
        close,
    };

    return <UsersBrokerConfirmationAction {...childProps} />;
};

const propTypes = {
    close: PropTypes.func.isRequired,
};

interface extraProps {
    id: string;
    action: string;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBrokerConfirmationActionContainer.propTypes = propTypes;

export default UsersBrokerConfirmationActionContainer;
