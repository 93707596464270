import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientProspectApproveModal from './ClientProspectApproveModal';
import { useTranslation } from 'react-i18next';
import { useApproveProspect } from 'hooks/api/clients.hooks';
import { useFormik } from 'formik';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import * as Yup from 'yup';
import { t } from 'i18next';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = () => ({
    customerCode: '',
});

const getValidationSchema = () =>
    Yup.object().shape({
        customerCode: Yup.number()
            .required(tRequiredFieldError)
            .test(
                'correct_value',
                t('approve_prospect_customer_code_error', tErrorsContext) as string,
                value => {
                    return String(value).length === 9 || String(value).length === 8;
                },
            ),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const ClientProspectApproveModalContainer = (props: Props) => {
    const { setSnackBarMessage, id, close } = props;
    const { t } = useTranslation('clients');
    const { mutate, isLoading } = useApproveProspect(setSnackBarMessage, close);

    const handleSubmit = React.useCallback(
        async (data: { customerCode: string }) => {
            mutate({ id, body: data });
        },
        [id, mutate],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            enableReinitialize: true,
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        t,
        formik,
        isLoading,
    };

    return <ClientProspectApproveModal {...childProps} />;
};

const propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    setSnackBarMessage: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientProspectApproveModalContainer.propTypes = propTypes;

export default ClientProspectApproveModalContainer;
