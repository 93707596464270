import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CanDelete from './CanDelete';
import { useTranslation } from 'react-i18next';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import { DeleteHook } from 'types/common/general.types';

const CanDeleteContainer = (props: Props) => {
    const { deleteHook } = props;
    const [deleteModal, setDeleteModal] = React.useState(false);
    const { t } = useTranslation();
    const { setSnackBarMessage } = useSnackBar();
    const { mutate, isLoading, isError, errorMessage, successfulMessage } = deleteHook();

    const onDelete = React.useCallback((elementId: number) => {
        mutate(elementId, {
            onSuccess: () => {
                setSnackBarMessage(successfulMessage);
            },
        });
    }, []);

    React.useEffect(() => {
        if (isError) setSnackBarMessage(errorMessage, 'error');
    }, [isError]);

    const childProps = {
        ...props,
        isLoading,
        deleteModal,
        setDeleteModal,
        onDelete,
        t,
    };

    return <CanDelete {...childProps} />;
};

const propTypes = {
    resource: PropTypes.string.isRequired,
};

interface extraProps {
    id: number | string;
    deleteHook: () => DeleteHook;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CanDeleteContainer.propTypes = propTypes;

export default CanDeleteContainer;
