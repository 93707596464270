import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ForgotPasswordPage.styled';
import SendMailForm from './SendMailForm';
import ConfirmationForm from './ConfirmationForm';

const ForgotPasswordPage = (props: Props) => {
    const { emailSent, setEmailSent } = props;

    return (
        <>
            <St.ForgotPasswordContainerBox>
                {emailSent ? (
                    <ConfirmationForm emailSent={emailSent} />
                ) : (
                    <SendMailForm onSuccess={setEmailSent} />
                )}
            </St.ForgotPasswordContainerBox>
        </>
    );
};

const propTypes = {};

interface extraProps {
    emailSent: string;
    setEmailSent: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ForgotPasswordPage.propTypes = propTypes;

export default ForgotPasswordPage;
