import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BackofficePage from './UsersBackofficePage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import {
    ActionColumn,
    ActionHeader,
    CommonFilter,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import { getFullName } from 'utils/helpers/commonHelper';
import { BackofficeUsersDefinition } from 'types/usersBackoffice/usersBackoffice.types';
import UsersBackofficeForm from './UsersBackofficeForm';
import UsersBackofficeDetail from './UsersBackofficeDetail';
import { RoleDefinition } from 'types/usersRoles/usersRoles.types';
import UsersBackofficeConfirmationAction from './UsersBackofficeConfirmationAction';
import { tDate } from 'constants/appConstants';

const UsersBackofficePageContainer = (props: Props) => {
    const { t } = useTranslation('usersBackoffice');

    const columns: CommonGridColumnsProps<BackofficeUsersDefinition>[] = [
        {
            id: 'createdAt',
            name: t('backoffice_users_list_creation_date_column'),
            selector: row => formatDateWithHour(row.createdAt),
            exportOption: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'updatedAt',
            name: t('backoffice_users_list_modification_date_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            exportOption: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'user',
            name: t('backoffice_users_list_user_column'),
            selector: row => row.username,
            exportOption: row => row.username,
            sortField: 'username',
            sortable: true,
            center: true,
        },
        {
            id: 'full_name',
            name: t('backoffice_users_list_full_name_column'),
            selector: row => getFullName(row),
            exportOption: row => getFullName(row),
            sortField: 'full_name',
            sortable: false,
            center: true,
        },
        {
            id: 'rol',
            name: t('backoffice_users_list_rol_column'),
            selector: row =>
                row?.assignedRoles?.map((rol: RoleDefinition) => rol?.name as string).join(', ') ||
                '-',
            exportOption: row =>
                row?.assignedRoles?.map((rol: RoleDefinition) => rol?.name as string).join(', ') ||
                '-',
            sortField: 'rol',
            sortable: false,
            center: true,
        },
        {
            id: 'state',
            name: t('backoffice_users_list_state_column'),
            selector: row =>
                t(`backoffice_users_list_${row.enabled ? 'enabled' : 'disabled'}`) ?? '',
            exportOption: row =>
                t(`backoffice_users_list_${row.enabled ? 'enabled' : 'disabled'}`) ?? '',
            sortField: 'enabled',
            sortable: true,
            center: true,
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: () => (
                <CustomTableAction
                    type="create"
                    Icon={props => <></>}
                    modalTitle={'backoffice-user'}
                    component={(props: any) => <UsersBackofficeForm {...props} />}
                />
            ),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <UsersBackofficeDetail {...props} />,
        },
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <UsersBackofficeForm isEdit {...props} />,
        },
        {
            id: 'disable_backoffice_user',
            icon: (props: any) => <CustomIconSelector type="disable" sx={props.sx} />,
            isHidden: (row: any) => !row?.enabled,
            component: ({ row: { id }, close }: any) => (
                <UsersBackofficeConfirmationAction id={id} action="disable" close={close} />
            ),
        },
        {
            id: 'enable_backoffice_user',
            icon: (props: any) => <CustomIconSelector type="enable" sx={props.sx} />,
            isHidden: (row: any) => row?.enabled,
            component: ({ row: { id }, close }: any) => (
                <UsersBackofficeConfirmationAction id={id} action="enable" close={close} />
            ),
        },
    ];

    const headerFilters: CommonFilter[] = [
        {
            id: 'fromCreatedAt',
            label: t('backoffice_user_createdAt_from'),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'fromCreatedAt',
        },
        {
            id: 'toCreatedAt',
            label: t('backoffice_user_createdAt_to'),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'toCreatedAt',
        },
        {
            id: 'fromUpdatedAt',
            label: t('backoffice_user_updatedAt_from'),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'fromUpdatedAt',
        },
        {
            id: 'toUpdatedAt',
            label: t('backoffice_user_updatedAt_to'),
            type: 'date',
            placeholder: t('select_date', tDate) as string,
            query: 'toUpdatedAt',
        },
        {
            id: 'fullName',
            label: t('backoffice_user_name'),
            type: 'text',
            placeholder: t('backoffice_user_name_placeholder') as string,
            query: 'fullName',
        },
        {
            id: 'uuids',
            label: t('backoffice_user_ref'),
            type: 'text',
            placeholder: t('backoffice_user_ref_placeholder') as string,
            query: 'uuids',
        },
        {
            id: 'username',
            label: t('backoffice_user_username'),
            type: 'text',
            placeholder: t('backoffice_user_username_placeholder') as string,
            query: 'username',
        },
        {
            id: 'roleName',
            label: t('backoffice_user_role_name'),
            type: 'text',
            query: 'roleName',
        },
        {
            id: 'state',
            label: t('backoffice_user_status'),
            type: 'select',
            query: 'enabled',
            options: [
                { id: 'true', name: t('backoffice_user_enabled') },
                { id: 'false', name: t('backoffice_user_disabled') },
            ],
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        headerActions,
        actionColumns,
        headerFilters,
    };

    return <BackofficePage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficePageContainer.propTypes = propTypes;

export default UsersBackofficePageContainer;
