import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBackofficeConfirmationAction from './UsersBackofficeConfirmationAction';
import { useTranslation } from 'react-i18next';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import {
    useDisableUsersBackoffice,
    useEnableUsersBackoffice,
} from 'hooks/api/usersBackoffice.hooks';

const UsersBackofficeConfirmationActionContainer = (props: Props) => {
    const { id, action } = props;
    const { t } = useTranslation('usersBackoffice');
    const { setSnackBarMessage } = useSnackBar();
    const { mutate: mutateEnable, isLoading: isLoadingEnable } =
        useEnableUsersBackoffice(setSnackBarMessage);
    const { mutate: mutateDisable, isLoading: isLoadingDisable } =
        useDisableUsersBackoffice(setSnackBarMessage);

    const handleSubmit = () =>
        action !== 'disable'
            ? mutateEnable(
                  { id },
                  {
                      onSuccess: () =>
                          setSnackBarMessage(t(`backoffice_users_list_disable_successfully`)),
                  },
              )
            : mutateDisable(
                  { id },
                  {
                      onSuccess: () =>
                          setSnackBarMessage(t(`backoffice_users_list_enable_successfully`)),
                  },
              );

    const childProps = {
        ...props,
        isLoadingEnable,
        isLoadingDisable,
        handleSubmit,
        t,
        action,
    };

    return <UsersBackofficeConfirmationAction {...childProps} />;
};

const propTypes = {};
interface extraProps {
    id: string;
    action: string;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficeConfirmationActionContainer.propTypes = propTypes;

export default UsersBackofficeConfirmationActionContainer;
