import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketExteriorInstrumentForm from './MarketExteriorInstrumentForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ExternalInstrument } from 'types/marketExteriorInstruments/marketExteriorInstruments.types';
import { AlertColor } from '@mui/material';
import {
    useCreateExternalInstruments,
    useEditExternalInstruments,
} from 'hooks/api/marketExteriorInstruments.hooks';
import {
    CreateExternalInstrumentRequest,
    EditExternalInstrumentRequest,
} from 'types/marketExteriorInstruments/marketExteriorInstruments.api.types';
import { tRequiredFieldError } from 'constants/appConstants';

const MarketExteriorInstrumentFormContainer = (props: Props) => {
    const { isEdit = false, row, close, setSnackBarMessage } = props;
    const { t } = useTranslation('marketExteriorInstruments');

    const { mutate: createExternalInstrument, isLoading: isLoadingCreate } =
        useCreateExternalInstruments(setSnackBarMessage, close);
    const { mutate: editExternalInstrument, isLoading: isLoadingEdit } = useEditExternalInstruments(
        setSnackBarMessage,
        close,
    );

    const getInitialValues = () => ({
        ticker: row?.name ?? '',
        description: row?.description ?? '',
    });

    const getValidationSchema = () =>
        Yup.object().shape({
            ticker: Yup.string()
                .nullable()
                .test('empty-ticker', tRequiredFieldError, value => {
                    return !!value;
                })
                .test('correct-ticker', t('invalid_ticker') as string, value => {
                    if (!value) return true;
                    let i = 0;
                    for (const c of value as string) if (c === '-') i++;
                    return value?.includes('-')
                        ? i === 1 && value?.toLocaleUpperCase().includes('EXT-')
                        : !!value;
                }),
            description: Yup.string().required(tRequiredFieldError),
        });

    const handleSubmit = React.useCallback(
        async (data: { ticker: string; description: string }) => {
            const ticker = data.ticker.toLocaleUpperCase().includes('EXT-')
                ? data.ticker.toLocaleUpperCase()
                : `EXT-${data.ticker.toLocaleUpperCase()}`;
            if (isEdit) {
                const req: EditExternalInstrumentRequest = {
                    id: row?.id,
                    body: {
                        name: ticker,
                        description: data.description,
                        profile: 'Agresivo',
                        tickers: [
                            {
                                currency: 'USD-EXT',
                                ticker,
                            },
                        ],
                    },
                };
                editExternalInstrument(req);
            } else {
                const req: CreateExternalInstrumentRequest = {
                    name: ticker,
                    description: data.description,
                    type: 'Exterior',
                    profile: 'Agresivo',
                    tickers: [
                        {
                            currency: 'USD-EXT',
                            ticker,
                        },
                    ],
                };
                createExternalInstrument(req);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [row?.id, isEdit],
    );

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(),
            onSubmit: handleSubmit,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = {
        ...props,
        formik,
        t,
        isLoadingSubmitForm: isLoadingCreate || isLoadingEdit,
    };

    return <MarketExteriorInstrumentForm {...childProps} />;
};

const propTypes = { isEdit: PropTypes.bool };

interface extraProps {
    close: () => void;
    row: ExternalInstrument;
    setSnackBarMessage: (msj: string, sever: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketExteriorInstrumentFormContainer.propTypes = propTypes;

export default MarketExteriorInstrumentFormContainer;
