import i18n from 'utils/i18n';

export const ALERTS_BASE_URL = '/alerts/v1_0/bo/';
export const AUTH_BASE_URL = '/auth/v1_0/bo';
export const AUTH_BASE = '/auth/v1_0/';
export const INSTRUMENTS_BASE_URL = '/instruments/v1_0';
export const ORDERS_BASE_URL = '/orders/v1_0/bo';
export const CASHFLOW_BASE_URL = '/cashflow/v1_0/bo';
export const DECLARATIONS_BASE_URL = 'declarations/v1_0/bo';
export const ONBOARDING_BASE_URL = '/onboarding/v1_0/bo';
export const MS_MARKET_ACCOUNT = '/market-account/v1_0/bo';
export const RULES_BASE_URL = '/rules/v1_0/bo';
export const DATES_BASE_URL = '/dates/v1_0';

export const EXAMPLE_CONSTANT: { [key: string]: string } = {
    FIRST_VALUE: 'firstValue',
};

export const SIGNATURE_BASE_MESSAGE = (address: string, nonce: string) =>
    `The Alien Labs.\n\nWallet address:\n${address}\n\nNonce:\n${nonce}`;

export const SIGNATURE_BASE_STATEMENT = `The Alien Labs. This request will not trigger a blockchain transaction or cost any gas fees.`;

export const tCommon = { ns: 'common' };
export const tCommonGrid = { ns: 'commonGrid' };
export const tErrorsContext = { ns: 'errors' };
export const tDate = { ns: 'date' };
export const tOrders = { ns: 'channelsOrders' };
export const tChannelsCommon = { ns: 'channelsCommon' };
export const tModal = { ns: 'cdtModal' };
export const tClient = { ns: 'clients' };
export const tRequiredFieldError = i18n.t('required_field', { ns: 'errors' });

export const ICON_CELL_SIZE = { width: 18, height: 18 };

export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const ELIMINATED = 'ELIMINATED';

// order actions
export const LOCK = 'lock';
export const UNLOCK = 'unlock';

// possible order states
export const ERROR = 'ERROR';
export const EXPIRED = 'EXPIRED';
export const EXPIRED_BECAUSE_DDJJ = 'EXPIRED_BECAUSE_DDJJ';
export const CANCELLED = 'CANCELLED';
export const REJECTED = 'REJECTED';
export const ASSIGNED = 'ASSIGNED';
export const SCHEDULED = 'SCHEDULED';
export const COMPLETED = 'COMPLETED';
// possible order states
export const SENT = 'SENT';
export const PARTIALLY_FILLED = 'PARTIALLY_FILLED';
export const CONFIRMED = 'CONFIRMED';
export const CANCELLED_PARTIALLY = 'CANCELLED_PARTIALLY';
export const EXPIRED_PARTIALLY = 'EXPIRED_PARTIALLY';
export const PENDING_CANCELLATION = 'PENDING_CANCELLATION';
export const UNASSIGNED = 'UNASSIGNED';
export const PENDING_ORDER = 'PENDING_ORDER';

// withdrawal auxiliar status
export const PENDING_WITHDRAWAL = 'PENDING_WITHDRAWAL';
// withdrawal states
export const APPROVED_API = 'APPROVED_API';
export const APPROVED_MANUALLY = 'APPROVED_MANUALLY';

// possible operation types
export const ARBITRAGE = 'arbitrage';
export const SALE = 'sale';
export const PURCHASE = 'purchase';
export const EXT_SALE = 'ext_sale';
export const EXT_PURCHASE = 'ext_purchase';

// collateral operation types
export const SETTER = 'BUY';
export const TAKER = 'SELL';

// possible fundings operation types
export const SUBSCRIPTION = 'BUY';
export const RESCUE = 'SELL';

// Currency format
export const ARS_LOCALE = 'es-AR';
export const ARS = 'ARS';
export const USD_LOCALE = 'en-US';
export const USD = 'USD';

// Rules terms currencies list
export const currenciesBaseArray = ['ARS', 'USD', 'USD-C'];
export const termsBaseArray = ['CI', '24'];

// Client sections
export const ACCOUNTS = 'marketAccounts';
export const GENERAL = 'general';
export const ADDRESS = 'address';
export const EXTRAINFO = 'extraInfo';
export const TAX_STATUS = 'taxStatus';
export const DOCUMENTS = 'documents';
export const ACTIVITY = 'activity';
export const PROSPECTS_STEPS = 'prospectsSteps';
export const ESCO_INFO = 'escoInfo';

// Client bank account status
export const PENDING_ACCEPT = 'PENDING_ACCEPT';
export const PENDING_DELETE = 'PENDING_DELETE';
export const ACTIVE = 'ACTIVE';
export const DELETED = 'DELETED';

export const BANK_ACCOUNTS_TYPES = ['SAVINGS', 'CHECKING', 'EXTERIOR'];

export const GENDERS = [
    { label: 'female', value: 'F' },
    { label: 'male', value: 'M' },
    { label: 'other', value: 'X' },
];

export const ARGENTINA_STATES = [
    'Ciudad Autónoma de Buenos Aires',
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego',
    'Tucumán',
];

export const HIDDEN_PROSPECT_STATES = ['FINALIZE', 'DONE', 'ONBOARDING_STARTED'];

export const LOCAL_ACCOUNTS = ['SAVINGS', 'CHECKING'];

// exterior bank accounts types
export const BROKER = 'BROKER';
export const DESTINATION = 'DESTINATION';
export const INTERMEDIARY = 'INTERMEDIARY';

// onboarding steps order

export const ONBOARDING_STEPS_ORDERS = [
    'VALIDATE_PERSON',
    'PERSONAL_DATA',
    'CONTACT_INFO',
    'COMPLEMENTARY_DATA',
    'UPLOAD_DOCUMENT',
    'INVESTMENT_TEST',
    'PROOF_OF_INCOME',
    'CONTRACT',
];

export const VALIDATED = 'VALIDATED';
export const PENDING_VALIDATION = 'PENDING_VALIDATION';
export const VALIDATION_FAILED = 'VALIDATION_FAILED';
export const VALIDATION_RETRY = 'VALIDATION_RETRY';
