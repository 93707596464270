import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { DollarQuotes } from 'types/marketDollarQuotes/marketDollarQuotes.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn, ActionHeader } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useDollarQuotes, useDeleteQuotes } from 'hooks/api/marketDollarQuotes.hooks';

const MarketDollarQuotesPage = (props: Props) => {
    const { t, columns, headerActions, actionColumns } = props;
    return (
        <CommonGrid
            title={t('dollar_quotes_list_title')}
            columns={columns}
            resource="market-dollar-quotes"
            useGetData={useDollarQuotes}
            defaultSortFieldId="id"
            canExport
            extraActionsInHeader={headerActions}
            customActionColumns={actionColumns}
            deleteHook={useDeleteQuotes}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<DollarQuotes>[];
    headerActions: ActionHeader[];
    actionColumns: ActionColumn[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketDollarQuotesPage.propTypes = propTypes;

export default MarketDollarQuotesPage;
