import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketDollarQuotesPage from './MarketDollarQuotesPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { DollarQuotes } from 'types/marketDollarQuotes/marketDollarQuotes.types';
import { ActionColumn, ActionHeader } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomTableAction from 'components/common/CommonGrid/Extra/CustomTableAction';
import MarketDollarQuotesForm from './MarketDollarQuotesForm';
import MarketDollarQuotesDetail from './MarketDollarQuotesDetail';
import St from './MarketDollarQuotesPage.styled';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { InstrumentObjApi } from 'types/common/instrument.types';
import { UPDATE_QUOTATIONS } from 'constants/abilities.constants';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';

export const getTicker = (instrument: InstrumentObjApi) => {
    const arsTicker = instrument.tickers.find(ticker => ticker.currency === 'ARS');
    return arsTicker ? arsTicker.ticker : instrument.tickers[0].ticker;
};

const MarketDollarQuotesPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('marketDollarQuotes');
    const { isHiddenByAbility } = useGetActionsReestrictions();

    const columns: CommonGridColumnsProps<DollarQuotes>[] = [
        {
            id: 'instrument',
            name: t('dollar_quotes_list_instrument_column'),
            exportOption: row => getTicker(row.instrument),
            cell: row => (
                <St.InstrumentBox>
                    <St.InstrumentName>{getTicker(row.instrument)}</St.InstrumentName>
                </St.InstrumentBox>
            ),
            sortField: '',
            sortable: false,
            center: true,
        },
    ];

    const headerActions: ActionHeader[] = [
        {
            id: 'create',
            component: (props: any) => {
                return (
                    <CustomTableAction
                        type="create"
                        Icon={() => <></>}
                        modalTitle={'market-dollar-quotes'}
                        component={props => <MarketDollarQuotesForm {...props} />}
                    />
                );
            },
            isHidden: isHiddenByAbility(UPDATE_QUOTATIONS),
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            component: (props: any) => <MarketDollarQuotesDetail {...props} />,
        },
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            component: (props: any) => <MarketDollarQuotesForm {...props} isEdit />,
            isHidden: () => isHiddenByAbility(UPDATE_QUOTATIONS),
        },
    ];

    const childProps = {
        ...props,
        t,
        columns,
        headerActions,
        actionColumns,
    };

    return <MarketDollarQuotesPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketDollarQuotesPageContainer.propTypes = propTypes;

export default MarketDollarQuotesPageContainer;
