import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsFundDetailActions from './ChannelsFundDetailActions';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';
import { useApproveFunds, useRejectFunds } from 'hooks/api/channelsFundings.hooks';

const ChannelsFundDetailActionsContainer = (props: Props) => {
    const { t } = useTranslation('channelsFundings');

    const { setSnackBarMessage } = useSnackBar();
    const [action, setAction] = useState('');
    const [reason, setReason] = useState('');
    const { mutate, isError, isLoading, errorMessage } = useApproveFunds(close, setSnackBarMessage);
    const {
        mutate: mutateReject,
        isError: isErrorReject,
        isLoading: isLoadingReject,
        errorMessage: errorMessageReject,
    } = useRejectFunds(close, setSnackBarMessage);

    const handleSubmit = () => {
        switch (action) {
            case 'approve':
                return mutate({ id: props.id, body: { approvedApi: false } });
            case 'reject':
                return mutateReject({ id: props.id, body: { reason } });

            default:
                return props.close();
        }
    };

    const childProps = {
        ...props,
        isLoading,
        isLoadingReject,
        reason,
        action,
        setAction,
        setReason,
        handleSubmit,
        t,
    };

    useEffect(() => {
        if (isError || isErrorReject)
            setSnackBarMessage(action === 'approve' ? errorMessage : errorMessageReject, 'error');
    }, [isError, isErrorReject]);

    return <ChannelsFundDetailActions {...childProps} />;
};

const propTypes = {};

interface extraProps {
    id: string;
    status: string;
    close(): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsFundDetailActionsContainer.propTypes = propTypes;

export default ChannelsFundDetailActionsContainer;
