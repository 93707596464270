import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionProspectEscoInfo from './ClientSectionProspectEscoInfo';
import { useTranslation } from 'react-i18next';
import { ProspectMarketData } from 'types/clients/clients.types';

const ClientSectionProspectEscoInfoContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('clients');

    const childProps = {
        ...props,
        t,
    };

    return <ClientSectionProspectEscoInfo {...childProps} />;
};

const propTypes = {};

interface extraProps {
    marketInfo: ProspectMarketData;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionProspectEscoInfoContainer.propTypes = propTypes;

export default ClientSectionProspectEscoInfoContainer;
