import { BackofficeUsersDefinition } from 'types/usersBackoffice/usersBackoffice.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UsersBackofficeDetail from './UsersBackofficeDetail';
import { useTranslation } from 'react-i18next';

export const UsersBackofficeDetailContainer = (props: Props) => {
    const { row } = props;
    const { t } = useTranslation('usersBackoffice');

    const childProps = { ...props, t, detailData: row };

    return <UsersBackofficeDetail {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row?: BackofficeUsersDefinition;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UsersBackofficeDetailContainer.propTypes = propTypes;

export default UsersBackofficeDetailContainer;
