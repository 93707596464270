import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FadeIn from './FadeIn';

const FadeInContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <FadeIn {...childProps} />;
};

const propTypes = {};

interface extraProps {
    duration?: string;
    children: React.ReactNode;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FadeInContainer.propTypes = propTypes;

export default FadeInContainer;
