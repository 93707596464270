import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsModalItemGrid from './ChannelsModalItemGrid';
import { ItemGridProps } from 'types/common/channels.types';

const ChannelsModalItemGridContainer = (props: Props) => {
    const childProps = {
        ...props,
    };

    return <ChannelsModalItemGrid {...childProps} />;
};

const propTypes = {};

interface extraProps extends ItemGridProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalItemGridContainer.propTypes = propTypes;

export default ChannelsModalItemGridContainer;
