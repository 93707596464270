import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsBlocksPage from './SettingsBlocksPage';
import { useTranslation } from 'react-i18next';
import { Functionality } from 'types/settingsBlocks/settingsBlocks.api.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { ICON_CELL_SIZE, tCommon } from 'constants/appConstants';
import defaultTheme from 'utils/theme/defaultTheme';
import { ActionColumn } from 'types/common/CommonGrid/CommonGridFormModal.types';
import SettingsBlocksEdit from './SettingsBlocksEdit';

const SettingsBlocksPageContainer = (props: Props) => {
    const { t } = useTranslation('settingsBlocks');

    const getIconCell = (value: boolean) =>
        value ? (
            <CustomIconSelector
                type="check"
                sx={{ color: defaultTheme.palette.success.main, ...ICON_CELL_SIZE }}
            />
        ) : (
            <CustomIconSelector
                type="close"
                sx={{ color: defaultTheme.palette.secondary.main, ...ICON_CELL_SIZE }}
            />
        );

    const columns: CommonGridColumnsProps<Functionality>[] = [
        {
            id: 'updatedAt',
            name: t('settings_blocks_updated_date_column'),
            selector: row => formatDateWithHour(row.updatedAt),
            exportOption: row => formatDateWithHour(row.updatedAt),
            sortField: 'updatedAt',
            sortable: true,
            center: true,
        },
        {
            id: 'name',
            name: t('settings_blocks_name_column'),
            selector: row => t(row.name) as string,
            exportOption: row => t(row.name) as string,
            sortField: 'name',
            sortable: true,
            wrap: true,
            center: true,
        },
        {
            id: 'locked',
            name: t('settings_blocks_locked_column'),
            selector: row => t(row.locked ? 'yes' : 'no', tCommon) as string,
            exportOption: row => t(row.locked ? 'yes' : 'no', tCommon) as string,
            cell: row => getIconCell(row.locked),
            sortField: 'locked',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'edit',
            icon: (props: any) => <CustomIconSelector type="edit" sx={props.sx} />,
            width: 'xl',
            component: (props: any) => <SettingsBlocksEdit {...props} />,
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        t,
    };

    return <SettingsBlocksPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsBlocksPageContainer.propTypes = propTypes;

export default SettingsBlocksPageContainer;
