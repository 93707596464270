import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './EmailVerificationPage.styled';
import EmailVerificationForm from './EmailVerificationForm';

const EmailVerificationPage = (props: Props) => {
    const { t } = props;
    return (
        <St.Container>
            <EmailVerificationForm />
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
EmailVerificationPage.propTypes = propTypes;

export default EmailVerificationPage;
