import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CanDisable from './CanDisable';
import { useTranslation } from 'react-i18next';
import useDisable from 'hooks/common/CommonGrid/disable.hooks';
import { useState } from 'react';
import useSnackBar from 'hooks/common/CommonGrid/snackBar.hooks';

const CanDisableContainer = (props: Props) => {
    const { resource, isDisabled, fake } = props;

    const { t } = useTranslation();
    const { isLoading, mutateDisable } = useDisable(resource, isDisabled ?? false, fake ?? false);
    const [openModal, setOpenModal] = useState(false);
    const { setSnackBarMessage } = useSnackBar();

    const onDisable = (elementId: number) =>
        mutateDisable(elementId, {
            onSuccess: () => {
                setSnackBarMessage(
                    t(
                        isDisabled ?? false
                            ? 'successful_enabled_snackbar_text'
                            : 'successful_disabled_snackbar_text',
                        {
                            item: t('customers_modal_text'),
                        },
                    ),
                );
            },
            onError: () => {
                setSnackBarMessage(
                    t(
                        isDisabled ?? false
                            ? 'error_enabled_snackbar_text'
                            : 'error_disabled_snackbar_text',
                        {
                            item: t('customers_modal_text'),
                        },
                    ),
                    'error',
                );
            },
        });
    const childProps = {
        ...props,
        isDisabled: isDisabled ?? false,
        isLoading,
        openModal,
        setOpenModal,
        t,
        onDisable,
    };

    return <CanDisable {...childProps} />;
};

const propTypes = {
    resource: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    fake: PropTypes.bool,
};

interface extraProps {
    id: number | string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CanDisableContainer.propTypes = propTypes;

export default CanDisableContainer;
