import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { AcceptDeleteAccountRequest } from 'types/clientDeleteAccountRequest/clientDeleteAccountRequest.types';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';

const ClientAcceptDeleteAccount = ({ isLoading, t, close, handleSubmit }: Props) => (
    <>
        <DialogContent>
            <DialogContentText>{t('delete_account_request_modal_question')}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <ActionButton
                type="button"
                size="small"
                variant="outlined"
                onClick={close}
                color="primary">
                {t('cancel', tCommon)}
            </ActionButton>

            <ActionButton
                type="button"
                size="small"
                variant="contained"
                onClick={handleSubmit}
                loading={isLoading}
                color="primary">
                {t('confirm', tCommon)}
            </ActionButton>
        </DialogActions>
    </>
);

const propTypes = {};

interface extraProps extends AcceptDeleteAccountRequest {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientAcceptDeleteAccount.propTypes = propTypes;

export default ClientAcceptDeleteAccount;
