import React from 'react';
import {
    ExtraQueryFiltersProps,
    GridHookParams,
    SortInformation,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCommonGrid } from 'context/common/commonGrid.context';
import useBreakpoints from '../breakpoints.hooks';

const useCustomFetch = (
    useGetData: (params: GridHookParams) => any,
    canDisable: boolean,
    clientSidePagination: boolean,
    adjustHeight: number,
    canSearch: boolean,
    rowHeight: number,
    extraFilters?: Array<ExtraQueryFiltersProps>,
    defaultSortFieldId?: string,
    defaultFilters?: Array<ExtraQueryFiltersProps>,
    backTableId?: number,
    defaultSortDirection?: 'asc' | 'desc',
) => {
    const { showDisabled, setNeedRefresh, needRefresh } = useCommonGrid();
    const { matchesSm } = useBreakpoints();
    const pageSize: number =
        Math.ceil((window.innerHeight - adjustHeight - (matchesSm ? 350 : 300)) / rowHeight) - 1;
    const [page, setPage] = React.useState<number>(1);
    const [searchText, setSearchText] = React.useState('');
    const [filterHeaderString, setFilterHeaderString] = React.useState<string>(
        defaultFilters
            ? `&${defaultFilters.map(filter => `${filter.query}=${filter.queryValue}`).join('&')}`
            : '',
    );
    const [sort, setSort] = React.useState({
        column: defaultSortFieldId || '',
        sortDirection: defaultSortDirection || 'desc',
    } as SortInformation);

    React.useEffect(() => {
        setPage(1);
    }, [filterHeaderString]);
    const [showHeaderFilters, setShowHeaderFilters] = React.useState(false);

    const filterQueryString = React.useMemo(() => {
        let extraQueryFilters = '';
        if (!clientSidePagination) extraQueryFilters = `&page=${page}&pageSize=${pageSize}`;
        if (sort.column)
            extraQueryFilters += `&sortField=${sort.column}&sort=${sort.sortDirection.toUpperCase()}`;
        if (canDisable) extraQueryFilters += `&showDisabled=${showDisabled}`;
        if (canSearch) extraQueryFilters += `&searchText=${searchText}`;
        extraQueryFilters += filterHeaderString;
        extraFilters?.forEach((element: ExtraQueryFiltersProps) => {
            extraQueryFilters += `&${element.query}=${element.queryValue}`;
        });
        return extraQueryFilters;
    }, [page, pageSize, sort, showDisabled, filterHeaderString, extraFilters, searchText]);

    const {
        data: dataFromQuery,
        isLoading: loading,
        refetch,
    } = useGetData({
        filterQueryString,
        extraFilters: extraFilters ?? [],
        forceDisable: false,
        backTableId,
    } as GridHookParams);

    const [data, totalRows] = React.useMemo(() => {
        if (dataFromQuery)
            return [dataFromQuery?.data ?? dataFromQuery, dataFromQuery?.total_count];
        else return [[], 0];
    }, [dataFromQuery]);

    const handlePageChange = (pag: number) => {
        setPage(pag);
    };

    const handleSort = (sortInfo: SortInformation) => setSort(sortInfo);

    React.useEffect(() => {
        if (needRefresh) {
            setNeedRefresh(false);
            refetch();
        }
    }, [needRefresh]);

    return {
        data,
        handlePageChange,
        handleSort,
        loading,
        pageSize,
        setSearchText,
        totalRows,
        refetch,
        filterHeaderString,
        setFilterHeaderString,
        showHeaderFilters,
        setShowHeaderFilters,
    };
};

export default useCustomFetch;
