import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CommonGrid from 'components/common/CommonGrid';
import { DeleteAccountRequest } from 'types/clientDeleteAccountRequest/clientDeleteAccountRequest.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useGetDeleteAccountRequest } from 'hooks/api/clientDeleteAccountRequest.hooks';

const ClientDeleteAccountRequestPage = (props: Props) => {
    const { t, columns, actionColumns } = props;

    return (
        <CommonGrid
            title={t('delete_account_request_list_title')}
            exportName={t('delete_account_request_list_title')}
            columns={columns}
            resource="client-delete-account-requests"
            useGetData={useGetDeleteAccountRequest}
            defaultSortFieldId="id"
            canExport
            customActionColumns={actionColumns}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonGridColumnsProps<DeleteAccountRequest>[];
    actionColumns: ActionColumn[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientDeleteAccountRequestPage.propTypes = propTypes;

export default ClientDeleteAccountRequestPage;
