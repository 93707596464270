import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Grid, Typography } from '@mui/material';
import { tChannelsCommon } from 'constants/appConstants';
import {
    ApproveOrEditInformation,
    collaterals,
} from 'types/channelsCollateral/channelsCollateral.types';
import ChannelsModalActions from 'components/common/ChannelsModalActions';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import { statesToShowActions } from 'utils/helpers/channelsCommonHelper';
import CustomDatePicker from 'components/common/CustomDatePicker';
import RejectForm from 'components/common/RejectForm';
import CustomLoader from 'components/common/CustomLoader';
import CurrencyInput from 'components/common/CurrencyInput';

const ChannelsCollateralDetailActions = ({
    id,
    status,
    isLoading,
    isLoadingEdit,
    isLoadingReject,
    reason,
    action,
    approveInformation,
    setAction,
    setReason,
    handleAcquireOrReleaseOrder,
    handleChange,
    handleSubmit,
    close,
    disabledDates,
    isLoadingCollateralsAvailable,
    t,
}: Props) => (
    <>
        {statesToShowActions.includes(status) && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />

                <ChannelsModalActionButton status={status} setAction={setAction} />

                <Grid
                    container
                    item
                    direction="column"
                    alignSelf="baseline"
                    xs={6}
                    spacing={2}
                    ml={1.25}>
                    {(action === 'approve' || action === 'edit') && (
                        <>
                            <Grid item xs>
                                <Typography fontSize={16} fontWeight={600}>
                                    {t(
                                        action === 'approve' ? 'for_approval' : 'to_edit',
                                        tChannelsCommon,
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <CurrencyInput
                                    handleChange={value => handleChange('rate', Number(value))}
                                    sx={{ width: '100%' }}
                                    value={approveInformation?.rate}
                                    decimalScale={2}
                                    label={t('rate', tChannelsCommon)}
                                    percentage
                                />
                            </Grid>
                            <Grid item xs>
                                {isLoadingCollateralsAvailable ? (
                                    <CustomLoader />
                                ) : (
                                    <CustomDatePicker
                                        fieldValue="settlementDay"
                                        value={approveInformation?.settlementDay || ''}
                                        setFieldValue={handleChange}
                                        disableCurrentDay
                                        disablePastDaysFromN
                                        disabledDates={disabledDates}
                                        disableWeekends
                                    />
                                )}
                            </Grid>
                            {approveInformation?.term && (
                                <Grid item xs>
                                    <Typography>
                                        {t('term', tChannelsCommon)}: {approveInformation?.term}
                                        &nbsp;
                                        {t(approveInformation?.term > 1 ? 'days' : 'day')}
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}

                    {action === 'reject' && <RejectForm setReason={setReason} reason={reason} />}
                </Grid>
            </>
        )}

        <Divider sx={{ width: '100%', mt: 2 }} />

        <ChannelsModalActions
            id={id}
            type="COLLATERAL"
            status={status}
            loading={isLoading || isLoadingEdit || isLoadingReject}
            disabled={isLoading || isLoadingEdit || isLoadingReject}
            close={close}
            handleSubmit={handleSubmit}
            handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
        />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    id: string;
    status: string;
    localInstrumentsUnderWarranty: collaterals[];
    isLoading: boolean;
    isLoadingEdit: boolean;
    isLoadingReject: boolean;
    reason?: string;
    action: string;
    approveInformation: ApproveOrEditInformation;
    setAction(action: string): void;
    close(): void;
    setReason(reason: string): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
    handleChange(name: string, value: string | number): void;
    handleSubmit(): void;
    isLoadingCollateralsAvailable: boolean;
    disabledDates: string[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralDetailActions.propTypes = propTypes;

export default ChannelsCollateralDetailActions;
