import { paginationAdapter } from 'adapters/common/pagination.adapter';
import {
    GetExtractionsResponse,
    ExtractionsApi,
} from 'types/clientExtractions/clientExtractions.api.types';

export const extractionsAdapter = (data: GetExtractionsResponse) => {
    return {
        ...paginationAdapter(data),
        data: data?.items?.map((extraction: ExtractionsApi) => {
            return {
                entryDate: extraction.entryDate,
                accountUuid: extraction.accountUuid,
                customerCode: extraction.customerCode,
                amount: extraction.amount,
                currency: extraction.currency,
                withdrawalCondition: extraction.withdrawalCondition,
                id: extraction.id,
                userUuid: extraction.userUuid,
                channelUuid: extraction.channelUuid,
                operatorUuid: extraction.operatorUuid,
                user: extraction.user,
                bankAccount: extraction.bankAccount,
                channel: extraction?.channel,
                operator: extraction?.operator,
                status: extraction.status,
                reason: extraction?.reason,
                conditions: extraction.conditions,
                actions: extraction.actions,
                comment: extraction.comment,
            };
        }),
    };
};
