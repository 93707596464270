import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsAlertsFormMessageEditorPage from './SettingsAlertsFormMessageEditor';
import { useTranslation } from 'react-i18next';

const SettingsAlertsFormMessageEditorPageContainer = (props: Props) => {
    const { t } = useTranslation('alerts');

    const childProps = {
        ...props,
        t,
    };

    return <SettingsAlertsFormMessageEditorPage {...childProps} />;
};

const propTypes = {};

interface extraProps {
    message: string;
    messageError?: string;
    handleChange(change: { target: { name: string; value: any } }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsAlertsFormMessageEditorPageContainer.propTypes = propTypes;

export default SettingsAlertsFormMessageEditorPageContainer;
