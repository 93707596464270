import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MainLayout from './MainLayout';
import { AuthContext } from 'context/auth.context';

const MainLayoutContainer = (props: Props) => {
    const { isAuthenticated, alertNotification, isLoadingAlert, errorMessageAlert } =
        React.useContext(AuthContext);

    const hasAcceptedAlert = localStorage.getItem('hasAcceptedAlert');
    const [openAlertModal, setOpenAlertModal] = React.useState<boolean>(false);

    React.useMemo(() => {
        setOpenAlertModal(!!alertNotification?.length && !hasAcceptedAlert);
    }, [alertNotification, hasAcceptedAlert]);

    const childProps = {
        ...props,
        isAuthenticated,
        setOpenAlertModal,
        openAlertModal,
        alertNotification,
        errorMessageAlert,
        isLoadingAlert,
    };

    return <MainLayout {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayoutContainer.propTypes = propTypes;

export default MainLayoutContainer;
